import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

import { ButtonHTMLAttributes, PropType, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiButton',
  props: {
  htmlType: {
    type: String as PropType<ButtonHTMLAttributes["type"]>,
    default: "button",
  },
  variant: {
    type: String,
    default: "neutral",
  },
  textVariant: {
    type: String,
    default: "white",
  },
  themed: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "md",
  },
  shape: {
    type: String,
    default: "rounded",
  },
  shadow: {
    type: String,
    default: "sm",
  },
  disabled: Boolean,
  to: {
    type: Object,
  },
},
  setup(__props) {

const props = __props;

const classes = computed(() => {
  const variantExceptions = [
    "inherit",
    "current",
    "transparent",
    "black",
    "white",
  ];

  let sizes: string[] = [];

  switch (props.size) {
    case "xs":
      sizes = ["px-1", "py-1", "text-xs"];
      break;
    case "sm":
      sizes = ["px-2.5", "py-1.5", "text-xs"];
      break;
    case "md":
      sizes = ["px-3", "py-2", "text-sm"];
      break;
    case "lg":
      sizes = ["px-4", "py-2", "text-sm"];
      break;
    case "xl":
      sizes = ["px-4", "py-2", "text-base"];
      break;
    case "2xl":
      sizes = ["px-6", "py-3", "text-base"];
      break;
    default:
      break;
  }

  let shape: string | undefined = undefined;

  if (props.shape == "rounded") {
    if (props.size == "xs" || props.size == "sm") shape = "rounded";
    else shape = "rounded-md";
  } else if (props.shape == "rounded-full") shape = "rounded-full";

  const getVariantSuffix = (variant: string, themed: boolean) => {
    if (themed)
      return {
        default: variant,
        darker: variant + "-dark",
        lighter: variant + "-light",
      };

    let variantColor: string = variant;
    let variantIntensity: number | undefined = undefined;

    if (!variantExceptions.includes(variant)) {
      const variantContainsIntensity = variant.lastIndexOf("-") != -1;

      if (variantContainsIntensity) {
        variantIntensity = parseInt(
          variant.substring(variant.lastIndexOf("-") + 1, variant.length),
        );

        variantColor = variant.substring(0, variant.lastIndexOf("-"));
      } else variantIntensity = 600;
    }

    const getSufix = (intensity: number | undefined, variation: number) => {
      if (intensity == undefined) return "";
      else {
        let sufix = intensity + variation;
        if (sufix > 900) sufix = 900;
        else if (sufix < 50) sufix = 50;

        return "-" + sufix;
      }
    };

    return {
      default: variantColor + getSufix(variantIntensity, 0),
      darker: variantColor + getSufix(variantIntensity, 100),
      lighter: variantColor + getSufix(variantIntensity, -100),
    };
  };

  const variantWithSuffixes = getVariantSuffix(props.variant, props.themed);
  const textVariantWithSuffixes = getVariantSuffix(
    props.textVariant,
    props.themed,
  );

  let classes: (string | object)[] = [
    `bg-${variantWithSuffixes.default}`,
    `text-${textVariantWithSuffixes.default}`,
  ];

  if (props.disabled) {
    classes = [`bg-gray-400`, `text-gray-600`];
  }

  if (!variantExceptions.includes(props.variant) && !props.disabled) {
    classes = classes.concat([
      `hover:bg-${variantWithSuffixes.darker}`,
      `focus:ring-${variantWithSuffixes.lighter}`,
    ]);
  }

  if (!variantExceptions.includes(props.textVariant)) {
    classes = classes.concat([`hover:text-${textVariantWithSuffixes.darker}`]);
  }

  classes = classes.concat(sizes);

  if (shape != undefined) classes.push(shape);

  if (props.shadow != undefined) classes.push("shadow-" + props.shadow);

  return classes;
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (__props.to === undefined)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: __props.htmlType,
        class: _normalizeClass(["border flex justify-center font-medium focus:outline-none focus:ring-2 disabled", classes.value]),
        disabled: props.disabled
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: __props.to,
        class: _normalizeClass(["border flex justify-center font-medium focus:outline-none focus:ring-2", classes.value])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "class"]))
}
}

})