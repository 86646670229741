import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" }
const _hoisted_3 = {
  key: 0,
  class: "border border-gray-300 bg-gray-50 rounded-xl shadow hover:bg-white min-h-full flex flex-col justify-between"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "px-4 pt-3 font-semibold text-gray-700 flex items-center" }
const _hoisted_6 = { class: "-ml-0.5 p-1 bg-teal-100 rounded-lg mr-2 ring-1 ring-black ring-opacity-5" }
const _hoisted_7 = { class: "px-4 pt-2 text-sm text-gray-600" }
const _hoisted_8 = { class: "px-4 mt-3 py-2 border-t border-gray-300/50 text-sm rounded-b-xl flex items-center text-primary font-semibold" }

import { AnnotationIcon, ArrowRightIcon } from "@heroicons/vue/solid";
import { Permission, IDashboardItem } from "@/definitions";
import {
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  PhoneIcon,
  OfficeBuildingIcon,
  ClockIcon,
  MenuAlt2Icon,
  CloudUploadIcon,
  LinkIcon,
} from "@heroicons/vue/solid";

import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfigurationPage',
  setup(__props) {

const items: IDashboardItem[] = [
  {
    title: "dashboard.configuration.users",
    description: "user.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USERS,
    routeName: "ListUser",
    icon: UsersIcon,
  },
  {
    title: "dashboard.configuration.userLabel",
    description: "userLabel.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USER_LABELS,
    routeName: "ListUserLabel",
    icon: UserGroupIcon,
  },
  {
    title: "dashboard.configuration.roles",
    description: "role.description",
    callToAction: "core.actions.goPage",
    permission: Permission.ASSIGN_ROLE_TO_USER,
    routeName: "Permissions",
    icon: LockClosedIcon,
  },
  {
    title: "dashboard.configuration.levels",
    description: "level.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_LEVELS,
    routeName: "ListLevel",
    icon: OfficeBuildingIcon,
  },
  {
    title: "dashboard.configuration.numbers",
    description: "number.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_NUMBERS,
    routeName: "ListNumber",
    icon: PhoneIcon,
  },
  {
    title: "dashboard.configuration.connectFlow",
    description: "connectFlow.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOWS,
    routeName: "ListConnectFlow",
    icon: ConnectFlowIcon,
  },
  {
    title: "dashboard.configuration.schedules",
    description: "schedule.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_SCHEDULES,
    routeName: "ListSchedule",
    icon: ClockIcon,
  },
  {
    title: "dashboard.configuration.queues",
    description: "queue.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_QUEUES,
    routeName: "ListQueue",
    icon: MenuAlt2Icon,
  },
  {
    title: "dashboard.configuration.connectFlowAudios",
    description: "connectFlowAudio.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
    routeName: "ListConnectFlowAudio",
    icon: CloudUploadIcon,
  },
  {
    title: "dashboard.configuration.surveys",
    description: "survey.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_SURVEYS,
    routeName: "ListSurvey",
    icon: AnnotationIcon,
  },
  {
    title: "dashboard.configuration.integrations",
    description: "integration.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_INTEGRATIONS,
    routeName: "ListIntegration",
    icon: LinkIcon,
  },
  // {
  //   title: "dashboard.configuration.words",
  //   description: "word.description",
  //   callToAction: "core.actions.goPage",
  //   permission: Permission.VIEW_WORDS,
  //   routeName: "ListWord",
  //   icon: "CogIcon",
  // },
  // {
  //   title: "dashboard.configuration.scripts",
  //   description: "Descrição de scripts de replace de replace",
  //   callToAction: "core.actions.goPage",
  //   routeName: "",
  //   icon: "CogIcon",
  // },
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(items, (block, index) => {
        return _createVNode(_component_router_link, {
          key: `dashboardItem${index}`,
          to: { name: block.routeName }
        }, {
          default: _withCtx(() => [
            (block.permission && _ctx.$can(block.permission))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h3", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(block.icon), {
                          class: "h-5 w-5 text-secondary",
                          "aria-hidden": "true"
                        }))
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(block.title)), 1)
                    ]),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(block.description)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_unref(ArrowRightIcon), {
                      class: "-ml-1 mr-1 h-4 w-4",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(block.callToAction)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"])
      }), 64))
    ])
  ]))
}
}

})