import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-shrink-0 bg-black/20 p-4 shadow-md" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = { class: "ml-3" }
const _hoisted_4 = { class: "text-sm font-medium text-white" }

import { computed } from "vue";
import store from "@/store";
import { UiGravatar } from "@/components";
import { AuthGetters, IUser } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateProfile',
  setup(__props) {

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (user.value !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_unref(UiGravatar), {
              email: user.value.email,
              name: user.value.name,
              class: "inline-block h-10 w-10 rounded-full"
            }, null, 8, ["email", "name"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(user.value.name), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})