import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

import { PropType } from "vue";
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from "@heroicons/vue/solid";
import { ISortMenuItem, ISortMenuItemDirection } from "@/definitions";
import { UiBadge } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenuBadge',
  props: {
  item: {
    type: Object as PropType<ISortMenuItem>,
    required: true,
  },
},
  emits: ["remove"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleRemoveClick = (item: ISortMenuItem) => {
  emit("remove", item);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiBadge), {
    removable: "",
    onRemove: _cache[0] || (_cache[0] = ($event: any) => (handleRemoveClick(props.item)))
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(
        props.item.direction == _unref(ISortMenuItemDirection).ASC
          ? _unref(ArrowCircleDownIcon)
          : _unref(ArrowCircleUpIcon)
      ), {
        class: "h-4 w-4 cursor-pointer text-primary",
        "aria-hidden": "true"
      })),
      _createTextVNode(" " + _toDisplayString(props.item.label), 1)
    ]),
    _: 1
  }))
}
}

})