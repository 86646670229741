import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium mb-3 text-purple-500" }
const _hoisted_4 = { class: "text-lg leading-6 font-medium mb-3 text-blue-500" }
const _hoisted_5 = { class: "flex flex-row gap-5" }
const _hoisted_6 = { class: "px-4 py-5 bg-white shadow rounded-lg sm:p-3 border-l-4 basis-1/2 border-purple-500" }
const _hoisted_7 = { class: "mb-3 text-gray-700 flex" }
const _hoisted_8 = { class: "mb-3 text-gray-700 flex" }
const _hoisted_9 = { class: "px-4 py-5 bg-white shadow rounded-lg sm:p-3 border-l-4 basis-1/2 border-blue-500" }
const _hoisted_10 = { class: "text-gray-700 mb-3 flex" }
const _hoisted_11 = { class: "text-gray-700 mb-3 flex" }

import { computed, ComputedRef, PropType, WritableComputedRef } from "vue";
import { SpeakerphoneIcon, AnnotationIcon } from "@heroicons/vue/outline";
import {
  CallPlayerGetters,
  CallPlayerMutations,
  ICallInsight,
  ICallWord,
  IInsightByPersona,
  IWordsByPersona,
  ParticipantType,
} from "@/definitions";
import { WordsBlock, InsightBlock } from "@/views/pages/call/components";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallWordsInsight',
  props: {
  insight: {
    type: Object as PropType<IInsightByPersona>,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, {
      value,
    });
  },
});

const agentWords: ComputedRef<ICallWord[]> = computed(() => {
  if (words.value) {
    return words.value?.agent.filter((w) => w.counter > 1);
  }
  return [];
});

const clientWords: ComputedRef<ICallWord[]> = computed(() => {
  if (words.value) {
    return words.value?.client.filter((w) => w.counter > 1);
  }
  return [];
});

const agentInsight: ComputedRef<ICallInsight[]> = computed(() => {
  if (props.insight) {
    return props.insight?.agent;
  }
  return [];
});

const clientInsight: ComputedRef<ICallInsight[]> = computed(() => {
  if (props.insight) {
    return props.insight?.client;
  }
  return [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("call.view.participant.agent")), 1),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("call.view.participant.client")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (words.value !== undefined)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_7, [
                _createVNode(_unref(SpeakerphoneIcon), { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.spokenWords")), 1)
              ]),
              _createVNode(_unref(WordsBlock), {
                words: agentWords.value,
                persona: _unref(ParticipantType).Agent
              }, null, 8, ["words", "persona"])
            ], 64))
          : _createCommentVNode("", true),
        (__props.insight)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h3", _hoisted_8, [
                _createVNode(_unref(AnnotationIcon), { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.foundInsights")), 1)
              ]),
              _createVNode(_unref(InsightBlock), {
                insights: agentInsight.value,
                persona: _unref(ParticipantType).Agent
              }, null, 8, ["insights", "persona"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("h3", _hoisted_10, [
          _createVNode(_unref(SpeakerphoneIcon), { class: "h-6 w-6 mr-1" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.spokenWords")), 1)
        ]),
        _createVNode(_unref(WordsBlock), {
          words: clientWords.value,
          persona: _unref(ParticipantType).Client
        }, null, 8, ["words", "persona"]),
        (__props.insight)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_11, [
                _createVNode(_unref(AnnotationIcon), { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.foundInsights")), 1)
              ]),
              _createVNode(_unref(InsightBlock), {
                insights: clientInsight.value,
                persona: _unref(ParticipantType).Client
              }, null, 8, ["insights", "persona"])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})