import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { StepEdge, EdgeText, getSmoothStepPath } from "@vue-flow/core";
import { computed, ComputedRef } from "vue";
import { ConnectFlowModelingGetters, IFlowConnection } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'AwaitableEdge',
  props: {
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: false,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const path = computed(() => getSmoothStepPath(props));

const edge: ComputedRef<IFlowConnection> = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.id),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(StepEdge), {
      id: __props.id,
      style: _normalizeStyle(__props.style),
      path: path.value[0],
      "marker-end": __props.markerEnd
    }, null, 8, ["id", "style", "path", "marker-end"]),
    (edge.value.label)
      ? (_openBlock(), _createBlock(_unref(EdgeText), {
          key: 0,
          x: props.targetX - 0,
          y: props.targetY - 30,
          "label-show-bg": true,
          "label-bg-padding": [8, 4],
          "label-bg-border-radius": 5
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(edge.value.label), 1)
          ]),
          _: 1
        }, 8, ["x", "y"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})