import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_6 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_7 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-3"
}
const _hoisted_8 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_9 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_10 = { class: "px-4 py-4 sm:px-6" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toast, toastServiceError } from "@/utils/notification";
import ConnectFlowService from "@/services/ConnectFlowService";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
} from "@/components";
import {
  ConnectFlowListItem,
  ConnectFlowListSort,
  ConnectFlowListFilters,
} from "@/views/pages/connect-flow/components";
import {
  IConnectFlow,
  Permission,
  IConnectFlowServiceGetAllParams,
  ConnectFlowGetters,
  ConnectFlowActions,
  IConnectFlowFilter,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";

const perPage = 30;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlows: Ref<IConnectFlow[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[ConnectFlowGetters.SEARCH],
  set: (search: string) =>
    store.dispatch(ConnectFlowActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[ConnectFlowGetters.SORT],
  set: (sort: ISortMenuItem[]) =>
    store.dispatch(ConnectFlowActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[ConnectFlowGetters.PAGE],
  set: (page: number) => store.dispatch(ConnectFlowActions.SET_PAGE, page),
});

const filter: IConnectFlowFilter = store.getters[ConnectFlowGetters.FILTER];

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[ConnectFlowGetters.ACTIVE_FILTERS],
);

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getConnectFlows = () => {
  const params: IConnectFlowServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    level: filter.level?.id || null,
    is_active: filter.isActive ? filter.isActive.value : null,
    search: search.value,
  };

  setLoading(true);
  ConnectFlowService.getAll<Array<IConnectFlow>>({
    params,
  })
    .then((response: AxiosResponse<IConnectFlow[]>) => {
      connectFlows.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getConnectFlows();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getConnectFlows();
});

getConnectFlows();

const setIsActive = async (item: IConnectFlow, isActive: boolean) => {
  await setLoading(true);
  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getConnectFlows();
  };

  (isActive ? ConnectFlowService.activate : ConnectFlowService.inactivate)(
    item.id,
  )
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) =>
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);

const handleRemoveStatusFilterClick = (field: string) => {
  store.dispatch(ConnectFlowActions.CLEAR_FILTER, field);
};

const handleActivateClick = (item: IConnectFlow) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IConnectFlow) => {
  setIsActive(item, false);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(ConnectFlowIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.ConnectFlows")), 1)
        ])
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_CONNECT_FLOW))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateConnectFlow',
          } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event))
            }, null, 8, ["modelValue"])
          ], 32),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(ConnectFlowListSort), {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(ConnectFlowListFilters))
          ])
        ])
      ]),
      (sortMenuSelected.value.length || activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (item) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                removable: "",
                key: `activeFilter${item.field}`,
                onRemove: ($event: any) => (handleRemoveStatusFilterClick(item.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`connectFlow.filters.${item.field}.label`)) + ": " + _toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connectFlows.value, (connectFlow) => {
          return (_openBlock(), _createBlock(_unref(ConnectFlowListItem), {
            key: connectFlow.id,
            "model-value": connectFlow,
            onActivate: handleActivateClick,
            onInactivate: handleInactivateClick
          }, null, 8, ["model-value"]))
        }), 128)),
        (!connectFlows.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})