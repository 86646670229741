import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white/40 w-full p-2 pb-0 flex items-center justify-center font-medium gap-x-2 ring-1 ring-black ring-opacity-5"
}
const _hoisted_2 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "w-full sm:w-64" }
const _hoisted_5 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_6 = { class: "truncate text-xs font-medium text-gray-500 text-center" }
const _hoisted_7 = { class: "mt-1 text-2xl font-semibold tracking-tight text-gray-800 text-center" }
const _hoisted_8 = {
  key: 2,
  class: "mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 px-4 sm:px-0"
}
const _hoisted_9 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_10 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_11 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_12 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_13 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 hidden sm:block" }
const _hoisted_14 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_15 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { toastServiceError } from "@/utils/notification";
import {
  CallReportActions,
  CallReportGetters,
  CallStatus,
  CallType,
  FilterActions,
  FilterGetters,
  ICallReportFilter,
  ICallReportServiceGetInfoParams,
  ICallsStats,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
  Permission,
} from "@/definitions";
import {
  CallMediasDoughnutChart,
  CallReportFilters,
  CallServiceTimeRangesDoughnutChart,
  CallStatusDoughnutChart,
  CallStatusPerDayBarChart,
  CallTimeOfDayHeatmapChart,
  CallTypesPerDayBarChart,
  CallAverageTimePerDayBarChart,
} from "@/views/pages/reports/call/components";
import { UiPageHeader, UiDatePicker, UiBadge } from "@/components";
import moment from "moment";
import CallReportService from "@/services/CallReportService";
import { formatSecToTime } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallReportPage',
  setup(__props) {

const callsStats: Ref<ICallsStats | null> = ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  if (!callsStats.value) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.value.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.value.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.value.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "noAnswer",
      value: callsStats.value.status[CallStatus.NO_ANSWER].toString(),
    },
    {
      name: "busy",
      value: callsStats.value.status[CallStatus.BUSY].toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.value.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.value.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
    {
      name: "averageWaitingTime",
      value: callsStats.value.averageWaitingTime
        ? formatSecToTime(callsStats.value.averageWaitingTime, true)
        : "-",
    },
  ];

  return result;
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<ICallReportFilter>(
  () => store.getters[CallReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[CallReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: ICallReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: filter.value.type?.value || null,
    status: filter.value.status?.value || null,
    user: filter.value.user?.id || null,
    userLabel: filter.value.userLabel?.id || null,
    number: filter.value.number?.number || null,
    serviceTimeRange: filter.value.serviceTimeRange?.value || null,
  };

  setLoading(true);

  CallReportService.getInfo<ICallsStats>({
    params,
  })
    .then((response) => {
      callsStats.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(CallReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$can(_unref(Permission).VIEW_CALL))
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _ctx.$route.name === 'CallList'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallList' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("dashboard.calls")), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _ctx.$route.name === 'CallReport'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallReport' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("report.call.title")), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(UiPageHeader), null, {
        info: _withCtx(() => [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("report.call.title")), 1)
        ]),
        actions: _withCtx(() => [
          _createVNode(_unref(CallReportFilters)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiDatePicker), {
              modelValue: dateRange.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
              range: "",
              multiCalendars: ""
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }),
      (activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                key: filter,
                removable: "",
                onRemove: ($event: any) => (handleRemoveFilter(filter))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`report.call.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (headerStats.value)
        ? (_openBlock(), _createElementBlock("dl", {
            key: 1,
            class: _normalizeClass(["mt-5 grid grid-cols-2 gap-3", ['sm:grid-cols-' + headerStats.value.length]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerStats.value, (stat) => {
              return (_openBlock(), _createElementBlock("div", {
                key: stat.name,
                class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-3"
              }, [
                _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.call.stats." + stat.name)), 1),
                _createElementVNode("dd", _hoisted_7, _toDisplayString(stat.value), 1)
              ]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true),
      (callsStats.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_unref(CallStatusDoughnutChart), {
                title: _ctx.$t('report.call.callStatusDoughnutChart.title'),
                data: callsStats.value.status
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(CallStatusPerDayBarChart), {
                title: _ctx.$t('report.call.callStatusPerDayBarChart.title'),
                data: callsStats.value.statusPerDay
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_unref(CallTypesPerDayBarChart), {
                title: _ctx.$t('report.call.callTypePerDayBarChart.title'),
                data: callsStats.value.typePerDay
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_unref(CallAverageTimePerDayBarChart), {
                title: _ctx.$t('report.call.callAverageTimesChart.title'),
                data: callsStats.value.tmPerDay
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(CallTimeOfDayHeatmapChart), {
                title: _ctx.$t('report.call.callTimeOfDayHeatmapChart.title'),
                data: callsStats.value.timeOfDay
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(CallServiceTimeRangesDoughnutChart), {
                title: _ctx.$t('report.call.callServiceTimeRangesDoughnutChart.title'),
                data: callsStats.value.serviceTimeRange
              }, null, 8, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_unref(CallMediasDoughnutChart), {
                title: _ctx.$t('report.call.callMediasDoughnutChart.title'),
                data: callsStats.value.media
              }, null, 8, ["title", "data"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})