import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-3" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-3" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_9 = { class: "sm:col-span-5" }
const _hoisted_10 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_11 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_12 = { class: "sm:col-span-5" }
const _hoisted_13 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_14 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_15 = { class: "sm:col-span-5" }
const _hoisted_16 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_17 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_18 = { class: "sm:col-span-5" }
const _hoisted_19 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_20 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  ICallReportFilter,
  CallReportGetters,
  CallReportActions,
  CallServiceTimeRange,
  CallStatus,
  CallType,
  IUser,
  ILevel,
  FilterGetters,
  INumber,
  IUserLabel,
} from "@/definitions";
import { UserLabelService, UserService, NumberService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallReportFilters',
  setup(__props) {

const { t } = useI18n();

const filterOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const defaultFilter = computed(
  () => store.getters[CallReportGetters.DEFAULT_FILTER],
);
const filter: Ref<ICallReportFilter> = ref({
  type: defaultFilter.value.type,
  status: defaultFilter.value.status,
  user: defaultFilter.value.user,
  number: defaultFilter.value.number,
  userLabel: defaultFilter.value.userLabel,
  serviceTimeRange: defaultFilter.value.serviceTimeRange,
});
const storeFilter = computed(() => store.getters[CallReportGetters.FILTER]);

const typeOptions: Ref<IMultiSelectOption[]> = ref([
  {
    value: CallType.INBOUND,
    label: t("core.options.call.type.inbound"),
  },
  {
    value: CallType.OUTBOUND,
    label: t("core.options.call.type.outbound"),
  },
  {
    value: CallType.INTERNAL,
    label: t("core.options.call.type.internal"),
  },
]);

const statusOptions: Ref<IMultiSelectOption[]> = ref([
  {
    value: CallStatus.ANSWERED,
    label: t("core.options.call.status.answered"),
  },
  {
    value: CallStatus.NO_ANSWER,
    label: t("core.options.call.status.no_answer"),
  },
  {
    value: CallStatus.BUSY,
    label: t("core.options.call.status.busy"),
  },
  {
    value: CallStatus.DITCHED,
    label: t("core.options.call.status.ditched"),
  },
]);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  if (level.value)
    UserService.getAll<IUser[]>({
      params: {
        isAgent: true,
        page: 1,
        per_page: 100,
        level: level.value.id,
      },
    }).then((response) => (userOptions.value = response.data));
};

const userLabelOptions: Ref<IUserLabel[]> = ref([]);
const getUserLabels = () => {
  if (level.value)
    UserLabelService.getAll<IUserLabel[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
};

const numberOptions: Ref<INumber[]> = ref([]);
const getNumbers = () => {
  if (level.value)
    NumberService.getAll<INumber[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (numberOptions.value = response.data));
};

const serviceTimeRangeOptions: Ref<IMultiSelectOption[]> = ref(
  [
    CallServiceTimeRange.BELOW_30S,
    CallServiceTimeRange.BETWEEN_30S_AND_60S,
    CallServiceTimeRange.BETWEEN_61_AND_180S,
    CallServiceTimeRange.BETWEEN_181S_AND_300S,
    CallServiceTimeRange.BETWEEN_301S_AND_480S,
    CallServiceTimeRange.BETWEEN_481S_AND_600S,
    CallServiceTimeRange.ABOVE_600,
  ].map((callServiceTimeRange) => {
    return {
      value: callServiceTimeRange,
      label: t("core.options.call.serviceTimeRange." + callServiceTimeRange),
    };
  }),
);

function applyFilters() {
  store.dispatch(CallReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(CallReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.type = storeFilter.value.type;
  filter.value.status = storeFilter.value.status;
  filter.value.user = storeFilter.value.user;
  filter.value.userLabel = storeFilter.value.userLabel;
  filter.value.number = storeFilter.value.number;
  filter.value.serviceTimeRange = storeFilter.value.serviceTimeRange;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

watch(level, () => {
  store.dispatch(CallReportActions.CLEAR_FILTER, "user");
  getUsers();
  store.dispatch(CallReportActions.CLEAR_FILTER, "number");
  getNumbers();
  store.dispatch(CallReportActions.CLEAR_FILTER, "userLabel");
  getUserLabels();
});

onMounted(() => {
  getUsers();
  getNumbers();
  getUserLabels();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("report.call.filters.type")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_type",
              modelValue: filter.value.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.type) = $event)),
              options: typeOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("report.call.filters.status")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_status",
              modelValue: filter.value.status,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.status) = $event)),
              options: statusOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("report.call.filters.user")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_user",
              modelValue: filter.value.user,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.user) = $event)),
              label: "name",
              "value-prop": "id",
              options: userOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("report.call.filters.userLabel")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_userLabel",
              modelValue: filter.value.userLabel,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.userLabel) = $event)),
              label: "label",
              "value-prop": "id",
              options: userLabelOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("report.call.filters.number")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_number",
              modelValue: filter.value.number,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.number) = $event)),
              label: "number",
              "value-prop": "id",
              options: numberOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("report.call.filters.serviceTimeRange")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_serviceTimeRange",
              modelValue: filter.value.serviceTimeRange,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filter.value.serviceTimeRange) = $event)),
              options: serviceTimeRangeOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})