import Vuex from "vuex";

import agentProductivityPerAgentReportStore from "@/store/modules/agentProductivityPerAgent.report.store";
import agentProductivityPerDayReportStore from "@/store/modules/agentProductivityPerDay.report.store";
import audioPlayerStore from "@/store/modules/audioPlayer.store";
import authStore from "@/store/modules/auth.store";
import callPlayerStore from "@/store/modules/callPlayer.store";
import callReportStore from "@/store/modules/call.report.store";
import callStore from "@/store/modules/call.store";
import connectFlowAudioStore from "@/store/modules/connectFlowAudio.store";
import connectFlowStore from "@/store/modules/connectFlow.store";
import contactCenterPerAgentReportStore from "@/store/modules/contactCenterPerAgent.report.store";
import contactCenterPerDayReportStore from "@/store/modules/contactCenterPerDay.report.store";
import billingReportStore from "@/store/modules/billing.report.store";
import filterStore from "@/store/modules/filter.store";
import integrationLogStore from "@/store/modules/integrationLog.store";
import levelStore from "@/store/modules/level.store";
import navigationStore from "@/store/modules/navigation.store";
import numberStore from "@/store/modules/number.store";
import permissionStore from "@/store/modules/permission.store";
import queueStore from "@/store/modules/queue.store";
import surveyStore from "@/store/modules/survey.store";
import uiStore from "@/store/modules/ui.store";
import userLabelStore from "@/store/modules/userLabel.store";
import userStore from "@/store/modules/user.store";
import wordStore from "@/store/modules/word.store";
import meetingStore from "@/store/modules/meeting.store";

export default new Vuex.Store({
  modules: {
    agentProductivityPerAgentReportStore: agentProductivityPerAgentReportStore,
    agentProductivityPerDayReportStore: agentProductivityPerDayReportStore,
    audioPlayer: audioPlayerStore,
    auth: authStore,
    call: callStore,
    callPlayer: callPlayerStore,
    callReportStore: callReportStore,
    connectFlowAudio: connectFlowAudioStore,
    connectFlowStore: connectFlowStore,
    contactCenterPerAgentReportStore: contactCenterPerAgentReportStore,
    contactCenterPerDayReportStore: contactCenterPerDayReportStore,
    billingReportStore: billingReportStore,
    filter: filterStore,
    integrationLog: integrationLogStore,
    level: levelStore,
    navigation: navigationStore,
    number: numberStore,
    permission: permissionStore,
    queue: queueStore,
    survey: surveyStore,
    ui: uiStore,
    user: userStore,
    userLabel: userLabelStore,
    word: wordStore,
    meetingStore: meetingStore,
  },
});
