import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-5 mb-5" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  for: "users",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-4 gap-5" }
const _hoisted_9 = { class: "col-span-2" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_11 = {
  key: 0,
  controls: "",
  class: "w-full mb-3"
}
const _hoisted_12 = ["src", "type"]
const _hoisted_13 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import {
  QueueService,
  LevelService,
  UserService,
  VaultService,
} from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
  UiAudioUpload,
} from "@/components";
import {
  IQueue,
  ILevel,
  IUser,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IQueueServiceCreateResult,
  FormAction,
  IQueueForm,
  FilterGetters,
  IMultiSelectOption,
  QueueStrategy,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import { useI18n } from "vue-i18n";
import { getAudioFormat } from "@/utils/audio";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();
const { t } = useI18n();

const props = __props;

const strategyOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t(`queue.strategy.${QueueStrategy.RANDOM}`),
    value: QueueStrategy.RANDOM,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RRORDERED}`),
    value: QueueStrategy.RRORDERED,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RRMEMORY}`),
    value: QueueStrategy.RRMEMORY,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RINGALL}`),
    value: QueueStrategy.RINGALL,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.FEWESTCALLS}`),
    value: QueueStrategy.FEWESTCALLS,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.LEASTRECENT}`),
    value: QueueStrategy.LEASTRECENT,
  },
]);

const formState = reactive<IQueueForm>({
  id: 0,
  name: "",
  strategy: "",
  fileAudio: "",
  isActive: true,
  levelId: 0,
  users: [],
  audio: undefined,
});

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(100),
  },
  strategy: {
    required,
  },
  levelId: {
    required,
  },
  fileAudio: {},
  users: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IQueueForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getQueue = (id: number) => {
  setLoading(true);
  QueueService.get<IQueue>(id)
    .then((response: AxiosResponse<IQueue>) => {
      const { name, levelId, strategy, fileAudio, isActive, queueUsers } =
        response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      formState.strategy = strategy;
      formState.fileAudio = fileAudio || "";
      formState.isActive = isActive;
      formState.users = queueUsers ? queueUsers?.map((u) => u.userId) : [];

      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getQueue(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch([levelFilter], () => {
  getLevels();
});

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

const users = computed(() => {
  return usersRaw.value.map((user) => {
    return {
      id: user.id,
      name: user.name,
    };
  });
});
const usersRaw: Ref<IUser[]> = ref([]);
const getUsers = () => {
  setLoading(true);
  UserService.getAll<IUser[]>({
    params: {
      isAgent: true,
      page: 1,
      per_page: 100,
      level: formState.levelId,
    },
  }).then((response) => {
    setLoading(false);
    usersRaw.value = response.data;
  });
};
getUsers();

const audioData: Ref<string | null> = ref(null);
const getAudioFile = async () => {
  if (formState.fileAudio) {
    const { data } = await VaultService.getAudioAsset(formState.fileAudio);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.fileAudio),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};
const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

watch(
  () => formState.levelId,
  () => {
    getUsers();
  },
);

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const formData = new FormData();

  formData.append("id", formState.id.toString());
  formData.append("levelId", formState.levelId.toString());
  formData.append("name", formState.name);
  formData.append("strategy", formState.strategy);
  formData.append("isActive", formState.isActive.toString());
  formState.users.forEach((user, index) => {
    formData.append(`users[${index}]`, user.toString());
  });

  if (formState.audio) {
    formData.append("audio", formState.audio);
  }

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    v$.value.$reset();
    router.push({ name: "ListQueue" });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    QueueService.update<IDefaultServiceResult, FormData>(id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    QueueService.create<IQueueServiceCreateResult, FormData>(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IQueueServiceCreateResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("queue." + props.action + ".title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("queue." + props.action + ".description")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("queue.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formState.name) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('queue.labels.name'),
              errors: _unref(v$).name.$errors,
              onBlur: _unref(v$).name.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("queue.labels.users")), 1),
              _createVNode(_unref(UiMultiselect), {
                id: "users",
                name: "users",
                label: "name",
                "value-prop": "id",
                modelValue: formState.users,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.users) = $event)),
                multiple: "",
                primitive: true,
                options: users.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("queue.labels.strategy")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "strategy",
                modelValue: formState.strategy,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formState.strategy) = $event)),
                primitive: true,
                options: strategyOptions.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: formState.isActive,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formState.isActive) = $event)),
              name: "isActive",
              label: _ctx.$t('queue.labels.isActive')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_unref(t)("queue.labels.customAudio")), 1),
              (audioData.value)
                ? (_openBlock(), _createElementBlock("audio", _hoisted_11, [
                    _createElementVNode("source", {
                      src: audioData.value,
                      type: _unref(getAudioFormat)(formState.fileAudio || '')
                    }, null, 8, _hoisted_12)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(UiAudioUpload), {
                id: `audioUpload`,
                class: "flex-grow w-full md:w-auto",
                errors: _unref(v$).fileAudio.$errors,
                audioData: audioData.value,
                filePath: formState.fileAudio,
                "onUpdate:file": handleFileUpdate,
                "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules')
              }, null, 8, ["errors", "audioData", "filePath", "text-rule"])
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListQueue' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_QUEUE)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_QUEUE))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})