import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "search-field",
  class: "sr-only"
}
const _hoisted_2 = { class: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 pr-5" }
const _hoisted_3 = ["placeholder", "value"]

import dbounce from "debounce";
import { SearchIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiListSearch',
  props: {
  modelValue: {
    type: String,
  },
  debounce: {
    type: Number,
    default: 1000,
  },
  placeholder: {
    type: String,
  },
  class: {
    type: String,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const { t } = useI18n();

const emit = __emit;

const placeholderText = computed(() => {
  if (props.placeholder !== undefined) {
    return props.placeholder;
  }
  return t("core.actions.Search");
});

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Search")), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-full text-gray-400 focus-within:text-gray-600", props.class])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(SearchIcon), {
          class: "h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        })
      ]),
      _createElementVNode("input", {
        name: "search-field",
        id: "search-field",
        class: "h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 bg-transparent",
        placeholder: placeholderText.value,
        type: "search",
        value: props.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => {
        _unref(dbounce)(() => {
          updateValue(($event.target as HTMLInputElement).value);
        }, props.debounce)()
      })
      }, null, 40, _hoisted_3)
    ], 2)
  ], 64))
}
}

})