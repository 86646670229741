import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import { useRoute } from "vue-router";
import { IntegrationType } from "@/definitions";
import {
  PipedriveIntegrationFormPage,
  OpenAiIntegrationFormPage,
} from "@/views/pages/integration";

// Configuration object for integration types

export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationDynamicForm',
  setup(__props) {

const integrationConfig = {
  [IntegrationType.Pipedrive]: PipedriveIntegrationFormPage,
  [IntegrationType.OpenAi]: OpenAiIntegrationFormPage,
};

// Access the current route
const route = useRoute();

// Resolve the component based on the route parameter
const resolvedComponent = computed(() => {
  const type = route.params.type as IntegrationType;
  return integrationConfig[type];
});

// Combine route props with passed props
const routeProps = computed(() => ({
  ...route.params,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(resolvedComponent.value), _normalizeProps(_guardReactiveProps(routeProps.value)), null, 16))
}
}

})