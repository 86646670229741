import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_5 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_6 = { class: "px-4 py-4 sm:px-6" }

import { ref, Ref, computed, onMounted } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import ConnectFlowService from "@/services/ConnectFlowService";
import ConnectFlowHistoryItem from "@/views/pages/connect-flow/components/ConnectFlowHistoryItem.vue";
import { UiPageHeader, UiButton, UiPagination } from "@/components";
import {
  Permission,
  ConnectFlowGetters,
  ConnectFlowActions,
  UiActions,
  IConnectFlowServiceGetHistoryParams,
  IConnectFlowFlow,
  IConnectFlow,
} from "@/definitions";
import { AxiosResponse } from "axios";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlow: Ref<IConnectFlow | undefined> = ref(undefined);
const flowHistory: Ref<IConnectFlowFlow[]> = ref([]);

const currentPage = computed<number>({
  get: () => store.getters[ConnectFlowGetters.PAGE],
  set: (page: number) => store.dispatch(ConnectFlowActions.SET_PAGE, page),
});

const count = ref(0);
currentPage.value = 1;

if (route.query.clearStateOnSetup) {
  router.replace({ query: {} });
}

const getHistory = () => {
  const params: IConnectFlowServiceGetHistoryParams = {
    page: currentPage.value,
    per_page: perPage,
  };

  setLoading(true);
  ConnectFlowService.getFlowHistory<Array<IConnectFlowFlow>>(
    route.params.id as string,
    {
      params,
    },
  )
    .then((response: AxiosResponse<IConnectFlowFlow[]>) => {
      flowHistory.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};
getHistory();
const getConnectFlow = (id: number) => {
  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      connectFlow.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
onMounted(() => {
  setLoading(true);
  getConnectFlow(parseInt(route.params.id as string));
  getHistory();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(ConnectFlowIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.ConnectFlows")), 1)
        ])
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_CONNECT_FLOW))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'ListConnectFlow',
          } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(flowHistory.value, (flow) => {
          return (_openBlock(), _createBlock(ConnectFlowHistoryItem, {
            key: `connectVersionItem${flow.id}`,
            "model-value": flow,
            published: flow.id === connectFlow.value?.lastAppliedFlowId
          }, null, 8, ["model-value", "published"]))
        }), 128)),
        (!flowHistory.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})