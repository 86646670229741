import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_2 = { class: "relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-52 sm:p-6" }

import { UiSpinner } from "@/components";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLoadingDialog',
  props: {
  show: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: __props.show
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        class: "fixed z-50 inset-0 overflow-y-auto"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(TransitionChild), {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogOverlay), { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" })
              ]),
              _: 1
            }),
            _cache[0] || (_cache[0] = _createElementVNode("span", {
              class: "hidden sm:inline-block sm:align-middle sm:h-screen",
              "aria-hidden": "true"
            }, "​", -1)),
            _createVNode(_unref(TransitionChild), {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to": "opacity-100 translate-y-0 sm:scale-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(DialogTitle), { class: "text-lg leading-10 font-medium text-gray-700" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(UiSpinner), { class: "h-10 w-10 float-left mr-3 theme-color" }),
                      _createTextVNode(" " + _toDisplayString(__props.text !== undefined ? __props.text : _ctx.$t("loading")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("button", null, "This is a focusable element.", -1))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})