import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-10" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "block text-sm font-medium leading-6 mb-2 text-gray-900" }
const _hoisted_6 = { class: "grid grid-cols-2 md:grid-cols-7 gap-6 mb-10" }
const _hoisted_7 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-10" }
const _hoisted_8 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import ScheduleService from "@/services/ScheduleService";
import { LevelService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiTimePicker,
  UiMultiselect,
} from "@/components";
import {
  ISchedule,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IScheduleServiceCreateResult,
  FormAction,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";

import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const { t } = useI18n();

const route = useRoute();

const props = __props;

const formState = reactive<ISchedule>({
  id: 0,
  name: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  timeBegin: "",
  timeEnd: "",
  isActive: true,
  levelId: 0,
});

const dayList = () => {
  return (
    formState.monday ||
    formState.tuesday ||
    formState.wednesday ||
    formState.thursday ||
    formState.friday ||
    formState.saturday ||
    formState.sunday
  );
};

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(500),
  },
  levelId: {
    required,
  },
  monday: {
    required,
  },
  tuesday: {
    required,
  },
  wednesday: {
    required,
  },
  thursday: {
    required,
  },
  friday: {
    required,
  },
  saturday: {
    required,
  },
  sunday: {
    required,
  },
  isActive: {
    required,
  },
  timeBegin: {
    required,
  },
  timeEnd: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ISchedule>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const getSchedule = (id: number) => {
  setLoading(true);
  ScheduleService.get<ISchedule>(id)
    .then((response: AxiosResponse<ISchedule>) => {
      const {
        levelId,
        name,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        timeBegin,
        timeEnd,
        isActive,
      } = response.data;
      formState.id = id;
      formState.levelId = levelId;
      formState.name = name;
      formState.isActive = isActive;
      formState.monday = monday;
      formState.tuesday = tuesday;
      formState.wednesday = wednesday;
      formState.thursday = thursday;
      formState.friday = friday;
      formState.saturday = saturday;
      formState.sunday = sunday;
      formState.timeBegin = timeBegin;
      formState.timeEnd = timeEnd;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getSchedule(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  if (!dayList()) {
    toast.error(t("schedule.error.dayNotSelected"));
    return;
  }

  const { timeBegin, timeEnd } = formState;

  if (!isTimeBefore(timeBegin, timeEnd)) {
    toast.error(t("schedule.error.invalidTimeRange"));
    return;
  }

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewScheduleById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ScheduleService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ScheduleService.create<IScheduleServiceCreateResult>(formState)
      .then(
        (response: AxiosResponse<IScheduleServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

function isTimeBefore(initialHour: string, finalHour: string) {
  const parseTime = (time: string) => time.split(":").map(Number);

  const [hours1, minutes1] = parseTime(initialHour);
  const [hours2, minutes2] = parseTime(finalHour);

  return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2);
}

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewScheduleById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListSchedule",
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("schedule." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('schedule.labels.name'),
              errors: _unref(v$).name.$errors,
              onBlur: _unref(v$).name.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"])
          ]),
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("schedule.labels.daysActive")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).monday.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).monday.$model) = $event)),
              name: "monday",
              label: _ctx.$t('schedule.labels.monday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).tuesday.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).tuesday.$model) = $event)),
              name: "tuesday",
              label: _ctx.$t('schedule.labels.tuesday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).wednesday.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).wednesday.$model) = $event)),
              name: "wednesday",
              label: _ctx.$t('schedule.labels.wednesday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).thursday.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(v$).thursday.$model) = $event)),
              name: "thursday",
              label: _ctx.$t('schedule.labels.thursday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).friday.$model,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(v$).friday.$model) = $event)),
              name: "friday",
              label: _ctx.$t('schedule.labels.friday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).saturday.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(v$).saturday.$model) = $event)),
              name: "saturday",
              label: _ctx.$t('schedule.labels.saturday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).sunday.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(v$).sunday.$model) = $event)),
              name: "sunday",
              label: _ctx.$t('schedule.labels.sunday'),
              class: "mr-8"
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(UiTimePicker), {
              modelValue: _unref(v$).timeBegin.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(v$).timeBegin.$model) = $event)),
              name: "initialHour",
              label: _ctx.$t('schedule.labels.timeBegin'),
              errors: _unref(v$).timeBegin.$errors,
              onBlur: _unref(v$).timeBegin.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createVNode(_unref(UiTimePicker), {
              modelValue: _unref(v$).timeEnd.$model,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(v$).timeEnd.$model) = $event)),
              name: "finalHour",
              label: _ctx.$t('schedule.labels.timeEnd'),
              errors: _unref(v$).timeEnd.$errors,
              onBlur: _unref(v$).timeEnd.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).isActive.$model,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('schedule.labels.isActive')
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          onClick: handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_SCHEDULE)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_SCHEDULE))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})