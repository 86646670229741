import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50 p-3.5" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex flex-1 gap-2" }
const _hoisted_4 = { class: "flex items-center w-2/5 gap-1" }
const _hoisted_5 = ["datetime"]
const _hoisted_6 = { class: "w-1/5" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "sr-only" }

import { PropType } from "vue";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { CalendarIcon } from "@heroicons/vue/solid";
import { BookmarkIcon } from "@heroicons/vue/outline";
import { IConnectFlowFlow, Permission } from "@/definitions";
import { UiButton } from "@/components";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryItem',
  props: {
  modelValue: {
    type: Object as PropType<IConnectFlowFlow>,
    required: true,
  },
  published: {
    type: Boolean,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(CalendarIcon), {
            class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("time", {
            datetime: props.modelValue?.createdAt
          }, _toDisplayString(_unref(formatDateAsString)(
                props.modelValue.createdAt,
                "DD/MM/YYYY HH:mm:ss",
              )), 9, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (props.modelValue?.createdByUser)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER) ? 'RouterLink' : 'span'), {
                key: 0,
                to: {
              name: 'ViewUserById',
              params: {
                id: props.modelValue?.createdByUser?.id,
              },
            },
                class: "truncate font-medium text-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.modelValue.createdByUser.name), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        (__props.published)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_unref(BookmarkIcon), { class: "h-6 w-6 text-primary" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(UiButton), {
          size: "",
          shape: "rounded-full",
          variant: "inherit",
          "text-variant": "primary",
          shadow: "none",
          themed: "",
          class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
          to: {
            name: 'ModelingConnectFlowHistory',
            params: {
              id: __props.modelValue.connectFlowId,
              historyId: __props.modelValue.id,
            },
          }
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("connectFlow.actions.modeling")), 1),
            _createVNode(_unref(ConnectFlowIcon), {
              class: "h-6 w-6",
              "aria-hidden": "true"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}
}

})