import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-end gap-1.5" }

import { Ref, ref, computed, watch } from "vue";
import { UiButton, UiTextInput } from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { minLength, required } from "@/utils/validators";
import { TrashIcon } from "@heroicons/vue/outline";

interface IExternalFormState {
  formState: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToExternalNumberField',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue", "remove"],
  setup(__props, { emit: __emit }) {

const props = __props;

const number: Ref<string> = ref<string>(props.modelValue);

const rules = computed<ValidationArgs<IExternalFormState>>(() => {
  const r: ValidationArgs<IExternalFormState> = {
    formState: {
      required,
      minLength: minLength(13),
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IExternalFormState>>> = useVuelidate(
  rules,
  {
    formState: number,
  },
);
const emit = __emit;

watch(number, () => {
  emit("update:modelValue", number.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiTextInput), {
      name: "extension",
      type: "text",
      label: _ctx.$t('connectFlow.node.transferToExternal.number'),
      class: _normalizeClass(props.removable ? 'w-11/12' : 'w-full'),
      modelValue: number.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((number).value = $event)),
      modelModifiers: { lazy: true },
      errors: _unref(v$).formState.$silentErrors,
      "is-phone-number": ""
    }, null, 8, ["label", "class", "modelValue", "errors"]),
    (props.removable)
      ? (_openBlock(), _createBlock(_unref(UiButton), {
          key: 0,
          class: "text-lg py-4",
          variant: "white",
          size: "md",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('remove')))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TrashIcon), { class: "h-4 w-4 text-gray-500" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})