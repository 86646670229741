import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "h-fit text-gray-600 w-full text-sm" }
const _hoisted_3 = {
  key: 0,
  class: "w-full block"
}
const _hoisted_4 = { class: "block w-full text-[10px] leading-5 text-gray-600" }
const _hoisted_5 = ["id", "disabled"]
const _hoisted_6 = { key: 2 }

import { ref, computed } from "vue";
import { CloudUploadIcon, XIcon } from "@heroicons/vue/outline";
import { ErrorObject } from "@vuelidate/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAudioUpload',
  props: {
  index: {
    type: Number,
    required: false,
  },
  action: {
    type: String,
    default: "view",
  },
  file: {
    type: File,
    default: null,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textRule: {
    type: String,
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
  silentErrors: {
    type: Array<ErrorObject>,
    default: [],
  },
},
  emits: ["update:file"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const fileName = ref("");

function handleAudioUpload(event: Event) {
  const input = event.target as HTMLInputElement;
  if (!input.files) return;

  const file = input.files[0];

  if (file) {
    emit("update:file", file);
    fileName.value = file.name;
  } else {
    emit("update:file", null);
    fileName.value = "";
  }
}

function removeFile() {
  emit("update:file", null);
  fileName.value = "";
}
const disabledClass = computed(() =>
  props.disabled ? "bg-gray-200 text-gray-400" : "",
);

const hasErros = computed(
  () => props.errors.length || props.silentErrors.length,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex content-center w-full rounded-md relative border-0 text-gray-900 placeholder:text-gray-400 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm p-2", [
      disabledClass.value,
      !hasErros.value
        ? 'ring-gray-300 focus:ring-primary'
        : 'ring-danger focus:ring-danger',
    ]])
  }, [
    (fileName.value.length)
      ? (_openBlock(), _createBlock(_unref(XIcon), {
          key: 0,
          class: "h-4 w-4 text-gray-400 absolute top-2 right-2 font-bold cursor-pointer",
          onClick: removeFile
        }))
      : _createCommentVNode("", true),
    (props.file === null)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: `audio${props.index}`,
          class: _normalizeClass(["text-sm gap-3 flex font-semibold w-11/12 shrink max-h-fit", [
        props.disabled ? '' : 'cursor-pointer',
        'focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-600 focus-within:ring-offset-2',
      ]])
        }, [
          _createVNode(_unref(CloudUploadIcon), { class: "h-8 w-8 mt-1 text-gray-300 disabled:text-white" }),
          _createElementVNode("span", _hoisted_2, [
            (fileName.value.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(fileName.value), 1))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([props.disabled ? 'text-gray-500' : 'text-primary'])
                }, _toDisplayString(_ctx.$t("connectFlowAudio.create.fileLabel")), 3)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(props.textRule), 1),
            _createElementVNode("input", {
              onChange: handleAudioUpload,
              name: "audio",
              id: `audio${props.index}`,
              type: "file",
              disabled: props.disabled,
              class: _normalizeClass(['sr-only'])
            }, null, 40, _hoisted_5)
          ])
        ], 10, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(props.file?.name), 1))
  ], 2))
}
}

})