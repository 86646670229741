import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_4 = { class: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_5 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_12 = { class: "bg-white shadow rounded-lg p-6" }
const _hoisted_13 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "flex justify-between border-b border-gray-200 pb-2" }
const _hoisted_16 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_17 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_18 = { class: "text-sm text-gray-500" }
const _hoisted_19 = { class: "text-sm font-semibold text-gray-900" }
const _hoisted_20 = { class: "bg-white shadow rounded-lg p-6" }
const _hoisted_21 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_22 = { class: "space-y-4" }
const _hoisted_23 = { class: "flex justify-between border-b border-gray-200 pb-2" }
const _hoisted_24 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_25 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_26 = { class: "text-sm text-gray-500" }
const _hoisted_27 = { class: "text-sm font-semibold text-gray-900" }
const _hoisted_28 = { class: "bg-white shadow rounded-lg p-6 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1" }

import { ref, Ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { toastServiceError } from "@/utils/notification";
import { UiPageHeader, BarChart, UiBadge, UiDatePicker } from "@/components";
import { BillingReportFilters } from "./components"; // Importação dos filtros
import { IBilling, IFilterItem, IBarChart, UiActions } from "@/definitions";
import {
  BillingReportActions,
  BillingReportGetters,
  FilterActions,
  FilterGetters,
} from "@/definitions";
import { BillingReportService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportPage',
  setup(__props) {

const { t } = useI18n();

const reportInfo: Ref<IBilling | null> = ref(null);

const getChart = (
  title: string,
  dataKey: keyof IBilling["dailyResults"][0],
  color = "#BFDBFE",
) => {
  const category =
    reportInfo.value?.dailyResults?.map((item) =>
      moment(item.date).format("D [de] MMM"),
    ) || [];
  const value =
    reportInfo.value?.dailyResults?.map((item) => item[dataKey] || 0) || [];

  return {
    title,
    category,
    series: [
      {
        name: title,
        value,
        color,
      },
    ],
  } as IBarChart;
};

const dailyConsumptionChart = computed(() =>
  getChart(t("report.billing.dailyConsumptionValue"), "cost", "#00C3AE"),
);

console.log(dailyConsumptionChart);

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (value) => store.dispatch(FilterActions.SET_DATE_RANGE, value),
});

const filter = computed(() => store.getters[BillingReportGetters.FILTER]);
const activeFilters = computed(
  () => store.getters[BillingReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  const params = {
    start_date: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    end_date: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    user_id: filter.value.user?.id || null,
    source_number: filter.value.sourceNumber?.value || null,
    destination_number: filter.value.destinationNumber?.value || null,
    status: filter.value.status?.value || null,
    type: filter.value.type?.value || null,
  };

  setLoading(true);

  BillingReportService.getInfo({ params })
    .then((response) => {
      reportInfo.value = response.data;
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(BillingReportActions.CLEAR_FILTER, filter.field);
}

watch([dateRange, filter], getReportData, { deep: true });

onMounted(() => {
  getReportData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.billing.title")), 1)
      ]),
      actions: _withCtx(() => [
        _createVNode(_unref(BillingReportFilters), {
          sources: reportInfo.value?.sources || [],
          destinations: reportInfo.value?.destinations || []
        }, null, 8, ["sources", "destinations"]),
        _createVNode(_unref(UiDatePicker), {
          modelValue: dateRange.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (activeFilters.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
            return (_openBlock(), _createBlock(_unref(UiBadge), {
              key: filter,
              removable: "",
              onRemove: ($event: any) => (handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.billing.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
              ]),
              _: 2
            }, 1032, ["onRemove"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("dl", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.billing.stats.totalMinutes")), 1),
        _createElementVNode("dd", _hoisted_7, _toDisplayString(`${Math.floor(reportInfo.value?.totalMinutes || 0)}:${Math.floor(
              (reportInfo.value?.totalMinutes % 1) * 60 || 0,
            )
              .toString()
              .padStart(2, "0")}`) + "s ", 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("dt", _hoisted_9, _toDisplayString(_ctx.$t("report.billing.stats.totalCost")), 1),
        _createElementVNode("dd", _hoisted_10, " R$ " + _toDisplayString(reportInfo.value?.totalCost?.toFixed(2) || "0,00"), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t("report.billing.stats.inbound")), 1),
        _createElementVNode("dl", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("dt", _hoisted_16, _toDisplayString(_ctx.$t("report.billing.stats.totalInboundCost")), 1),
            _createElementVNode("dd", _hoisted_17, " R$ " + _toDisplayString(reportInfo.value?.inboundCost?.toFixed(2) || "0,00"), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reportInfo.value?.costByOriginType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_18, _toDisplayString(_ctx.$t(`report.billing.stats.originType.${key}`)), 1),
              _createElementVNode("dd", _hoisted_19, " R$ " + _toDisplayString(value?.toFixed(2) || "0,00"), 1)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t("report.billing.stats.outbound")), 1),
        _createElementVNode("dl", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("dt", _hoisted_24, _toDisplayString(_ctx.$t("report.billing.stats.totalOutboundCost")), 1),
            _createElementVNode("dd", _hoisted_25, " R$ " + _toDisplayString(reportInfo.value?.outboundCost?.toFixed(2) || "0,00"), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reportInfo.value?.costByDestinationType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_26, _toDisplayString(_ctx.$t(`report.billing.stats.destinationType.${key}`)), 1),
              _createElementVNode("dd", _hoisted_27, " R$ " + _toDisplayString(value?.toFixed(2) || "0,00"), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createVNode(_unref(BarChart), {
        title: dailyConsumptionChart.value.title,
        categoryData: dailyConsumptionChart.value.category,
        seriesData: dailyConsumptionChart.value.series,
        column: true,
        style: {"width":"100%","height":"400px"}
      }, null, 8, ["title", "categoryData", "seriesData"])
    ])
  ]))
}
}

})