/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { AuthGetters, FilterGetters, ILevel, IUser } from "@/definitions";
import store from "@/store";

interface TrackingData {
  external_id_client: string; // Required: Customer's code in CX
  identifier: string; // Required: Name of screen or action
  type_tracking: "screen" | "action" | "login" | "metric"; // Required: Type of tracking
  email?: string; // Required if external_id_contact is not provided
  amount?: number; // Optional: Total operations
  external_id_product?: string; // Optional: Product code
  external_id_contact?: string; // Optional: External contact ID
  date_tracking?: string; // Optional: Retroactive tracking date (DD/MM/YYYY)
}

class CustomerXTrackingAPI {
  private static sendTracking(data: TrackingData) {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    if (
      process.env.VUE_APP_CUSTOMER_X_CREDENTIAL !== undefined &&
      process.env.VUE_APP_CUSTOMER_X_KEY !== undefined
    ) {
      axios({
        method: "post",
        maxBodyLength: Infinity,
        url: "https://tracker.customerx.com.br",
        headers: {
          "Content-Type": "application/json",
          credential: process.env.VUE_APP_CUSTOMER_X_CREDENTIAL,
          key: process.env.VUE_APP_CUSTOMER_X_KEY,
        },
        data: JSON.stringify(data),
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  /**
   * Send a tracking event of a screen to the CustomerX API.
   * @param {ILevel} level - The level to atribute the data to send.
   * @param {IUser} user - The user that did the navigation.
   * @param {string} page - The tracking data to send.
   */
  public static async trackingNavigation(
    level: ILevel,
    user: IUser,
    page: string,
  ) {
    if (level.trackingId && user.email && page) {
      this.sendTracking({
        external_id_client: level.trackingId,
        type_tracking: "screen",
        identifier: page,
        email: user.email,
      } as TrackingData);
    }
  }

  /**
   * Send a tracking event of an action to the CustomerX API.\
   * @param {ILevel} level - The level to atribute the data to send.
   * @param {IUser} user - The user that did the navigation.
   * @param {string} action - The tracking data to send.
   */
  public static async trackingAction(action: string) {
    const level = store.getters[FilterGetters.LEVEL];
    const user = store.getters[AuthGetters.USER];
    if (level.trackingId && user.email && action) {
      this.sendTracking({
        external_id_client: level.trackingId,
        identifier: action,
        type_tracking: "action",
        email: user.email,
      } as TrackingData);
    }
  }
}

export default CustomerXTrackingAPI;
