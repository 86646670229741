import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "multiselect-single-label" }
const _hoisted_3 = { class: "multiselect-multiple-label gap-1.5" }
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-danger absolute bg-red-50 px-2 py-2 w-full shadow z-10"
}

import { ErrorObject } from "@vuelidate/core";
import Multiselect from "@vueform/multiselect";
import { PropType, computed, ref, watch } from "vue";
import { IMultiselectMode } from "@/definitions";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMultiselect',
  props: {
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    default: null,
    type: [String, Number, Object, Boolean, null],
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "label",
  },
  valueProp: {
    type: String,
    default: "value",
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  primitive: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minCharMessage: {
    type: Boolean,
    default: false,
  },
  canClear: {
    type: Boolean,
    default: false,
  },
  canDeselect: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: String,
    validator: (value: string) => ["async", "local", "no"].includes(value),
    default: "no",
  },
  options: {
    type: [Array, Object, Function] as PropType<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any[] | object | ((query: string) => Promise<any[]>)
    >,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: [],
  },
  noResultsText: {
    default: function () {
      const { t } = useI18n();
      return t("core.multiselect.noResults");
    },
  },
  noOptionsText: {
    default: function () {
      const { t } = useI18n();
      return t("core.multiselect.noOptions");
    },
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const { t } = useI18n();
const searchQuery = ref("");

const selectedOption = computed({
  get() {
    if (props.primitive) {
      if (props.multiple) {
        return props.options.filter((option) => {
          return props.modelValue.includes(option[props.valueProp]);
        });
      } else {
        return props.options.find((option) => {
          return option[props.valueProp] === props.modelValue;
        });
      }
    }
    return props.modelValue;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set() {},
});

const multiselectMode = computed(() => {
  if (props.multiple) {
    return IMultiselectMode.MULTIPLE;
  }
  return IMultiselectMode.SINGLE;
});

const emit = __emit;
function handleInput(event: ProxyConstructor) {
  //Não achei uma solução boa para converter o Proxy para um objeto
  const data = JSON.parse(JSON.stringify(event));

  if (data === null) {
    emit("update:modelValue", data);
    return;
  }

  if (props.primitive) {
    if (props.multiple) {
      const mapData = data.map((i) => i[props.valueProp]);
      emit("update:modelValue", mapData);
    } else {
      emit("update:modelValue", data[props.valueProp]);
    }
  } else {
    emit("update:modelValue", data);
  }
}

const getContainerClass = computed(() =>
  !props.errors.length
    ? "border-gray-300 focus:ring-primary"
    : "border-danger focus:border-danger",
);

const computedNoOptionsText = computed(() => {
  if (
    searchQuery.value !== null &&
    searchQuery.value.length < 4 &&
    props.minCharMessage
  ) {
    return t("core.multiselect.minChar");
  }
  return t("core.multiselect.noResults");
});

watch(
  () => props.modelValue,
  (newValue) => {
    searchQuery.value = newValue;
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Multiselect), {
      classes: {
        container: `multiselect ${getContainerClass.value}`,
      },
      id: props.name,
      name: props.name,
      object: true,
      options: props.options,
      value: selectedOption.value,
      mode: multiselectMode.value,
      label: props.label,
      valueProp: props.valueProp,
      placeholder: props.placeholder,
      "close-on-select": multiselectMode.value === _unref(IMultiselectMode).SINGLE,
      "can-clear": props?.canClear,
      "can-deselect": props?.canDeselect,
      searchable: props.searchable !== 'no',
      minChars: 4,
      resolveOnLoad: false,
      delay: props.searchable === 'async' ? 300 : -1,
      disabled: __props.disabled,
      noResultsText: props.noResultsText,
      noOptionsText: computedNoOptionsText.value,
      class: _normalizeClass([__props.errors.length ? 'border-danger' : null, 'py-1.5']),
      onInput: handleInput,
      onSearchChange: _cache[0] || (_cache[0] = ($event: any) => (searchQuery.value = $event))
    }, {
      singlelabel: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "selected-label", { value: value }, () => [
            _createTextVNode(_toDisplayString(value[props.label]), 1)
          ])
        ])
      ]),
      multiplelabel: _withCtx(({ values }) => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values, (item) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `${props.name}${item.value}${item.value}`,
              class: "border border-gray-200 p-0.5 px-1.5 rounded"
            }, _toDisplayString(item[props.valueProp]) + " - " + _toDisplayString(item[props.label]), 1))
          }), 128))
        ])
      ]),
      option: _withCtx(({ option }) => [
        _renderSlot(_ctx.$slots, "option-label", { option: option }, () => [
          _createTextVNode(_toDisplayString(option[props.label]), 1)
        ])
      ]),
      tag: _withCtx(({ option, handleTagRemove, disabled }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["bg-gray-400 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1", {
            'is-disabled': disabled,
          }])
        }, [
          _createTextVNode(_toDisplayString(option[props.label]) + " ", 1),
          (!disabled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "multiselect-tag-remove",
                onMousedown: _withModifiers(($event: any) => (handleTagRemove(option, $event)), ["prevent"])
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "multiselect-tag-remove-icon" }, null, -1)
              ]), 40, _hoisted_4))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 3
    }, 8, ["classes", "id", "name", "options", "value", "mode", "label", "valueProp", "placeholder", "close-on-select", "can-clear", "can-deselect", "searchable", "delay", "disabled", "noResultsText", "noOptionsText", "class"]),
    (__props.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.$uid
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})