import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap flex-col gap-2" }
const _hoisted_2 = { class: "font-bold sm:truncate text-purple-400 flex flex-wrap flex-row mb-1" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = { class: "flex flex-wrap sm:flex-row gap-2" }

import { computed, ComputedRef } from "vue";
import { TagIcon } from "@heroicons/vue/solid";
import { CallPlayerGetters, ICall } from "@/definitions";
import { UiPanel, UiBadge } from "@/components";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTags',
  setup(__props) {

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

return (_ctx: any,_cache: any) => {
  return (call.value.tags.length > 0)
    ? (_openBlock(), _createBlock(_unref(UiPanel), { key: 0 }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_2, [
                _createVNode(_unref(TagIcon), { class: "h-6 w-6 text-purple-400 p-0.5" }),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("call.view.tags")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(call.value.tags, (tag) => {
                return (_openBlock(), _createBlock(_unref(UiBadge), {
                  class: "rounded-lg py-2",
                  variant: "purple",
                  key: `callTag${tag.id}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tag.value), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})