import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full" }
const _hoisted_2 = { class: "sm:fixed flex flex-col justify-between overflow-y-auto sm:w-60 h-full bg-primary" }
const _hoisted_3 = { class: "h-full pb-16" }
const _hoisted_4 = { class: "flex flex-shrink-0 items-center px-4 my-4" }
const _hoisted_5 = { class: "text-xl font-bold leading-7 text-white sm:truncate" }
const _hoisted_6 = { class: "w-full sm:pl-60" }
const _hoisted_7 = { class: "flex flex-col justify-between" }
const _hoisted_8 = { class: "w-full flex items-center justify-between" }
const _hoisted_9 = { class: "grid grid-cols-2 md:grid-cols-5 gap-4 w-full" }
const _hoisted_10 = { class: "flex items-center" }

import { computed, onMounted, ref, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import {
  TemplateHeader,
  TemplateSidebar,
  TemplateNavigation,
  TemplateProfile,
} from "@/views/templates/components";
import { UiButton, UiLoadingDialog, UiMultiselect } from "@/components";
import { HomeIcon } from "@heroicons/vue/outline";
import {
  FilterActions,
  FilterGetters,
  ILevel,
  NavigationGetters,
  UiGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfigTemplate',
  setup(__props) {

const loading = computed(() => store.getters[UiGetters.IS_LOADING]);
const configSideNavigationItems = computed(
  () => store.getters[NavigationGetters.CONFIG_SIDE_NAVIGATION_ITEMS],
);

const handleBackToAppClick = () => {
  router.push("/");
};

const levels: Ref<ILevel[]> = ref([]);

const level = computed<ILevel>({
  get: () => store.getters[FilterGetters.LEVEL],
  set: (level: ILevel) => store.dispatch(FilterActions.SET_LEVEL, level),
});

onMounted(() => {
  LevelService.getAllGlobalSelect<ILevel[]>().then((response) => {
    levels.value = response.data;
  });
});

const disabledIfRouterWithId = computed(() => {
  if (router.currentRoute.value.params !== undefined) {
    return router.currentRoute.value.params.id !== undefined;
  }
  return false;
});

const headerClass = computed(() => {
  if (process.env.NODE_ENV === "production") {
    return "bg-slate-100";
  }

  if (process.env.NODE_ENV === "development") {
    return "bg-teal-700";
  }

  return "bg-gray-700";
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TemplateSidebar), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, { to: { name: 'Configurations' } }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("sideNavigation.config")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_unref(TemplateNavigation), { items: configSideNavigationItems.value }, null, 8, ["items"])
          ]),
          _createVNode(_unref(TemplateProfile))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass([headerClass.value, "p-2 sm:px-4 sm:py-2 shadow flex items-center sticky top-0 z-30"])
        }, [
          _createElementVNode("header", _hoisted_8, [
            _createVNode(_unref(TemplateHeader), null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_unref(UiMultiselect), {
                    name: "template_level",
                    label: "name",
                    "value-prop": "id",
                    modelValue: level.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((level).value = $event)),
                    options: levels.value,
                    class: "col-span-2",
                    disabled: disabledIfRouterWithId.value
                  }, null, 8, ["modelValue", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(UiButton), {
                    size: "sm",
                    variant: "inherit",
                    "text-variant": "gray-400",
                    shadow: "none",
                    class: "border-transparent focus:ring-primary flex items-center ml-auto whitespace-nowrap",
                    onClick: handleBackToAppClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(HomeIcon), {
                        class: "h-5 w-5 mr-1",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("sideNavigation.BackToDashboard")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ])
        ], 2),
        _createElementVNode("main", null, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createVNode(_unref(UiLoadingDialog), {
        show: loading.value,
        text: _ctx.$t('ui-loading-dialog-text')
      }, null, 8, ["show", "text"])
    ])
  ]))
}
}

})