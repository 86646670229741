import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-8 grid grid-cols-12 gap-4" }
const _hoisted_5 = { class: "bg-white rounded-lg shadow w-full h-[200px] col-span-12 md:h-[277px] md:col-span-4 md:p-6" }
const _hoisted_6 = { class: "col-span-12 md:col-span-8" }
const _hoisted_7 = { class: "mb-2 text-lg font-semibold" }
const _hoisted_8 = { class: "mt-0 px-0 py-0 overflow-y-auto h-60 bg-white rounded-lg shadow" }
const _hoisted_9 = { class: "mt-8 grid grid-cols-12 gap-4" }
const _hoisted_10 = { class: "bg-white rounded-lg shadow w-full h-[200px] col-span-12 md:h-[277px] md:col-span-4" }
const _hoisted_11 = { class: "col-span-12 md:col-span-8" }
const _hoisted_12 = { class: "mb-2 text-lg font-semibold" }
const _hoisted_13 = { class: "mt-0 px-0 py-0 overflow-y-auto h-60 bg-white rounded-lg shadow" }

import { computed, onUnmounted, ref, Ref, watch } from "vue";
import { UiPageHeader } from "@/components";
import {
  UsersStatusDoughnutChart,
  CallQueueGaugeChart,
  QueueDetailsTable,
  UsersExtensionsDetailsTable,
  DataTotalize,
} from "@/views/pages/reports/realtime/components";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { FilterGetters, IDataTotalize, ILevel } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'RealtimeReportPage',
  setup(__props) {

const dataTotalize: Ref<IDataTotalize> = ref({
  callQueue: 0,
  callInboundInProgress: undefined,
  callOutboundInProgress: undefined,
  userOffline: undefined,
  userOnline: undefined,
  averageQuality: undefined,
});

const loading = ref(true);

const getData = () => {
  loading.value = true;

  RealtimeReportService.getDataTotalize<IDataTotalize>()
    .then((response) => {
      dataTotalize.value.callQueue = response.data.callQueue;
      dataTotalize.value.callInboundInProgress =
        response.data.callInboundInProgress;
      dataTotalize.value.callOutboundInProgress =
        response.data.callOutboundInProgress;
      dataTotalize.value.userOffline = response.data.userOffline;
      dataTotalize.value.userOnline = response.data.userOnline;
      dataTotalize.value.averageQuality = response.data.averageQuality;
    }, toastServiceError)
    .finally(() => (loading.value = false));
};

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  dataTotalize.value.callQueue = 0;
  dataTotalize.value.callInboundInProgress = undefined;
  dataTotalize.value.callOutboundInProgress = undefined;
  dataTotalize.value.userOffline = undefined;
  dataTotalize.value.userOnline = undefined;
  dataTotalize.value.averageQuality = undefined;
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.realtime.title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(DataTotalize), {
        data: dataTotalize.value,
        loading: loading.value
      }, null, 8, ["data", "loading"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(CallQueueGaugeChart), {
          data: dataTotalize.value.callQueue,
          loading: loading.value
        }, null, 8, ["data", "loading"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.title")), 1),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(QueueDetailsTable))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_unref(UsersStatusDoughnutChart), {
          data: dataTotalize.value,
          loading: loading.value
        }, null, 8, ["data", "loading"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.title")), 1),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_unref(UsersExtensionsDetailsTable))
        ])
      ])
    ])
  ]))
}
}

})