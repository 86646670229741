import {
  ISelectOptionsServiceGetUsersResult,
  ISelectOptionsServiceGetUsersLabelsResult,
  ISelectOptionsServiceGetWordsResult,
} from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class SelectOptionsService extends BaseService<ISelectOptionsServiceGetUsersResult> {
  getUsers(config?: AxiosRequestConfig) {
    return this.http.get<ISelectOptionsServiceGetUsersResult[]>(
      `${this.route}/users`,
      config,
    );
  }

  getUsersLabels(config?: AxiosRequestConfig) {
    return this.http.get<ISelectOptionsServiceGetUsersLabelsResult[]>(
      `${this.route}/users-labels`,
      config,
    );
  }

  getWords(config?: AxiosRequestConfig) {
    return this.http.get<ISelectOptionsServiceGetWordsResult[]>(
      `${this.route}/words`,
      config,
    );
  }
}

export default new SelectOptionsService("select-options");
