import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50" }
const _hoisted_2 = { class: "px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_8 = { class: "sm:flex" }
const _hoisted_9 = { class: "flex items-center text-sm text-gray-500 mr-4" }
const _hoisted_10 = { class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6 mr-4" }
const _hoisted_11 = { class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6" }
const _hoisted_12 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
}
const _hoisted_13 = {
  key: 1,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
}
const _hoisted_14 = {
  key: 2,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
}
const _hoisted_15 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_16 = ["datetime"]

import { computed, PropType } from "vue";
import router from "@/router";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import {
  CalendarIcon,
  FolderIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  UsersIcon,
} from "@heroicons/vue/solid";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import { PencilAltIcon, TagIcon } from "@heroicons/vue/outline";
import { INumber, Permission } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListItem',
  props: {
  modelValue: {
    type: Object as PropType<INumber>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleEditClick = () => {
  router.push({
    name: "EditNumberById",
    params: {
      id: props.modelValue.id,
    },
  });
};

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_NUMBER) ? 'RouterLink' : 'span'), {
            to: {
              name: 'ViewNumberById',
              params: {
                id: __props.modelValue.id,
              },
            },
            class: "truncate text-md font-medium text-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(applyPhoneMask)(__props.modelValue.number)), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        ]),
        (_ctx.$can(_unref(Permission).EDIT_NUMBER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                onClick: handleEditClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("core.actions.Edit")), 1),
                  _createVNode(_unref(PencilAltIcon), {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, [
            _createVNode(_unref(FolderIcon), {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(__props.modelValue.level.name), 1)
          ]),
          _createElementVNode("p", _hoisted_10, [
            _createVNode(_unref(LocationMarkerIcon), {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(__props.modelValue.city) + "/" + _toDisplayString(__props.modelValue.state), 1)
          ]),
          _createElementVNode("p", _hoisted_11, [
            _createVNode(_unref(InformationCircleIcon), {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(`number.types.${__props.modelValue?.type}`)), 1)
          ]),
          (__props.modelValue.connectFlow)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                _createVNode(_unref(ConnectFlowIcon), {
                  class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                  "aria-hidden": "true"
                }),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_CONNECT_FLOW) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ModelingConnectFlow',
                params: {
                  id: __props.modelValue.connectFlow.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.modelValue.connectFlow.name), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              ]))
            : _createCommentVNode("", true),
          (__props.modelValue.mediaLabel)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                _createVNode(_unref(TagIcon), {
                  class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(__props.modelValue.mediaLabel), 1)
              ]))
            : _createCommentVNode("", true),
          (__props.modelValue.usersNumber?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                _createVNode(_unref(UsersIcon), {
                  class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t(
                "number.list.agentCount",
                props.modelValue.usersNumber.length || 0,
                {
                  named: { n: props.modelValue.usersNumber.length },
                },
              )), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (lastModifiedBy.value != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_unref(CalendarIcon), {
                class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(__props.modelValue.updatedByUser
                ? _ctx.$t("core.updatedAt")
                : _ctx.$t("core.createdAt")) + " ", 1),
                _createElementVNode("time", {
                  datetime: __props.modelValue.updatedAt || __props.modelValue.createdAt
                }, _toDisplayString(_unref(formatDateAsString)(
                  __props.modelValue.updatedAt || __props.modelValue.createdAt,
                  "LL",
                )), 9, _hoisted_16),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("core.by")) + " ", 1),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ViewUserById',
                params: {
                  id: lastModifiedBy.value.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(lastModifiedBy.value.name), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})