import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = {
  class: "flex flex-1 px-4 sm:px-6 h-16 border-b border-gray-200 bg-white shadow-sm",
  action: "#",
  method: "GET"
}
const _hoisted_5 = { class: "max-w-md min-w-full overflow-x-auto" }
const _hoisted_6 = { class: "min-w-full" }
const _hoisted_7 = { class: "bg-gray-50" }
const _hoisted_8 = {
  scope: "col",
  class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
}
const _hoisted_9 = {
  scope: "col",
  class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_10 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_11 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" }
const _hoisted_12 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900" }
const _hoisted_13 = ["checked", "onChange"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center" }

import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import RoleService from "@/services/RoleService";
import PermissionService from "@/services/PermissionService";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
} from "@/components";
import { PencilIcon } from "@heroicons/vue/solid";
import {
  IRole,
  IPermission,
  Permission,
  PermissionGetters,
  PermissionActions,
  UiActions,
} from "@/definitions";
import { LockClosedIcon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const roles: Ref<IRole[]> = ref([]);
const permissions: Ref<IPermission[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[PermissionGetters.SEARCH],
  set: (search: string) => store.dispatch(PermissionActions.SET_SEARCH, search),
});

const orderBy: Ref<string> = ref("permission.description");

const currentPage = computed<number>({
  get: () => store.getters[PermissionGetters.PAGE],
  set: (page: number) => store.dispatch(PermissionActions.SET_PAGE, page),
});

const descriptionalizedPermissions = computed<IPermission[]>(() => {
  return permissions.value.map((p) => {
    p.description = t("permission.permissions." + p.id);
    return p;
  });
});

const filteredPermissions = computed<IPermission[]>(() => {
  return descriptionalizedPermissions.value.filter((p) => {
    return (
      !search.value ||
      p.description?.toLowerCase().includes(search.value.toLowerCase())
    );
  });
});

const paginatedPermissions = computed<IPermission[]>(() => {
  return filteredPermissions.value.slice(
    (currentPage.value - 1) * perPage,
    currentPage.value * perPage,
  );
});

if (route.query.clearStateOnSetup) {
  search.value = "";
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getRolesAndPermissions = () => {
  setLoading(true);
  Promise.all([
    RoleService.getAll<IRole[]>(),
    PermissionService.getAll<IPermission[]>(),
  ])
    .then(([roleResponse, permissionResponse]) => {
      roles.value = roleResponse.data;
      permissions.value = permissionResponse.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

getRolesAndPermissions();

watch([search, orderBy], () => {
  currentPage.value = 1;
});

const handleAddRemovePermissionClick = (
  role: IRole,
  permission: IPermission,
) => {
  setLoading(true);
  const permissionRole = permission.roles.find((r: IRole) => r.id == role.id);
  if (permissionRole) {
    RoleService.removePermission(role.id, permission.id)
      .then(
        () =>
          permission.roles.splice(permission.roles.indexOf(permissionRole), 1),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    RoleService.addPermission(role.id, permission.id)
      .then(() => permission.roles.push(role), toastServiceError)
      .finally(() => setLoading(false));
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(LockClosedIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Permissions")), 1)
        ])
      ]),
      actions: _withCtx(() => [
        (_ctx.$can(_unref(Permission).CREATE_ROLE))
          ? (_openBlock(), _createBlock(_unref(RouterLink), {
              key: 0,
              to: {
            name: 'CreateRole',
          }
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(UiButton), {
                  variant: "primary",
                  "text-variant": "white",
                  themed: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("permission.actions.newRole")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", _hoisted_4, [
        _createVNode(_unref(UiListSearch), {
          modelValue: search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", _hoisted_7, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("permission.labels.module")), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("permission.labels.name")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roles.value, (role) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: role.id,
                  scope: "col",
                  class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_ROLE) ? _unref(RouterLink) : 'span'), {
                    to: {
                    name: 'ViewRoleById',
                    params: {
                      id: role.id,
                    },
                  },
                    class: "truncate text-sm font-medium text-primary inline-flex items-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(role.name) + " ", 1),
                      (_ctx.$can(_unref(Permission).VIEW_ROLE))
                        ? (_openBlock(), _createBlock(_unref(PencilIcon), {
                            key: 0,
                            class: "ml-1 h-4 w-4",
                            "aria-hidden": "true"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("tbody", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedPermissions.value, (permission) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: permission.id
              }, [
                _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t(`permission.modules.${permission.module}`)), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(permission.description), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roles.value, (role) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: role.id,
                    class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      checked: 
                    permission.roles.find((r) => r.id == role.id)
                      ? true
                      : false
                  ,
                      onChange: ($event: any) => (handleAddRemovePermissionClick(role, permission))
                    }, null, 40, _hoisted_13), [
                      [_directive_tippy, {
                    content: permission.description,
                    placement: 'left',
                  }]
                    ])
                  ]))
                }), 128))
              ]))
            }), 128)),
            (!paginatedPermissions.value.length)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                  _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
        count: filteredPermissions.value.length,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})