import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium mb-3 text-blue-900" }
const _hoisted_4 = { class: "flex flex-row gap-5" }
const _hoisted_5 = { class: "px-4 py-5 bg-white shadow rounded-lg sm:p-3 border-l-4 basis-1/2 border-blue-900" }
const _hoisted_6 = { class: "mb-3 text-gray-700 flex" }
const _hoisted_7 = { class: "survey-answers-container" }
const _hoisted_8 = { class: "font-semibold" }

import { computed, ComputedRef } from "vue";
import { ICallSurvey } from "@/definitions";
import { ISurveyQuestionAnswer } from "@/definitions/";
import { AnnotationIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallSurveyAnswers',
  props: {
  surveyAnswers: {
    type: Object as () => ICallSurvey,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const formattedAnswers: ComputedRef<
  { label: string; value: string; rangeAnswer: string }[]
> = computed(() => {
  return props.surveyAnswers.surveyQuestions.map(
    (answer: ISurveyQuestionAnswer) => ({
      label: String(answer.label),
      value: String(answer.value),
      rangeAnswer: String(answer.rangeAnswer),
    }),
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("survey.view.section.level.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, [
          _createVNode(_unref(AnnotationIcon), { class: "h-6 w-6 mr-1" }),
          _createTextVNode(" " + _toDisplayString(__props.surveyAnswers.name), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedAnswers.value, (answer, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "text-sm"
              }, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(answer.label) + " (" + _toDisplayString(answer.rangeAnswer) + "):", 1),
                _createTextVNode(" " + _toDisplayString(answer.value), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})