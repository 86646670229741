import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import orderBy from "lodash.orderby";
import { useI18n } from "vue-i18n";
import { colord } from "colord";
import { BarChart } from "@/components";
import { CallType, IDataItem, IUserAgentCallsStats } from "@/definitions";

interface ChartData {
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTypePerUserBarChart',
  props: {
  title: {
    type: String,
    required: true,
  },
  data: {
    type: Array<IUserAgentCallsStats>,
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const baseColor = colord("#0FA5E8");

const chartData = computed<ChartData>(() => {
  const topUsers = orderBy(
    props.data,
    (user) => {
      return (
        user.type[CallType.INBOUND] +
        user.type[CallType.OUTBOUND] +
        user.type[CallType.INTERNAL]
      );
    },
    "desc",
  ).slice(0, 10);

  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t("core.options.call.type.inbound"),
      color: baseColor.toHex(),
    },
    {
      value: [],
      name: t("core.options.call.type.outbound"),
      color: baseColor.lighten(0.2).toHex(),
    },
    {
      value: [],
      name: t("core.options.call.type.internal"),
      color: baseColor.lighten(0.4).toHex(),
    },
  ];

  for (let i = topUsers.length - 1; i >= 0; i--) {
    const user = topUsers[i];

    category.push(user.name);

    series[0].value.push(user.type[CallType.INBOUND]);
    series[1].value.push(user.type[CallType.OUTBOUND]);
    series[2].value.push(user.type[CallType.INTERNAL]);
  }

  return {
    category,
    series,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BarChart), {
    title: props.title,
    categoryData: chartData.value.category,
    seriesData: chartData.value.series
  }, null, 8, ["title", "categoryData", "seriesData"]))
}
}

})