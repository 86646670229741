import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-40 flex" }
const _hoisted_2 = { class: "absolute top-0 right-0 -mr-12 pt-2" }

import { computed } from "vue";
import store from "@/store";
import { NavigationGetters, NavigationActions } from "@/definitions";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateSidebar',
  setup(__props) {

const sidebarOpen = computed(
  () => store.getters[NavigationGetters.SIDE_NAVIGATION_OPEN],
);

function closeSidebar() {
  store.dispatch(NavigationActions.TOGGLE_SIDE_NAVIGATION, true);
}

function handleDialogCLose() {
  closeSidebar();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(TransitionRoot), {
      as: "template",
      show: sidebarOpen.value
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Dialog), {
          as: "div",
          class: "relative z-40 md:hidden",
          onClose: handleDialogCLose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TransitionChild), {
              as: "template",
              enter: "transition-opacity ease-linear duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "transition-opacity ease-linear duration-300",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "fixed inset-0 bg-gray-600 bg-opacity-75" }, null, -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(TransitionChild), {
                as: "template",
                enter: "transition ease-in-out duration-300 transform",
                "enter-from": "-translate-x-full",
                "enter-to": "translate-x-0",
                leave: "transition ease-in-out duration-300 transform",
                "leave-from": "translate-x-0",
                "leave-to": "-translate-x-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogPanel), { class: "relative flex w-full max-w-xs flex-1 flex-col bg-primary" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TransitionChild), {
                        as: "template",
                        enter: "ease-in-out duration-300",
                        "enter-from": "opacity-0",
                        "enter-to": "opacity-100",
                        leave: "ease-in-out duration-300",
                        "leave-from": "opacity-100",
                        "leave-to": "opacity-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
                              onClick: closeSidebar
                            }, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close sidebar", -1)),
                              _createVNode(_unref(XIcon), {
                                class: "h-6 w-6 text-white",
                                "aria-hidden": "true"
                              })
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _renderSlot(_ctx.$slots, "default")
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-14 flex-shrink-0" }, null, -1))
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["show"]),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}
}

})