import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5 w-10/12" }
const _hoisted_2 = ["name", "id"]
const _hoisted_3 = { class: "ml-3 text-sm font-medium leading-6 text-gray-900" }

import { computed } from "vue";
import { useAttrs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormDevice',
  props: {
  modelValue: {
    type: Boolean,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const attrs = useAttrs();

const props = __props;

const emit = __emit;

const checkedValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([
      checkedValue.value ? 'bg-cyan-50 text-cyan-600' : 'bg-gray-50 text-gray-400',
      'hover:bg-cyan-50 hover:text-cyan-600 rounded shadow ring-1 ring-black ring-opacity-5 w-40 h-40',
      'flex flex-col items-center cursor-pointer relative',
    ])
  }, [
    _renderSlot(_ctx.$slots, "icon"),
    _renderSlot(_ctx.$slots, "extra-info"),
    _createElementVNode("span", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        name: _unref(attrs).name,
        id: _unref(attrs).name,
        type: "checkbox",
        class: "h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkedValue).value = $event))
      }, null, 8, _hoisted_2), [
        [_vModelCheckbox, checkedValue.value]
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.label), 1)
    ])
  ], 2))
}
}

})