import { IBilling } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class BillingReportService extends BaseService<IBilling> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IBilling>(`${this.route}`, config);
  }
}

export default new BillingReportService("reports/billing");
