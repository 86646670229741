import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_5 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_6 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_8 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-1.5"
}
const _hoisted_9 = { class: "min-w-full divide-y divide-gray-300" }
const _hoisted_10 = { class: "bg-gray-200" }
const _hoisted_11 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_12 = {
  key: 0,
  class: "whitespace-nowrap p-4 text-sm text-gray-900 text-center",
  colspan: "7"
}

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { IntegrationService } from "@/services";
import { goBack } from "@/utils/navigation";
import { toastServiceError } from "@/utils/notification";
import {
  UiBadge,
  UiButton,
  UiListSearch,
  UiPageHeader,
  UiPagination,
  UiSortMenuBadge,
} from "@/components";

import {
  IntegrationLogListFilters,
  IntegrationLogListItem,
  IntegrationLogListSort,
} from "@/views/pages/integration/components";
import {
  FilterGetters,
  IFilterItem,
  IIntegrationLog,
  IIntegrationLogServiceGetAllParams,
  ILevel,
  IntegrationLogActions,
  IntegrationLogGetters,
  ISortMenuItem,
  UiActions,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { LinkIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogPage',
  props: {
  type: {
    type: String,
  },
},
  setup(__props) {

const route = useRoute();
const router = useRouter();

const props = __props;

const integrationId = parseInt(route.params.id as string);

const cols = [
  "callId",
  "callCreatedAt",
  "callType",
  "callStatus",
  "callSource",
  "callDestination",
  "createdAt",
  "status",
  "externalId",
  "observation",
];

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const integrationLogs: Ref<IIntegrationLog[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[IntegrationLogGetters.SEARCH],
  set: (search: string) =>
    store.dispatch(IntegrationLogActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[IntegrationLogGetters.SORT],
  set: (sort: ISortMenuItem[]) =>
    store.dispatch(IntegrationLogActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[IntegrationLogGetters.PAGE],
  set: (page: number) => store.dispatch(IntegrationLogActions.SET_PAGE, page),
});

const count = ref(0);
search.value = "";
sortMenuSelected.value = [];
currentPage.value = 1;
router.replace({ query: {} });

const filter = computed(() => store.getters[IntegrationLogGetters.FILTER]);

const getIntegrationLogs = () => {
  const params: IIntegrationLogServiceGetAllParams = {
    page: currentPage.value,
    status: filter.value.status?.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  IntegrationService.getLogs<Array<IIntegrationLog>>(integrationId, {
    params,
  })
    .then((response: AxiosResponse<IIntegrationLog[]>) => {
      integrationLogs.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch(
  [level, search, filter, orderBy],
  () => {
    if (currentPage.value === 1) getIntegrationLogs();
    else currentPage.value = 1;
  },
  { deep: true },
);

watch([currentPage], () => {
  getIntegrationLogs();
});

getIntegrationLogs();

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[IntegrationLogGetters.ACTIVE_FILTERS],
);

function handleRemoveFilter(filter: string) {
  store.dispatch(IntegrationLogActions.CLEAR_FILTER, filter);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(LinkIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Integrations")) + " - " + _toDisplayString(_ctx.$t("integration.integrations." + props.type + ".title")) + " - " + _toDisplayString(_ctx.$t("integration.labels.logs")), 1)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListIntegration' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
              placeholder: _ctx.$t('core.actions.Search')
            }, null, 8, ["modelValue", "placeholder"])
          ], 32),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(IntegrationLogListSort), {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(IntegrationLogListFilters))
          ])
        ])
      ]),
      (sortMenuSelected.value.length || activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                key: filter,
                removable: "",
                onRemove: ($event: any) => (handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`integration.logs.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("table", _hoisted_9, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_10, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cols, (col) => {
              return _createElementVNode("th", {
                key: col,
                scope: "col",
                class: "whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              }, _toDisplayString(_ctx.$t(`integration.logs.cols.${col}`)), 1)
            }), 64))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(integrationLogs.value, (log, index) => {
            return (_openBlock(), _createBlock(_unref(IntegrationLogListItem), {
              key: log.id,
              "model-value": log,
              class: _normalizeClass({
              'bg-gray-100': index % 2,
            })
            }, null, 8, ["model-value", "class"]))
          }), 128)),
          (!integrationLogs.value.length)
            ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})