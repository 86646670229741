import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full" }
const _hoisted_2 = { class: "md:fixed flex flex-col justify-between overflow-y-auto sm:w-60 h-full bg-primary" }
const _hoisted_3 = { class: "h-full pb-24" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w-full md:pl-60" }
const _hoisted_6 = { class: "flex flex-col justify-between" }
const _hoisted_7 = { class: "w-full flex items-center justify-between" }
const _hoisted_8 = { class: "grid grid-cols-2 md:grid-cols-5 gap-4 w-full" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = ["value"]

import { ref, computed, onMounted, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import { RouterView } from "vue-router";
import {
  TemplateHeader,
  TemplateSidebar,
  TemplateNavigation,
  TemplateProfile,
} from "@/views/templates/components";
import { UiButton, UiMultiselect, UiLoadingDialog } from "@/components";
import { CogIcon, BellIcon, LogoutIcon } from "@heroicons/vue/outline";
import {
  ILevel,
  FilterGetters,
  FilterActions,
  NavigationGetters,
  UiGetters,
  UiActions,
  AuthGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTemplate',
  setup(__props) {

const logoPath = computed(() => {
  const company = store.getters[AuthGetters.USER_COMPANY];
  if (company.templateLogo) {
    return company.templateLogo;
  }
  return "/img/logo-mob.png";
});

const loading = computed(() => store.getters[UiGetters.IS_LOADING]);

const levels: Ref<ILevel[]> = ref([]);

const level = computed<ILevel>({
  get: () => store.getters[FilterGetters.LEVEL],
  set: (level: ILevel) => store.dispatch(FilterActions.SET_LEVEL, level),
});

const sideNavigationItems = computed(
  () => store.getters[NavigationGetters.SIDE_NAVIGATION_ITEMS],
);

onMounted(() => {
  LevelService.getAllGlobalSelect<ILevel[]>().then((response) => {
    levels.value = response.data;
  });
});

const handleConfigClick = () => {
  router.push("/config");
};

const handleLogoutClick = () => {
  router.push("/logout");
};

const handleLocaleChange = (event: Event) => {
  const selectedLocale = (event.target as HTMLInputElement).value;
  store.dispatch(UiActions.SET_LOCALE, selectedLocale);
};

const showLogout = computed(() => {
  return location.host !== "embed.mobcall.com";
});

const headerClass = computed(() => {
  if (process.env.NODE_ENV === "production") {
    return "bg-slate-100";
  }

  if (process.env.NODE_ENV === "development") {
    return "bg-teal-700";
  }

  return "bg-gray-700";
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TemplateSidebar), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: { name: 'Home' } }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "mt-3 ml-3 mb-8 h-12",
                  src: logoPath.value,
                  alt: "Logo Mobcall"
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_unref(TemplateNavigation), { items: sideNavigationItems.value }, null, 8, ["items"])
          ]),
          _createVNode(_unref(TemplateProfile))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass([headerClass.value, "p-2 sm:px-4 sm:py-2 shadow flex items-center sticky top-0 z-30"])
        }, [
          _createElementVNode("header", _hoisted_7, [
            _createVNode(_unref(TemplateHeader), null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(UiMultiselect), {
                    name: "template_level",
                    label: "name",
                    "value-prop": "id",
                    modelValue: level.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((level).value = $event)),
                    searchable: "local",
                    options: levels.value,
                    class: "col-span-2"
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  false
                    ? _withDirectives((_openBlock(), _createElementBlock("select", {
                        key: 0,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                        class: "rounded-md inline-flex py-1 pl-2 pr-7 sm:mr-1 border border-transparent text-gray-400 hover:text-gray-500 focus:outline-none bg-inherit hover:bg-inherit focus:ring-primary",
                        onChange: handleLocaleChange
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: `locale-${locale}`,
                            value: locale
                          }, _toDisplayString(locale), 9, _hoisted_10))
                        }), 128))
                      ], 544)), [
                        [_vModelSelect, _ctx.$i18n.locale]
                      ])
                    : _createCommentVNode("", true),
                  _createVNode(_unref(UiButton), {
                    size: "xs",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "gray-400",
                    shadow: "none",
                    class: "border-transparent focus:ring-primary shadow-none mr-1",
                    onClick: handleConfigClick
                  }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Config", -1)),
                      _createVNode(_unref(CogIcon), {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  }),
                  false
                    ? (_openBlock(), _createBlock(_unref(UiButton), {
                        key: 1,
                        size: "xs",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "gray-400",
                        shadow: "none",
                        class: "border-transparent focus:ring-primary shadow-none mr-1"
                      }, {
                        default: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "View notifications", -1)),
                          _createVNode(_unref(BellIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (showLogout.value)
                    ? (_openBlock(), _createBlock(_unref(UiButton), {
                        key: 2,
                        size: "xs",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "gray-400",
                        shadow: "none",
                        class: "border-transparent focus:ring-primary shadow-none",
                        onClick: handleLogoutClick
                      }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only" }, "Logout", -1)),
                          _createVNode(_unref(LogoutIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ])
        ], 2),
        _createElementVNode("main", null, [
          (level.value)
            ? (_openBlock(), _createBlock(_unref(RouterView), {
                key: _ctx.$route.fullPath
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_unref(UiLoadingDialog), {
        show: loading.value,
        text: _ctx.$t('ui-loading-dialog-text')
      }, null, 8, ["show", "text"])
    ])
  ]))
}
}

})