import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_4 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { SpamNumberService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import router from "@/router";
import {
  UiButton,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiTextInput,
} from "@/components";
import {
  UiActions,
  FormAction,
  ISpamNumber,
  IDefaultServiceResult,
  ISpamNumberFormState,
  ISpamNumberServiceCreateResult,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<ISpamNumberFormState>({
  id: 0,
  number: null,
});

const rules = computed(() => ({
  id: {},
  number: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ISpamNumberFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSpamNumber = (id: number) => {
  setLoading(true);
  SpamNumberService.get<ISpamNumber>(id)
    .then((response: AxiosResponse<ISpamNumber>) => {
      formState.id = id;
      formState.number = response.data.number;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getSpamNumber(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const id = formState.id || 0;
  if (props.action == FormAction.EDIT) {
    SpamNumberService.update<IDefaultServiceResult>(id, formState)
      .then((response: AxiosResponse<IDefaultServiceResult>) => {
        toast.success(response.data.message);
        v$.value.$reset();
        router.push({
          name: "ListSpamNumber",
        });
      }, toastServiceError)
      .finally(() => setLoading(false));
  } else {
    SpamNumberService.create<ISpamNumberServiceCreateResult>(formState)
      .then((response: AxiosResponse<ISpamNumberServiceCreateResult>) => {
        v$.value.$reset();
        toast.success(response.data.message);
        Analytics.trackingAction(`NewSpanNumber`).then();
        goBack({
          name: "ListSpamNumber",
        });
      }, toastServiceError)
      .finally(() => {
        setLoading(false);
      });
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("spamNumber." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_unref(UiTextInput), {
                modelValue: formState.number,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.number) = $event)),
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('spamNumber.labels.number'),
                class: "col-span-6 sm:col-span-4",
                onBlur: _unref(v$).number.$touch
              }, null, 8, ["modelValue", "label", "onBlur"])
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListSpamNumber' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_unref(UiButton), {
          type: "submit",
          variant: "primary",
          "text-variant": "white",
          themed: "",
          class: "ml-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
          ]),
          _: 1
        })
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})