import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-5" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-5" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IAgentProductivityPerDayReportFilter,
  AgentProductivityPerDayReportGetters,
  AgentProductivityPerDayReportActions,
  IUser,
  ILevel,
  FilterGetters,
  IUserLabel,
} from "@/definitions";
import { UserService, UserLabelService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportFilters',
  setup(__props) {

const { t } = useI18n();

const filterOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const defaultFilter = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.DEFAULT_FILTER],
);
const filter: Ref<IAgentProductivityPerDayReportFilter> = ref({
  user: defaultFilter.value.user,
  userLabel: defaultFilter.value.userLabel,
});
const storeFilter = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  if (level.value)
    UserService.getAll<IUser[]>({
      params: {
        isAgent: true,
        page: 1,
        per_page: 100,
        level: level.value.id,
      },
    }).then((response) => (userOptions.value = response.data));
};

const userLabelOptions: Ref<IUserLabel[]> = ref([]);
const getUserLabels = () => {
  if (level.value)
    UserLabelService.getAll<IUserLabel[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
};

function applyFilters() {
  store.dispatch(AgentProductivityPerDayReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.user = storeFilter.value.user;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

watch(level, () => {
  store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER, "user");
  getUsers();
  getUserLabels();
});

onMounted(() => {
  getUsers();
  getUserLabels();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("report.agentProductivityPerAgent.filters.userLabel")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_user_label",
              modelValue: filter.value.userLabel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.userLabel) = $event)),
              label: "label",
              "value-prop": "id",
              options: userLabelOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("report.agentProductivityPerDay.filters.user")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_user",
              modelValue: filter.value.user,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.user) = $event)),
              label: "name",
              "value-prop": "id",
              options: userOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})