import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pb-6 border-b border-gray-200" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "text-base font-semibold leading-6 text-gray-900 mb-6" }
const _hoisted_10 = { class: "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "flex items-center text-sm text-gray-500" }

import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { QueueService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IQueue, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiGravatar, UiPanel } from "@/components";
import { MailIcon } from "@heroicons/vue/solid";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDetailPage',
  setup(__props) {

const route = useRoute();

const queue: Ref<IQueue | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getQueue = (id: number) => {
  setLoading(true);
  QueueService.get<IQueue>(id)
    .then((response: AxiosResponse<IQueue>) => {
      queue.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getQueue(parseInt(route.params.id as string));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("queue.view.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("queue.view.description")), 1)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListQueue' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
            ]),
            _: 1
          }),
          (_ctx.$can(_unref(Permission).EDIT_QUEUE))
            ? (_openBlock(), _createBlock(_unref(RouterLink), {
                key: 0,
                to: {
              name: 'EditQueueById',
              params: {
                id: _unref(route).params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("queue.labels.name")), 1),
              _createElementVNode("p", null, _toDisplayString(queue.value?.name), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("queue.labels.status")), 1),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  queue.value?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
              }, _toDisplayString(queue.value?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3)
            ])
          ]),
          _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("queue.view.section.users.title")), 1),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(queue.value?.users, (user) => {
              return (_openBlock(), _createElementBlock("div", {
                key: user.id,
                class: "bg-gray-50 p-3 rounded shadow ring-1 ring-black ring-opacity-5 flex items-center text-ellipsis overflow-hidden"
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_unref(UiGravatar), {
                    email: user.email,
                    name: user.name,
                    class: "h-12 w-12 rounded-full ring-1 ring-black ring-opacity-5"
                  }, null, 8, ["email", "name"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER) ? _unref(RouterLink) : 'p'), {
                    to: {
                    name: 'ViewUserById',
                    params: {
                      id: user?.id,
                    },
                  },
                    class: "truncate text-sm font-medium text-primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(user?.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])),
                  _createElementVNode("p", _hoisted_13, [
                    _createVNode(_unref(MailIcon), {
                      class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(user?.email), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})