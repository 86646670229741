import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 cursor-pointer" }
const _hoisted_3 = { class: "flex items-center mt-3 space-x-2" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = {
  key: 0,
  class: "bg-red-100 text-red-700 border border-red-300 px-1.5 py-0.5 rounded-md text-xs font-semibold"
}
const _hoisted_7 = { class: "flex gap-2" }

import { computed, onMounted, ref, WritableComputedRef } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { ArrowsExpandIcon } from "@heroicons/vue/outline";
import { UiPageHeader, UiButton } from "@/components";
import { VueFlow, useVueFlow } from "@vue-flow/core";
import { Background } from "@vue-flow/background";
import { Controls, ControlButton } from "@vue-flow/controls";
import { MiniMap } from "@vue-flow/minimap";
import {
  UiActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingActions,
  Permission,
  EdgeType,
  IConnectFlowFlowResult,
  IConnectFlow,
} from "@/definitions";
import {
  ComponentSelector,
  CustomNode,
  FormClienteInfoEdge,
  FormAwaitableEdge,
} from "./components/modeling";
import {
  AwaitableEdge,
  ClientInfoEdge,
} from "@/views/pages/connect-flow/components/modeling/edges";
import { StartNode } from "./components/modeling/nodes";
import { useI18n } from "vue-i18n";
import { ConnectFlowService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { AxiosResponse } from "axios";
import { formatDateAsString } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowModelingPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const { t } = useI18n();
const route = useRoute();

const props = __props;

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlow: WritableComputedRef<IConnectFlow> = computed({
  get: () => store.getters["flow/" + ConnectFlowModelingGetters.CONNECT_FLOW],
  set: (value) =>
    store.dispatch(
      "flow/" + ConnectFlowModelingActions.SET_CONNECT_FLOW,
      value,
    ),
});

const elements = computed({
  get: () => store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS],
  set: (value) =>
    store.dispatch("flow/" + ConnectFlowModelingActions.SET_ELEMENTS, value),
});

const isAllElementsValid = computed(
  () =>
    store.getters["flow/" + ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID],
);

const fullScreen = ref(false);

onMounted(() => {
  setLoading(true);
  store
    .dispatch("flow/" + ConnectFlowModelingActions.LOAD_FLOW_FLOW, {
      id: route.params.id,
      historyId: route.params.historyId,
    })
    .then(() => {
      setLoading(false);
    });
});

function save() {
  setLoading(true);
  ConnectFlowService.saveFlow<IConnectFlowFlowResult>(
    route.params.id.toString(),
    elements.value,
  )
    .then((response: AxiosResponse<IConnectFlowFlowResult>) => {
      connectFlow.value = response.data.connectFlow;
      toast.success(response.data.message);
    })
    .finally(() => setLoading(false));
}

function publish() {
  setLoading(true);
  ConnectFlowService.saveFlow<IConnectFlowFlowResult>(
    route.params.id.toString(),
    elements.value,
  ).then(() => {
    ConnectFlowService.publishFlow<IConnectFlowFlowResult>(
      route.params.id.toString(),
      elements.value,
    )
      .then((response: AxiosResponse<IConnectFlowFlowResult>) => {
        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
      }, toastServiceError)
      .finally(() => setLoading(false));
  });
}

const { onPaneReady, onEdgeClick } = useVueFlow();

onPaneReady(({ fitView }) => {
  fitView();
});

const showFormClienteInfoEdge = ref(false);
const showFormAwaitableEdge = ref(false);
const triggeredEdge = ref();
onEdgeClick((event) => {
  if (event.edge.data.component === EdgeType.AWAITABLE) {
    showFormAwaitableEdge.value = true;
    triggeredEdge.value = event.edge.id;
  }
  if (event.edge.data.component === EdgeType.CLIENT_INFO_EDGE) {
    showFormClienteInfoEdge.value = true;
    triggeredEdge.value = event.edge.id;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), { class: "mb-5" }, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlow." + props.action + ".title")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)("connectFlow.labels.lastPublished")), 1),
            _createTextVNode(" " + _toDisplayString(_unref(formatDateAsString)(connectFlow.value?.updatedAt, "LLL")), 1)
          ]),
          (
              connectFlow.value !== undefined &&
              connectFlow.value.currentFlowId !== connectFlow.value.lastAppliedFlowId
            )
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)("connectFlow.labels.notPublished")), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_CONNECT_FLOW))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(UiButton), {
                  variant: "emerald",
                  "text-variant": "white",
                  disabled: !isAllElementsValid.value,
                  title: 
              isAllElementsValid.value ? '' : _unref(t)('connectFlow.error.hasInvalidNodes')
            ,
                  onClick: publish
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Publish")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "title"]),
                _createVNode(_unref(UiButton), {
                  variant: "primary",
                  "text-variant": "white",
                  themed: "",
                  onClick: save
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(UiButton), { to: { name: 'ListConnectFlow' } }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", {
      style: _normalizeStyle(fullScreen.value ? '' : 'height: calc(100vh - 170px);'),
      class: _normalizeClass(["bg-gray-200 shadow rounded-md", fullScreen.value ? 'fixed top-0 right-0 w-full h-full z-40' : 'h-5/6'])
    }, [
      _createVNode(_unref(VueFlow), {
        modelValue: elements.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((elements).value = $event)),
        "default-zoom": 0.5,
        "max-zoom": 1,
        "min-zoom": 0.45,
        "snap-grid": [5, 5],
        "snap-to-grid": "",
        "fit-view-on-init": ""
      }, {
        "node-input": _withCtx((node) => [
          _createVNode(_unref(StartNode), {
            nodeId: node.id
          }, null, 8, ["nodeId"])
        ]),
        "node-default": _withCtx((node) => [
          _createVNode(_unref(CustomNode), {
            nodeId: node.id
          }, null, 8, ["nodeId"])
        ]),
        "edge-custom": _withCtx((edge) => [
          (
              [_unref(EdgeType).AWAITABLE, _unref(EdgeType).NORMAL].includes(
                edge.data.component,
              )
            )
            ? (_openBlock(), _createBlock(_unref(AwaitableEdge), _normalizeProps(_mergeProps({ key: 0 }, edge)), null, 16))
            : _createCommentVNode("", true),
          (edge.data.component === _unref(EdgeType).CLIENT_INFO_EDGE)
            ? (_openBlock(), _createBlock(_unref(ClientInfoEdge), _normalizeProps(_mergeProps({ key: 1 }, edge)), null, 16))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Background)),
          _createVNode(_unref(MiniMap)),
          _createVNode(_unref(Controls), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ControlButton), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(ArrowsExpandIcon), {
                    class: "w-6",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (fullScreen.value = !fullScreen.value))
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 6),
    _createVNode(_unref(FormClienteInfoEdge), {
      modelValue: showFormClienteInfoEdge.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showFormClienteInfoEdge).value = $event)),
      edgeId: triggeredEdge.value
    }, null, 8, ["modelValue", "edgeId"]),
    _createVNode(_unref(FormAwaitableEdge), {
      modelValue: showFormAwaitableEdge.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showFormAwaitableEdge).value = $event)),
      edgeId: triggeredEdge.value
    }, null, 8, ["modelValue", "edgeId"]),
    _createVNode(_unref(ComponentSelector))
  ]))
}
}

})