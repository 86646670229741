import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_4 = { class: "flex flex-col justify-end mt-6" }
const _hoisted_5 = { class: "flex flex-col justify-end pb-1.5 mt-4" }
const _hoisted_6 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

import {
  onMounted,
  ref,
  Ref,
  computed,
  WritableComputedRef,
  PropType,
  ComputedRef,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  IUserExtensions,
} from "@/definitions";
import store from "@/store";
import { ReplyIcon } from "@heroicons/vue/outline";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiCheckboxInput,
  UiTextArea,
} from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

interface IExtensionFormState {
  extension: null | number;
  userInput: null | boolean;
  whisper: null | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToExtension',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const extensionsOptions: ComputedRef<IUserExtensions[]> = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.EXTENSION_OPTIONS],
);
const formState: Ref<IExtensionFormState> = ref({
  extension: null,
  userInput: null,
  whisper: null,
});
const textValidation = t("connectFlow.node.transferToExtension.validation");
const atLeastOneFilled = helpers.withMessage(textValidation, (value, state) => {
  return !!state.extension || !!state.userInput;
});
const rules = computed<ValidationArgs<IExtensionFormState>>(() => {
  const r: ValidationArgs<IExtensionFormState> = {
    extension: {
      atLeastOneFilled,
    },
    userInput: {
      atLeastOneFilled,
    },
    whisper: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IExtensionFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.transferToExtension.empty");
  if (nodeWritable.value.data.related) {
    text = t("connectFlow.node.transferToExtension.extension", {
      extension: nodeWritable.value.data.related?.value,
    });
  } else if (nodeWritable.value.data.userInput) {
    text = t("connectFlow.node.transferToExtension.userInput");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = formState.value.userInput
      ? null
      : ({
          value: formState.value.extension,
          label: formState.value.extension?.toString(),
        } as IMultiSelectOption);
    nodeWritable.value.data.userInput = formState.value.userInput ?? undefined;
    nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;

    showForm.value = false;
    if (nodeWritable.value.data.related?.label !== undefined) {
      formState.value.extension = parseInt(
        nodeWritable.value.data.related?.label,
      );
    }
    formState.value.userInput = nodeWritable.value.data.userInput ?? null;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related?.label !== undefined) {
    formState.value.extension = parseInt(
      nodeWritable.value.data.related?.label,
    );
  }
  if (nodeWritable.value.data.userInput) {
    formState.value.userInput = nodeWritable.value.data.userInput;
  }

  if (nodeWritable.value.data.whisper !== undefined) {
    formState.value.whisper = nodeWritable.value.data.whisper;
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(UiNode), {
      nodeId: __props.node.id,
      title: _ctx.$t('connectFlow.node.transferToExtension.title'),
      icon: _unref(ReplyIcon),
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => (showForm.value = true)),
      onRemoveNode: removeNode,
      class: _normalizeClass(isFormCorrect.value ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(nodeText.value), 1)
        ])
      ]),
      _: 1
    }, 8, ["nodeId", "title", "icon", "class"]),
    _createVNode(_unref(UiRightPanel), {
      modelValue: showForm.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showForm).value = $event)),
      title: _ctx.$t('connectFlow.node.transferToExtension.title'),
      icon: _unref(ReplyIcon)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.transferToExtension.select")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "extension",
              modelValue: _unref(v$).extension.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).extension.$model) = $event)),
              options: extensionsOptions.value,
              label: "number",
              "value-prop": "number",
              errors: _unref(v$).extension.$silentErrors,
              disabled: formState.value.userInput ?? false,
              primitive: "",
              "can-clear": ""
            }, {
              "option-label": _withCtx(({ option }) => [
                _createTextVNode(_toDisplayString(option.number) + " - " + _toDisplayString(option.user.name), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "errors", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).userInput.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).userInput.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('connectFlow.node.transferToExtension.userInput'),
              class: "h-6",
              errors: _unref(v$).userInput.$errors
            }, null, 8, ["modelValue", "label", "errors"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).userInput.$errors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "text-sm text-danger bg-red-50 px-2 py-2 w-full shadow z-10",
                key: error.$uid
              }, [
                _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(UiTextArea), {
              label: _ctx.$t('connectFlow.node.transferToExtension.whisper'),
              name: "whisper",
              modelValue: _unref(v$).whisper.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).whisper.$model) = $event))
            }, null, 8, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (showForm.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(UiButton), {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "icon"])
  ], 64))
}
}

})