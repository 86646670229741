import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_4 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_6 = {
  key: 2,
  class: "mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2"
}
const _hoisted_7 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_8 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { toastServiceError } from "@/utils/notification";
import {
  AgentProductivityPerDayReportActions,
  AgentProductivityPerDayReportGetters,
  CallType,
  FilterActions,
  FilterGetters,
  IAgentProductivityPerDayReportFilter,
  IAgentProductivityPerDayReportServiceGetInfoParams,
  IAgentProductivityPerDayReportServiceGetInfoResult,
  IBarChart,
  ICallsStats,
  ICallsStatsPerDay,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
} from "@/definitions";
import { AgentProductivityPerDayReportFilters } from "@/views/pages/reports/agentProductivityPerDay/components";
import {
  BarChart,
  CallStatusPerDayColumnChart,
  CallTypePerDayColumnChart,
  UiBadge,
  UiDatePicker,
  UiPageHeader,
} from "@/components";
import moment from "moment";
import { AgentProductivityPerDayReportService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportPage',
  setup(__props) {

const { t } = useI18n();

const reportInfo: Ref<IAgentProductivityPerDayReportServiceGetInfoResult | null> =
  ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const callsStats: ICallsStats | undefined = reportInfo.value?.stats;

  if (callsStats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "internalCalls",
      value: callsStats.type[CallType.INTERNAL].toString(),
    },
    {
      name: "uniqueNumbers",
      value: callsStats.uniqueNumbers.toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
  ];

  return result;
});

const getChart = (
  title: string,
  prop: keyof ICallsStatsPerDay,
  color = "#BFDBFE",
  yAxisLabelFormatter = (value: number | string) => String(value),
) => {
  const callsStatsPerDay = reportInfo.value?.callsStatsPerDay || [];
  const category: string[] = [];
  const serie: IDataItem<number[]> = {
    name: title,
    value: [],
    color,
  };

  for (let i = callsStatsPerDay.length - 1; i >= 0; i--) {
    const stats = callsStatsPerDay[i];
    category.push(stats.date);
    serie.value.push(stats[prop] as number);
  }

  serie.value = serie.value.reverse();
  const barChart: IBarChart = {
    title,
    category: category.reverse(),
    series: [serie],
    yAxisLabelFormatter,
  };

  return barChart;
};

const yAxisLabelFormatterAsTime = (value: number | string) => {
  value = value || 0;
  return moment.utc((value as number) * 1000).format("HH:mm:ss");
};

const xAxisLabelFormatterAsDate = (value: number | string) => {
  return moment.utc(value).format("DD/MM");
};

const charts = computed<IBarChart[]>(() => {
  return [
    getChart(
      t("report.agentProductivityPerDay.serviceTimePerDayColumnChart.title"),
      "averageServiceTime",
      "#BFDBFE",
      yAxisLabelFormatterAsTime,
    ),
    // getChart(
    //   t("report.agentProductivityPerDay.silencePerDayColumnChart.title"),
    //   "averageSilencePercentage",
    //   "#E9D5FF",
    //   (value: number | string) => {
    //     return value + "%";
    //   }
    // ),
    getChart(
      t("report.agentProductivityPerDay.timeAvailablePerDayColumnChart.title"),
      "averageTimeAvailable",
      "#FBCFE8",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.durationPerDayColumnChart.title"),
      "averageDuration",
      "#A5F3FC",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.totalIdleTimePerDayColumnChart.title"),
      "averageTotalIdleTime",
      "#BAE6FD",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.idleTimePerDayColumnChart.title"),
      "averageIdleTime",
      "#F5D0FE",
      yAxisLabelFormatterAsTime,
    ),
  ];
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IAgentProductivityPerDayReportFilter>(
  () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IAgentProductivityPerDayReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    user: filter.value.user?.id || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  AgentProductivityPerDayReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(
    AgentProductivityPerDayReportActions.CLEAR_FILTER,
    filter.field,
  );
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.agentProductivityPerDay.title")), 1)
      ]),
      actions: _withCtx(() => [
        _createVNode(_unref(AgentProductivityPerDayReportFilters)),
        _createVNode(_unref(UiDatePicker), {
          modelValue: dateRange.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (activeFilters.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
            return (_openBlock(), _createBlock(_unref(UiBadge), {
              key: filter,
              removable: "",
              onRemove: ($event: any) => (handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(
              `report.agentProductivityPerDay.filters.activeTag.${filter.field}`,
            )) + ": " + _toDisplayString(filter.label), 1)
              ]),
              _: 2
            }, 1032, ["onRemove"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (reportInfo.value?.stats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-5 grid grid-cols-1 gap-5", ['sm:grid-cols-' + headerStats.value.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerStats.value, (stat) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stat.name,
              class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            }, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t("report.contactCenterPerAgent.stats." + stat.name)), 1),
              _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1)
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (reportInfo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(CallTypePerDayColumnChart), {
              title: 
            _unref(t)('report.agentProductivityPerDay.callTypePerDayColumnChart.title')
          ,
              data: reportInfo.value.callsStatsPerDay
            }, null, 8, ["title", "data"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_unref(CallStatusPerDayColumnChart), {
              title: 
            _unref(t)(
              'report.agentProductivityPerDay.callStatusPerDayColumnChart.title',
            )
          ,
              data: reportInfo.value.callsStatsPerDay
            }, null, 8, ["title", "data"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(charts.value, (chart, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1"
            }, [
              _createVNode(_unref(BarChart), {
                title: chart.title,
                categoryData: chart.category,
                seriesData: chart.series,
                xAxisLabelFormatter: xAxisLabelFormatterAsDate,
                yAxisLabelFormatter: chart.yAxisLabelFormatter,
                column: ""
              }, null, 8, ["title", "categoryData", "seriesData", "yAxisLabelFormatter"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})