import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 w-full" }
const _hoisted_2 = { class: "bg-white flex flex-col items-center sm:items-start sm:justify-center pt-20 sm:pt-0 w-full px-8 lg:px-28 xl:px-40" }
const _hoisted_3 = { class: "mb-8 text-2xl font-semibold text-gray-700" }
const _hoisted_4 = { class: "text-sm mb-4" }
const _hoisted_5 = { class: "list-decimal pl-6 text-sm mb-10" }
const _hoisted_6 = { class: "block" }

import { computed, onMounted, reactive, Ref } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import router from "@/router";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  containsUppercase,
  containsLowercase,
  containsNumber,
  containsSpecial,
  sameAs,
} from "@/utils/validators";
import { toastServiceError } from "@/utils/notification";
import { UiTextInput, UiButton } from "@/components";
import {
  UiActions,
  IUpdatePasswordFormState,
  IUser,
  AuthGetters,
} from "@/definitions";
import { useI18n } from "vue-i18n";
import { UserService } from "@/services";

const imageLoginPath = "/img/slogan-login.png";
const logoPath = "/img/logomob.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePasswordPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();

const formState = reactive<IUpdatePasswordFormState>({
  password: "",
  confirmPassword: "",
});

const token = route.query.token as string | undefined;

const rules = computed<ValidationArgs<IUpdatePasswordFormState>>(() => ({
  password: {
    required,
    minLength: minLength(8),
    maxLength: maxLength(250),
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
  },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(formState.password, t("user.labels.password")),
  },
}));

const v$: Ref<Validation<ValidationArgs<IUpdatePasswordFormState>>> =
  useVuelidate(rules, formState);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  if (token) {
    UserService.resetPassword({ token, password: formState.password })
      .then(() => {
        router.push("/");
      })
      .catch((error) => {
        toastServiceError(error);
        router.push({ name: "Login" });
      })
      .finally(() => setLoading(false));
  } else {
    UserService.firstLogin(formState)
      .then(() => {
        router.push("/");
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
};

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);
onMounted(() => {
  if (user?.value?.lastLogin) {
    router.push({ name: "Home" });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "bg-primary hidden sm:flex items-center justify-center w-full relative" }, [
      _createElementVNode("img", {
        src: imageLoginPath,
        class: "h-[150px] xl:h-[250px]",
        alt: "logo Mobcall"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "mb-8 w-[225px]",
        src: logoPath,
        alt: "logo Mobcall"
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("authentication.updatePassword.title")), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("authentication.updatePassword.description")), 1),
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.size")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.upperCase")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.lowerCase")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.number")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.special")), 1)
      ]),
      _createElementVNode("form", {
        class: "space-y-6 w-full",
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        _createVNode(_unref(UiTextInput), {
          modelValue: formState.password,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.password) = $event)),
          name: "password",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.password'),
          class: "",
          inputClass: "text-base px-4 py-3",
          autocomplete: "password",
          errors: _unref(v$).password?.$errors,
          onBlur: _unref(v$).password?.$touch
        }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
        _createVNode(_unref(UiTextInput), {
          modelValue: formState.confirmPassword,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formState.confirmPassword) = $event)),
          name: "confirmPassword",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.confirmPassword'),
          class: "",
          inputClass: "text-base px-4 py-3",
          autocomplete: "off",
          errors: _unref(v$).confirmPassword?.$errors,
          onBlur: _unref(v$).confirmPassword?.$touch
        }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
        _createElementVNode("div", null, [
          _createVNode(_unref(UiButton), {
            type: "submit",
            themed: "",
            variant: "primary",
            class: "w-full text-lg shadow",
            size: "xl"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("login.form.signIn")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ])
  ]))
}
}

})