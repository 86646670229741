import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "pl-1 flex flex-col h-full"
}
const _hoisted_2 = {
  key: 0,
  class: "last:mt-auto"
}
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = {
  key: 1,
  class: "last:mt-auto"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["hidden"]

import { onMounted, Ref, ref, computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { can } from "@/utils/auth";
import { INavigationItem } from "@/definitions";
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { ILevel, FilterGetters } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateNavigation',
  props: {
  items: {
    type: Array<INavigationItem>,
    default: [],
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const filteredNavigationItems: Ref<INavigationItem[]> = ref([]);
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const isActive = (item: INavigationItem) => {
  if (item.name == "Home") {
    return route.name === item.name;
  } else {
    return route.matched.some(
      ({ name }) => name === item.name || item.activateBy?.indexOf(name) >= 0,
    );
  }
};

const hasActiveChildren = (item: INavigationItem) => {
  return (
    (item.children || []).filter((i: INavigationItem) => isActive(i)).length > 0
  );
};

function toggleVisibility(item: INavigationItem) {
  item.isOpen = !item.isOpen;
}

onMounted(() => {
  filteredNavigationItems.value = props.items
    .map((item) => {
      return !item.children?.length
        ? item
        : {
            ...item,
            children: item.children.filter(
              (child) => !child.permission || can(child.permission),
            ),
          };
    })
    .filter((item, index) => {
      if (
        item.localeKey === "sideNavigation.meet.group" &&
        !level.value.meeting
      ) {
        return false;
      }
      return props.items[index].children?.length === undefined
        ? !item.permission || can(item.permission)
        : item.children?.length;
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredNavigationItems.value, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.name
      }, [
        (!item.children && item.name)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_unref(RouterLink), {
                to: {
            name: item.name,
            query: item.query,
          },
                class: _normalizeClass([
            isActive(item)
              ? 'bg-indigo-900/70 text-white'
              : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
            'group w-full flex items-center py-2 font-medium rounded-md',
          ])
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    class: _normalizeClass([
              isActive(item)
                ? 'text-secondary'
                : 'text-gray-400 group-hover:text-secondary',
              'flex-shrink-0 h-6 w-6 ml-2',
            ]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(item.localeKey)), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: "group w-full flex items-center pl-2 pr-1 py-2 text-left font-medium rounded-md text-gray-400 hover:bg-indigo-900/70 hover:text-white",
                  onClick: ($event: any) => (toggleVisibility(item))
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    class: _normalizeClass([
                hasActiveChildren(item) || item.isOpen ? 'text-secondary' : '',
                'mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-[#6dbcab]',
              ]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])),
                  _createElementVNode("span", {
                    class: _normalizeClass([
                hasActiveChildren(item) || item.isOpen ? 'text-white' : '',
                'flex-1',
              ])
                  }, _toDisplayString(_ctx.$t(item.localeKey)), 3),
                  (_openBlock(), _createElementBlock("svg", {
                    class: _normalizeClass([
                hasActiveChildren(item) || item.isOpen
                  ? 'text-gray-400 rotate-90'
                  : 'text-gray-300',
                'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
              ]),
                    viewBox: "0 0 20 20",
                    "aria-hidden": "true"
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("path", {
                      d: "M6 6L14 10L6 14V6Z",
                      fill: "currentColor"
                    }, null, -1)
                  ]), 2))
                ], 8, _hoisted_5),
                _createElementVNode("ul", {
                  class: "mt-1",
                  id: "sub-menu-1",
                  hidden: !(hasActiveChildren(item) || item.isOpen)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createVNode(_unref(RouterLink), {
                        to: {
                  name: subItem.name,
                  query: subItem.query,
                },
                        href: "#",
                        class: _normalizeClass([
                  isActive(subItem)
                    ? 'bg-indigo-900/70 text-white'
                    : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
                  'group w-full flex items-center pl-2 py-2 font-medium rounded-md',
                ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(ChevronRightIcon), {
                            class: "w-4 h-4 mr-1",
                            "aria-hidden": "true"
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t(subItem.localeKey)), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"])
                    ]))
                  }), 128))
                ], 8, _hoisted_6)
              ])
            ]))
      ], 64))
    }), 128))
  ]))
}
}

})