import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-wrap sm:flex-row gap-2 pb-3" }

import { ICallInsight } from "@/definitions";
import UiBadge from "@/components/UiBadge.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InsightBlock',
  props: {
  persona: {
    type: String,
    required: true,
  },
  insights: {
    type: Array as () => Array<ICallInsight>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.insights, (insight) => {
      return (_openBlock(), _createBlock(UiBadge, {
        key: `${__props.persona}-insight-${insight.name}`,
        class: "rounded-md cursor-pointer",
        variant: insight.selected ? 'orange' : 'yellow',
        border: insight.selected,
        onClick: ($event: any) => (insight.selected = !insight.selected)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(insight.value), 1)
        ]),
        _: 2
      }, 1032, ["variant", "border", "onClick"]))
    }), 128))
  ]))
}
}

})