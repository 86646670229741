import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = { class: "flex-1" }

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  ChevronDownIcon,
  SwitchVerticalIcon,
} from "@heroicons/vue/solid";
import { ISortMenuItem, ISortMenuItemDirection } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenu',
  props: {
  modelValue: {
    type: Array<ISortMenuItem>,
    default: [],
  },
  label: {
    type: String,
    required: true,
    default: "",
  },
  items: {
    type: Array<ISortMenuItem>,
    required: true,
    default: [],
  },
  fixmobile: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleItemClick = (
  item: ISortMenuItem,
  direction?: ISortMenuItemDirection,
) => {
  const currentItems = Object.assign([] as ISortMenuItem[], props.modelValue);

  const currentItem = currentItems.find((cItem) => cItem.key == item.key);

  if (currentItem) {
    if (currentItem.direction == direction) {
      currentItems.splice(currentItems.indexOf(currentItem), 1);
    } else if (direction) {
      currentItem.direction = direction;
    } else
      currentItem.direction =
        currentItem.direction == ISortMenuItemDirection.DESC
          ? ISortMenuItemDirection.ASC
          : ISortMenuItemDirection.DESC;
  } else {
    currentItems.push(
      Object.assign(
        { direction: direction || ISortMenuItemDirection.ASC } as ISortMenuItem,
        item,
      ),
    );
  }

  emit("update:modelValue", currentItems);
};

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_unref(Menu), {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(MenuButton), {
        class: _normalizeClass(["inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary", [props.fixmobile ? 'px-3 sm:px-4' : 'px-4']])
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(SwitchVerticalIcon), {
            class: _normalizeClass(["mr-3 h-5 w-5 text-gray-400", [props.fixmobile ? 'mr-0 sm:mr-3' : '']]),
            "aria-hidden": "true"
          }, null, 8, ["class"]),
          _createElementVNode("span", {
            class: _normalizeClass([props.fixmobile ? 'hidden sm:block' : ''])
          }, _toDisplayString(props.label), 3),
          _createVNode(_unref(ChevronDownIcon), {
            class: _normalizeClass(["ml-2.5 -mr-1.5 h-5 w-5 text-gray-400", [props.fixmobile ? 'hidden sm:block' : '']]),
            "aria-hidden": "true"
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_unref(MenuItems), { class: "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
              return (_openBlock(), _createBlock(_unref(MenuItem), { key: index }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'group flex items-center px-4 py-2 text-sm',
            ]),
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1),
                    _withDirectives(_createVNode(_unref(ArrowCircleDownIcon), {
                      class: _normalizeClass([[
                props.modelValue.find(
                  (m) =>
                    m.key == item.key &&
                    m.direction == _unref(ISortMenuItemDirection).ASC,
                )
                  ? 'text-primary hover:text-gray-300'
                  : 'text-gray-400 hover:text-primary',
              ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => (handleItemClick(item, _unref(ISortMenuItemDirection).ASC))
                    }, null, 8, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.ascending')]
                    ]),
                    _withDirectives(_createVNode(_unref(ArrowCircleUpIcon), {
                      class: _normalizeClass([[
                props.modelValue.find(
                  (m) =>
                    m.key == item.key &&
                    m.direction == _unref(ISortMenuItemDirection).DESC,
                )
                  ? 'text-primary hover:text-gray-300'
                  : 'text-gray-400 hover:text-primary',
              ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => (handleItemClick(item, _unref(ISortMenuItemDirection).DESC))
                    }, null, 8, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.descending')]
                    ])
                  ], 2)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})