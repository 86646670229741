import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = {
  for: "users",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_8 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { UserLabelService, LevelService, UserService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import UiPanel from "@/components/UiPanel.vue";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiMultiselect,
} from "@/components";
import {
  Permission,
  UiActions,
  FormAction,
  ILevel,
  IDefaultServiceResult,
  IUserLabelCreateResult,
  IUserLabelFormState,
  IUser,
  IUserLabel,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<IUserLabelFormState>({
  id: 0,
  levelId: 0,
  label: "",
  users: [],
  isActive: true,
});

const rules = computed<ValidationArgs<IUserLabelFormState>>(() => ({
  id: {
    required,
  },
  levelId: {
    required,
  },
  label: {
    required,
    maxLength: maxLength(50),
  },
  users: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IUserLabelFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getUserLabel = (id: number) => {
  setLoading(true);
  UserLabelService.get<IUserLabel>(id)
    .then((response: AxiosResponse<IUserLabel>) => {
      const { id, label, users, levelId, isActive } = response.data;
      formState.id = id;
      formState.label = label;
      formState.users = users.map((u) => u.id);
      formState.levelId = levelId;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getUserLabel(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewUserLabelById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    UserLabelService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    UserLabelService.create<IUserLabelCreateResult>(formState)
      .then(
        (response: AxiosResponse<IUserLabelCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const usersRaw: Ref<IUser[]> = ref([]);
const getUsers = () => {
  setLoading(true);
  UserService.getAll<IUser[]>({
    params: {
      page: 1,
      per_page: 100,
      level: formState.levelId ? formState.levelId : null,
    },
  }).then((response) => {
    setLoading(false);
    usersRaw.value = response.data;
  });
};
getUsers();

watch(
  () => formState.levelId,
  () => {
    getUsers();
  },
);

const users = computed(() => {
  return usersRaw.value.map((user) => {
    return {
      id: user.id,
      name: user.name,
    };
  });
});

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewUserLabelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListUserLabel",
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("userLabel." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(UiPanel, null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).label.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).label.$model) = $event)),
              name: "label",
              type: "text",
              label: _ctx.$t('userLabel.labels.label'),
              errors: _unref(v$).label.$errors,
              onBlur: _unref(v$).label.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("userLabel.labels.users")), 1),
              _createVNode(_unref(UiMultiselect), {
                id: "users",
                name: "users",
                label: "name",
                "value-prop": "id",
                modelValue: formState.users,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.users) = $event)),
                multiple: "",
                primitive: true,
                options: users.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: _unref(v$).isActive.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('userLabel.labels.isActive'),
                class: "h-6"
              }, null, 8, ["modelValue", "label"])
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          size: "xl",
          "text-variant": "gray-500",
          onClick: handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_USER_LABEL)) ||
            (props.action == _unref(FormAction).EDIT &&
              _ctx.$can(_unref(Permission).EDIT_USER_LABEL))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              size: "xl",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})