import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_4 = { class: "flex flex-col justify-end pb-1.5 mt-4" }
const _hoisted_5 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

import {
  onMounted,
  ref,
  Ref,
  computed,
  WritableComputedRef,
  PropType,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  IQueue,
} from "@/definitions";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiTextArea,
} from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { ReplyIcon } from "@heroicons/vue/outline";
import { required } from "@/utils/validators";
import { useI18n } from "vue-i18n";
import store from "@/store";

interface ITransferFormState {
  queue: null | IQueue;
  whisper: null | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToQueue',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const queueOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.QUEUE_OPTIONS],
);
const formState: Ref<ITransferFormState> = ref({
  queue: null,
  whisper: null,
});

const rules = computed<ValidationArgs<ITransferFormState>>(() => {
  const r: ValidationArgs<ITransferFormState> = {
    queue: {
      required,
    },
    whisper: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<ITransferFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.transferToQueue.empty");
  if (nodeWritable.value.data.related) {
    text = nodeWritable.value.data.related.label;
  } else if (nodeWritable.value.data.textToAudio) {
    text = t("connectFlow.node.transferToQueue.textToAudio");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = {
      value: formState.value.queue?.id,
      label: formState.value.queue?.name,
    } as IMultiSelectOption;
    nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;
    showForm.value = false;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related !== null) {
    formState.value.queue = queueOptions.value.find(
      (queue: IQueue) => queue.id == nodeWritable.value.data.related?.value,
    );
  }

  if (nodeWritable.value.data.whisper !== undefined) {
    formState.value.whisper = nodeWritable.value.data.whisper;
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(UiNode), {
      nodeId: __props.node.id,
      title: _ctx.$t('connectFlow.node.transferToQueue.title'),
      icon: _unref(ReplyIcon),
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => (showForm.value = true)),
      onRemoveNode: removeNode,
      class: _normalizeClass(isFormCorrect.value ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(nodeText.value), 1)
        ])
      ]),
      _: 1
    }, 8, ["nodeId", "title", "icon", "class"]),
    _createVNode(_unref(UiRightPanel), {
      modelValue: showForm.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showForm).value = $event)),
      title: _ctx.$t('connectFlow.node.transferToQueue.title'),
      icon: _unref(ReplyIcon)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.transferToQueue.select")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "queue",
              label: "name",
              "value-prop": "id",
              modelValue: _unref(v$).queue.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).queue.$model) = $event)),
              options: queueOptions.value,
              errors: _unref(v$).queue.$silentErrors,
              "can-clear": ""
            }, null, 8, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiTextArea), {
              label: _ctx.$t('connectFlow.node.transferToQueue.whisper'),
              name: "whisper",
              modelValue: _unref(v$).whisper.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).whisper.$model) = $event))
            }, null, 8, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (showForm.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(UiButton), {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "icon"])
  ], 64))
}
}

})