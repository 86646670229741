import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pt-4 px-4" }
const _hoisted_2 = { class: "flex h-9 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_3 = { class: "relative py-8 px-4" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = { class: "flex flex-col grow-0 w-auto items-center pr-3" }
const _hoisted_6 = { class: "grow flex items-end" }
const _hoisted_7 = { class: "grow" }
const _hoisted_8 = { class: "w-full text-center" }
const _hoisted_9 = { class: "flex-1 relative" }
const _hoisted_10 = { class: "top-0 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_11 = { class: "top-3 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_12 = { class: "top-7 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_13 = { class: "bottom-0 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_14 = { class: "bottom-3 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_15 = { class: "bottom-6 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  id: "progress-bar",
  class: "top-0 left-0 absolute h-full w-full flex items-center"
}
const _hoisted_18 = {
  id: "progress-time",
  class: "absolute text-gray-500 p-0.5 -bottom-9 right-0 text-sm cursor-default"
}
const _hoisted_19 = { class: "flex flex-shrink flex-col sm:w-auto" }
const _hoisted_20 = { class: "flex grow items-center" }
const _hoisted_21 = { class: "transform rotate-90 text-gray-500 w-full text-center" }
const _hoisted_22 = { class: "flex grow items-center" }
const _hoisted_23 = { class: "transform rotate-90 text-gray-500 w-full text-center" }
const _hoisted_24 = { class: "text-lg text-gray-700 text-center" }
const _hoisted_25 = { class: "text-lg text-center text-red-900" }
const _hoisted_26 = {
  key: 0,
  class: "flex w-full gap-4 mt-4 pl-14"
}
const _hoisted_27 = { class: "flex items-center text-xs text-blue-500" }
const _hoisted_28 = { class: "flex items-center text-xs text-yellow-500" }
const _hoisted_29 = { class: "flex items-center text-xs text-orange-500" }

import {
  ref,
  Ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  WritableComputedRef,
  ComputedRef,
} from "vue";
import {
  AudioPlayerActions,
  AudioPlayerMutations,
  AudioPlayerGetters,
  ICall,
  ICallTimelineItem,
  ICallInsight,
  IWordsByPersona,
  ICallWord,
  ParticipantType,
  CallPlayerGetters,
  CallPlayerMutations,
} from "@/definitions";
import { formatSecToTime } from "@/utils/datetimeCommon";
import store from "@/store";
import {
  PlayIcon,
  PauseIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/solid";
import CallPlayerBullets from "@/views/pages/call/components/CallPlayerBullets.vue";
import PlaySearchWord from "@/views/pages/call/components/PlaySearchWord.vue";
import { UiPanel, UiSpinner, UiButton } from "@/components";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";
import { map, lowerCase } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayer',
  setup(__props) {

const audioElement: Ref<HTMLElement | null> = ref(null);

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

const searchWord: Ref<string> = ref("");

const timeline: WritableComputedRef<ICallTimelineItem[]> = computed({
  get: () => store.getters[CallPlayerGetters.TIMELINE],
  set: (value: ICallTimelineItem[]) => {
    store.commit(CallPlayerMutations.SET_TIMELINE, value);
  },
});

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, {
      value,
    });
  },
});

const getAudioFile = async () => {
  if (call.value.audioFilePath !== undefined) {
    const { data } = await VaultService.getCallAudio(call.value.id);
    const blob = new Blob([data], {
      type: getAudioFormat(call.value.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const audioData = ref();

onMounted(() => {
  getAudioFile().then(() => {
    store.dispatch(AudioPlayerActions.INIT_INSTANCE, {
      element: audioElement.value,
      fileUrl: audioData.value,
    });
  });
});

onUnmounted(() => {
  if (isPlaying.value) {
    playPause();
  }
  store.commit(AudioPlayerMutations.UNSET_INSTANCE);
});

const waveInstance = computed(() => store.getters[AudioPlayerGetters.INSTANCE]);
const playerReady = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_READY],
);
const playerError = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_ERROR],
);
const isPlaying = computed(() =>
  playerReady.value ? waveInstance.value?.isPlaying() : false,
);
const secondsDuration = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_DURATION],
);
const secondsCurrent = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_CURRENT],
);
const loadingPercentage = computed(
  () => store.getters[AudioPlayerGetters.LOADING_PERCENTAGE],
);

const playedPercentage = computed(
  () => ((secondsCurrent.value * 100) / secondsDuration.value).toFixed(2) + "%",
);

function playPause() {
  store.dispatch(AudioPlayerActions.PLAY);
}

function changeSpeed() {
  let rate = waveInstance.value?.getPlaybackRate() ?? 1;
  if (rate === 1) {
    rate = 1.1;
  } else if (rate === 1.1) {
    rate = 1.2;
  } else if (rate === 1.2) {
    rate = 1;
  }
  waveInstance.value?.setPlaybackRate(rate);
}
const getPlaybackRate = computed(() => waveInstance.value?.getPlaybackRate());

const searchInsights: Ref<ICallInsight[]> = ref([]);
function filterInsights(item: ICallTimelineItem, participantType: string) {
  const isAgentInsight =
    item.participant === participantType && item.insights.length > 0;

  const insightsAgent = searchInsights.value.filter(
    (insight) => insight.participant === participantType,
  );

  if (insightsAgent.length) {
    return (
      insightsAgent.find((insight) => {
        return (
          item.insights.findIndex(
            (timeLineInsight) => timeLineInsight.text === insight.text,
          ) > -1
        );
      }) !== undefined && isAgentInsight
    );
  }
  return isAgentInsight;
}

const agentWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Agent &&
      item.registeredWords !== undefined &&
      item.registeredWords.length > 0,
  ),
);
const agentInsightsBullets = computed(() =>
  timeline.value?.filter((item) => filterInsights(item, ParticipantType.Agent)),
);
const agentSearchWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Agent &&
      item.matchedWords !== undefined &&
      item.matchedWords.length,
  ),
);

const clientWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Client &&
      item.registeredWords !== undefined &&
      item.registeredWords.length,
  ),
);
const clientInsightsBullets = computed(() =>
  timeline.value?.filter((item) => {
    if (item.insights !== undefined) {
      return filterInsights(item, ParticipantType.Client);
    }
  }),
);
const clientSearchWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Client &&
      item.matchedWords !== undefined &&
      item.matchedWords.length,
  ),
);

watch(
  [words, searchWord],
  () => {
    const selectedWordsClient: ICallWord[] = words.value.client.filter(
      (item) => item.selected,
    );
    const selectedWordsAgent: ICallWord[] = words.value.agent.filter(
      (item) => item.selected,
    );

    if (searchWord.value !== "") {
      selectedWordsAgent.push({
        text: searchWord.value,
        counter: 0,
      });
      selectedWordsClient.push({
        text: searchWord.value,
        counter: 0,
      });
    }

    timeline.value?.forEach((item) => {
      item.matchedWords = [];
    });

    selectedWordsClient.forEach((word) => {
      timeline.value?.forEach((item) => {
        if (item.participant === ParticipantType.Client) {
          const itemWords = map(item.words, lowerCase);
          if (itemWords.includes(word.text.toLowerCase())) {
            item.matchedWords.push(word);
          }
        }
      });
    });

    selectedWordsAgent.forEach((word) => {
      timeline.value?.forEach((item) => {
        if (item.participant === ParticipantType.Agent) {
          const itemWords = map(item.words, lowerCase);
          if (itemWords.includes(word.text.toLowerCase())) {
            item.matchedWords.push(word);
          }
        }
      });
    });
  },
  { deep: true },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiPanel), {
    class: "overflow-visible",
    "no-padding": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PlaySearchWord, {
            modelValue: searchWord.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchWord).value = $event)),
            placeholder: "Pesquisar palavra"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_unref(UiButton), {
                class: "shadow w-10 h-10 mb-2",
                size: "",
                variant: "primary",
                "text-variant": "white",
                shape: "rounded-full",
                themed: "",
                onClick: playPause
              }, {
                default: _withCtx(() => [
                  (!isPlaying.value)
                    ? (_openBlock(), _createBlock(_unref(PlayIcon), {
                        key: 0,
                        class: "mx-auto text-white font-semibold text-lg",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("", true),
                  (isPlaying.value)
                    ? (_openBlock(), _createBlock(_unref(PauseIcon), {
                        key: 1,
                        class: "mx-auto text-white font-semibold text-lg",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(UiButton), {
                class: "shadow w-7 h-7 text-center",
                size: "xs",
                variant: "primary",
                "text-variant": "white",
                shape: "rounded-full",
                themed: "",
                onClick: changeSpeed
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(getPlaybackRate.value) + "x", 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(CallPlayerBullets, {
                timeline: agentWordsBullets.value,
                type: "words"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(CallPlayerBullets, {
                timeline: agentInsightsBullets.value,
                type: "insights"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(CallPlayerBullets, {
                timeline: agentSearchWordsBullets.value,
                type: "search"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(CallPlayerBullets, {
                timeline: clientWordsBullets.value,
                type: "words"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(CallPlayerBullets, {
                timeline: clientInsightsBullets.value,
                type: "insights"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(CallPlayerBullets, {
                timeline: clientSearchWordsBullets.value,
                type: "search"
              }, null, 8, ["timeline"])
            ]),
            _createElementVNode("div", {
              class: "cursor-pointer group",
              ref_key: "audioElement",
              ref: audioElement
            }, [
              (playerReady.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", {
                        id: "full-line",
                        class: "w-full bg-gray-300 block h-1"
                      }, null, -1)),
                      _createElementVNode("div", {
                        class: "theme-bg-color absolute h-0.5",
                        style: _normalizeStyle({ width: playedPercentage.value })
                      }, _cache[1] || (_cache[1] = [
                        _createElementVNode("span", { class: "absolute player-progress-bar-pointer bg-blue-300 rounded-full h-3 w-3 block -top-1 -right-1.5 z-20 transition duration-150 opacity-0 group-hover:opacity-100" }, null, -1)
                      ]), 4),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(formatSecToTime)(secondsCurrent.value)) + " / " + _toDisplayString(_unref(formatSecToTime)(secondsDuration.value)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("call.view.participant.agent")), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("call.view.participant.client")), 1)
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute w-1/3 top-1/4 left-1/3", 
            !playerReady.value && playerError.value === null ? 'visible' : 'invisible'
          ])
        }, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("p", null, _toDisplayString(loadingPercentage.value) + "%", 1),
            _createElementVNode("p", null, [
              _createVNode(_unref(UiSpinner), { class: "h-6 w-6 mr-3 inline" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("call.audioPlayer.loadingFile")), 1)
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute w-1/3 top-1/4 left-1/3", playerError.value !== null ? 'visible' : 'invisible'])
        }, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("p", null, [
              _createVNode(_unref(ExclamationCircleIcon), { class: "h-6 w-6 mr-3 inline" }),
              _createTextVNode(" " + _toDisplayString(playerError.value), 1)
            ])
          ])
        ], 2),
        (timeline.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-blue-500" }, null, -1)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.word")), 1)
              ]),
              _createElementVNode("div", _hoisted_28, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-yellow-500" }, null, -1)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.insight")), 1)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-orange-500" }, null, -1)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.wordFilter")), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})