import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hover:bg-gray-50" }
const _hoisted_2 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-900" }
const _hoisted_3 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_4 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_5 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_6 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_7 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_8 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_9 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_10 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_11 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }

import { computed, PropType } from "vue";
import { IIntegrationLog } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogListItem',
  props: {
  modelValue: {
    type: Object as PropType<IIntegrationLog>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const callSource = computed<string>(() => {
  return (
    props.modelValue.call.events
      .filter((ce) => ce.sourceUser?.name)
      .map((ce) => ce.sourceUser?.name)
      .filter((ce, pos, self) => self.indexOf(ce) == pos)
      .join(", ") || props.modelValue.call.sourceNumber
  );
});

const callDestination = computed<string>(() => {
  return (
    props.modelValue.call.events
      .filter((ce) => ce.destinationUser?.name)
      .map((ce) => ce.destinationUser?.name)
      .filter((ce, pos, self) => self.indexOf(ce) == pos)
      .join(", ") || props.modelValue.call.destinationNumber
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(__props.modelValue.call.id), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_unref(formatDateAsString)(
          __props.modelValue.call.startedAt?.toString(),
          _ctx.$t("core.dateTimeFormat"),
        )), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.$t("core.options.call.type." + __props.modelValue.call.type.toLowerCase())), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t("core.options.call.status." + __props.modelValue.call.status.toLowerCase())), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(callSource.value), 1),
    _createElementVNode("td", _hoisted_7, _toDisplayString(callDestination.value), 1),
    _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(formatDateAsString)(
          __props.modelValue.createdAt.toString(),
          _ctx.$t("core.dateTimeFormat"),
        )), 1),
    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t(`integration.logs.filters.status.options.${__props.modelValue.status}`)), 1),
    _createElementVNode("td", _hoisted_10, _toDisplayString(__props.modelValue.externalId), 1),
    _createElementVNode("td", _hoisted_11, _toDisplayString(__props.modelValue.observation), 1)
  ]))
}
}

})