import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }

import { PropType, computed } from "vue";
import { EChartsOption } from "echarts";
import { useI18n } from "vue-i18n";
import { UiChart, UiSpinner } from "@/components";
import { CallbackDataParams } from "echarts/types/dist/shared";
import { UserDetailsStatus } from "@/definitions/chart-typings/Realtime";
import { IDataTotalize } from "@/definitions";

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersStatusDoughnutChart',
  props: {
  data: {
    type: Object as PropType<IDataTotalize>,
    required: true,
  },
  loading: {
    type: Boolean,
    require: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const CallStatusColor = {
  [UserDetailsStatus.CONNECTED]: "#B9F6D1",
  [UserDetailsStatus.OFFLINE]: "#EDC7CD",
};

const props = __props;

const callStatusOptions = computed<EChartsOption>(() => {
  const data = [
    {
      value: props.data?.userOnline,
      name: t("report.realtime.user.status.connected"),
      itemStyle: {
        color: CallStatusColor[UserDetailsStatus.CONNECTED],
      },
    },
    {
      value: props.data?.userOffline,
      name: t("report.realtime.user.status.offline"),
      itemStyle: {
        color: CallStatusColor[UserDetailsStatus.OFFLINE],
      },
    },
  ];

  const title = t("report.realtime.usersStatusDoughnutChart.title");

  const eChartsOption: EChartsOption = {
    title: {
      text: title,
      left: "10px",
      top: "10px",
      textStyle: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#555",
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      type: "scroll",
      width: "90%",
      height: "100%",
      bottom: "10px",
      left: "center",
      itemHeight: 10,
      itemWidth: 10,
      icon: "circle",
      textStyle: {
        fontSize: "11px",
        lineHeight: 14,
        rich: {
          a: {
            align: "center", // Alinha o texto da legenda horizontalmente ao meio
            verticalAlign: "bottom", // Alinha o texto da legenda verticalmente ao meio
          },
          b: {
            align: "center", // Alinha o valor numérico horizontalmente ao meio
            verticalAlign: "bottom", // Alinha o valor numérico verticalmente ao meio
          },
        },
      },
      selectedMode: true,
      formatter: (name: string) => {
        const value = data.find((d) => d.name == name)?.value || 0;
        return "{a|" + name + "} {b|(" + value + ")}";
      },
    },
    series: [
      {
        type: "pie",
        radius: ["20%", "55%"],
        data,
        label: {
          show: true,
          // textStyle: {
          //   fontSize: "11px",
          // },
          formatter(param: CallbackDataParams) {
            return param.name + " (" + (param.percent || 0) + "%)";
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return eChartsOption;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiChart), { option: callStatusOptions.value }, null, 8, ["option"]),
    (props.loading)
      ? (_openBlock(), _createBlock(_unref(UiSpinner), {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})