import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 mb-5" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row justify-end" }
const _hoisted_4 = { class: "w-full lg:w-64 md:mt-3 lg:mt-0" }
const _hoisted_5 = { class: "w-full mt-5" }
const _hoisted_6 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_7 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_8 = {
  class: "flex flex-1",
  action: "#",
  method: "GET"
}
const _hoisted_9 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_10 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_11 = {
  role: "list",
  class: ""
}
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

import { ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import {
  UiPageHeader,
  UiListSearch,
  UiPagination,
  UiDatePicker,
  UiSortMenuBadge,
  UiBadge,
} from "@/components";
import MeetingService from "@/services/MeetingService";
import {
  IFilterItem,
  ILevel,
  IMeeting,
  ISortMenuItem,
  MeetingActions,
  MeetingGetters,
  FilterGetters,
  FilterActions,
  UiActions,
} from "@/definitions";
import {
  MeetingListItem,
  MeetingListSort,
  MeetingListFilters,
} from "@/views/pages/meeting/components";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const count = ref(0);
const currentPage = computed<number>({
  get: () => store.getters[MeetingGetters.PAGE],
  set: (page: number) => store.dispatch(MeetingActions.SET_PAGE, page),
});

const search = computed<string>({
  get: () => store.getters[MeetingGetters.SEARCH],
  set: (search: string) => store.dispatch(MeetingActions.SET_SEARCH, search),
});
const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[MeetingGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(MeetingActions.SET_SORT, sort),
});

const orderBy = computed<string>(() => {
  return sortMenuSelected.value
    ? sortMenuSelected.value.map((s) => s.direction + s.key).join(",")
    : "";
});
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const platform = computed<string | null>(() => {
  const platformFilter = store.getters[MeetingGetters.FILTER].platform;

  if (platformFilter && platformFilter.value) {
    return platformFilter.value.toString();
  } else {
    return null;
  }
});

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[MeetingGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (route.query.clearStateOnSetup) {
  currentPage.value = 1;
  router.replace({ query: {} });
}

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};
function handleRemoveFilter(field: string) {
  store.dispatch(MeetingActions.CLEAR_FILTER, field);
}

const meetings = ref<IMeeting[]>([]);
const prepareMeetingParams = () => {
  if (!level.value || dateRange.value.length < 2) return null;

  return {
    search: search.value,
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    platform: platform?.value,
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
  };
};

const getMeetings = () => {
  const params = prepareMeetingParams();
  if (!params) return;

  params.page = currentPage.value;
  params.per_page = perPage;

  setLoading(true);

  MeetingService.getAll({ params })
    .then((response) => {
      meetings.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

getMeetings();

watch(
  [search, level, dateRange, orderBy, currentPage, activeFilters],
  () => {
    getMeetings();
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.meetings")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(UiDatePicker), {
          modelValue: dateRange.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("form", _hoisted_8, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(MeetingListSort), {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(MeetingListFilters))
          ])
        ])
      ]),
      (
          (sortMenuSelected.value && sortMenuSelected.value.length) ||
          (activeFilters.value && activeFilters.value.length)
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                key: filter,
                removable: "",
                onRemove: ($event: any) => (handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`meeting.filters.${filter.field}.label`)) + ": " + _toDisplayString(filter.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings.value, (meeting, index) => {
          return (_openBlock(), _createBlock(_unref(MeetingListItem), {
            key: index,
            "model-value": meeting
          }, null, 8, ["model-value"]))
        }), 128)),
        (!meetings.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1))
          : _createCommentVNode("", true)
      ]),
      (meetings.value.length)
        ? (_openBlock(), _createBlock(_unref(UiPagination), {
            key: 1,
            modelValue: currentPage.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentPage).value = $event)),
            count: count.value,
            perPage: perPage,
            i18n: _ctx.$t,
            class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
          }, null, 8, ["modelValue", "count", "i18n"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})