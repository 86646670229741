import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-gray-100 hover:bg-white my-2 py-3 px-4 items-center rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_2 = { class: "mb-2 sm:mb-0" }
const _hoisted_3 = { class: "text-xs bg-gray-50 px-1.5 py-0.5 rounded-lg border-gray-200 ring-1 ring-black ring-opacity-5" }
const _hoisted_4 = { class: "grid grid-cols-2 lg:grid-cols-6 gap-3" }
const _hoisted_5 = { class: "text-gray-600 text-sm flex flex-wrap items-center space-x-1" }
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = {
  key: 3,
  class: "relative"
}
const _hoisted_8 = { class: "text-gray-600 text-sm flex flex-wrap items-center space-x-1" }
const _hoisted_9 = { class: "truncate ml-0.5" }
const _hoisted_10 = { class: "flex flex-col text-sm" }
const _hoisted_11 = { class: "text-gray-400 text-xs" }
const _hoisted_12 = {
  key: 0,
  class: "truncate"
}
const _hoisted_13 = {
  key: 1,
  class: "truncate"
}
const _hoisted_14 = { class: "flex flex-col text-sm" }
const _hoisted_15 = { class: "text-gray-400 text-xs" }
const _hoisted_16 = { class: "truncate" }
const _hoisted_17 = { class: "flex flex-col text-sm" }
const _hoisted_18 = { class: "text-gray-400 text-xs" }
const _hoisted_19 = {
  key: 0,
  class: "truncate"
}
const _hoisted_20 = {
  key: 1,
  class: "truncate"
}
const _hoisted_21 = {
  key: 0,
  class: "flex items-center lg:justify-end"
}
const _hoisted_22 = { class: "grid grid-cols-2 lg:grid-cols-6 gap-3 border-t pt-3 mt-2" }
const _hoisted_23 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_24 = { class: "truncate" }
const _hoisted_25 = { class: "truncate" }
const _hoisted_26 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_27 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_28 = { class: "text-gray-600 text-xs flex items-center" }
const _hoisted_29 = { class: "ml-1" }
const _hoisted_30 = {
  key: 0,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "text-gray-600 text-xs flex items-center col-span-2" }
const _hoisted_33 = { class: "ml-1 space-x-0.5" }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  class: "flex gap-2 text-gray-600 text-xs items-center"
}

import { computed, PropType } from "vue";
import { UiButton } from "@/components";
import {
  CalendarIcon,
  ClockIcon,
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  TagIcon,
  UsersIcon,
  CalculatorIcon,
  AnnotationIcon,
} from "@heroicons/vue/outline";
import { PlayIcon } from "@heroicons/vue/solid";
import {
  CallStatus,
  CallStatusClassColor,
  CallType,
  ICall,
  Permission,
} from "@/definitions";
import { formatDateAsString, formatSecToTime } from "@/utils/datetimeCommon";
import moment from "moment";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallListItem',
  props: {
  modelValue: {
    type: Object as PropType<ICall>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const duration = computed(() => {
  return moment(props.modelValue?.endedAt).diff(
    moment(props.modelValue.answeredAt),
    "seconds",
  );
});

const waitingTime = computed(() => {
  if (
    props.modelValue?.status === CallStatus.ANSWERED &&
    props.modelValue.answeredAt
  ) {
    return moment(props.modelValue.answeredAt).diff(
      moment(props.modelValue?.startedAt),
      "seconds",
    );
  }
  return moment(props.modelValue.endedAt).diff(
    moment(props.modelValue?.startedAt),
    "seconds",
  );
});

const agents = computed(() => {
  const agentsNames: string[] = [];
  props.modelValue?.events.forEach((e) => {
    if (e.sourceUser) {
      if (!agentsNames.includes(e.sourceUser.name)) {
        agentsNames.push(e.sourceUser.name);
      }
    }
    if (e.destinationUser) {
      if (!agentsNames.includes(e.destinationUser.name)) {
        agentsNames.push(e.destinationUser.name);
      }
    }
  });
  return agentsNames;
});

const destinationEvent = computed(() => {
  if (
    props.modelValue?.type === CallType.INBOUND &&
    props.modelValue.status === CallStatus.ANSWERED
  ) {
    return props.modelValue?.events.find(
      (event) => event.status === props.modelValue.status,
    );
  }
  return props.modelValue?.events[props.modelValue.events.length - 1];
});

function formatDestinationNumber(number: string) {
  const pattern = /(\d+)_(\d+)_(\d+)/; // Pattern to match the "211_1_1" like strings
  const match = number.match(pattern);
  if (match) {
    return match[1];
  } else {
    return applyPhoneMask(number);
  }
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(__props.modelValue.id), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (__props.modelValue.type === _unref(CallType).INBOUND)
          ? (_openBlock(), _createBlock(_unref(PhoneIncomingIcon), {
              key: 0,
              class: "h-5 w-5 text-cyan-500",
              "aria-hidden": "true"
            }))
          : (__props.modelValue.type === _unref(CallType).OUTBOUND)
            ? (_openBlock(), _createBlock(_unref(PhoneOutgoingIcon), {
                key: 1,
                class: "h-5 w-5 text-orange-500",
                "aria-hidden": "true"
              }))
            : (__props.modelValue.type === _unref(CallType).INTERNAL)
              ? (_openBlock(), _createBlock(_unref(PhoneIcon), {
                  key: 2,
                  class: "h-5 w-5 text-gray-500",
                  "aria-hidden": "true"
                }))
              : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`call.type.${__props.modelValue.type}`)), 1),
        (__props.modelValue.survey)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_unref(AnnotationIcon), { class: "h-5 w-5 text-gray-500" }),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "absolute top-0 right-0 h-2 w-2 bg-green-600 rounded-full" }, null, -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex rounded-full h-2.5 w-2.5", _unref(CallStatusClassColor)[__props.modelValue.status]])
        }, null, 2),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`call.status.${__props.modelValue.status}`)), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("call.list.origin")), 1),
        (__props.modelValue.events[0].sourceUser)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(__props.modelValue.events[0].sourceUser.name), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(applyPhoneMask)(__props.modelValue.events[0].sourceNumber) ?? "-"), 1))
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("call.list.mask")), 1),
        _createElementVNode("span", _hoisted_16, _toDisplayString(__props.modelValue.type === _unref(CallType).INBOUND && __props.modelValue.destinationNumber
            ? _unref(applyPhoneMask)(__props.modelValue.destinationNumber)
            : "-"), 1)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("call.list.destination")), 1),
        (destinationEvent.value?.destinationUser)
          ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(destinationEvent.value?.destinationUser.name), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(destinationEvent.value?.destinationNumber
              ? formatDestinationNumber(destinationEvent.value?.destinationNumber)
              : "-"), 1))
      ]),
      (__props.modelValue.audioFilePath)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            (_ctx.$can(_unref(Permission).VIEW_CALL) && __props.modelValue.status == 'ANSWERED')
              ? _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                  key: 0,
                  size: "",
                  shape: "rounded-full",
                  variant: "inherit",
                  "text-variant": "primary",
                  shadow: "none",
                  themed: "",
                  class: "border-transparent focus:ring-primary shadow-none text-xs flex items-center",
                  to: {
            name: 'ViewCallById',
            params: {
              id: __props.modelValue.id,
            },
          }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(PlayIcon), {
                      class: "h-7 w-7",
                      "aria-hidden": "true"
                    }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("core.actions.View")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])), [
                  [_directive_tippy, _ctx.$t('core.actions.View')]
                ])
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_unref(CalendarIcon), {
          class: "h-4 w-4",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_24, _toDisplayString(_unref(formatDateAsString)(__props.modelValue.startedAt.toString(), "DD/MM/YYYY")), 1),
        _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(formatDateAsString)(__props.modelValue.startedAt.toString(), "HH:mm:ss")), 1)
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_unref(ClockIcon), {
          class: "h-4 w-4",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("call.list.duration")) + ": ", 1),
        _createElementVNode("span", null, _toDisplayString(__props.modelValue.status == _unref(CallStatus).ANSWERED && __props.modelValue.endedAt
            ? _unref(formatSecToTime)(duration.value)
            : "-"), 1)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_unref(ClockIcon), {
          class: "h-4 w-4",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("call.list.waitingTime")) + ": ", 1),
        _createElementVNode("span", null, _toDisplayString(__props.modelValue.status != _unref(CallStatus).ON_PROGRESS &&
          __props.modelValue.endedAt !== null
            ? _unref(formatSecToTime)(waitingTime.value)
            : "-"), 1)
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_unref(TagIcon), {
          class: "h-4 w-4 mr-1",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("call.list.media")) + ": ", 1),
        _createElementVNode("div", _hoisted_29, [
          (__props.modelValue.media)
            ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(__props.modelValue.media), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_31, "-"))
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createVNode(_unref(UsersIcon), {
          class: "h-4 w-4 mr-1",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("call.list.participants")) + ": ", 1),
        _createElementVNode("div", _hoisted_33, [
          (agents.value.length > 1)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(agents.value, (agent) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500",
                  key: `agent${__props.modelValue.id}${agent}`
                }, _toDisplayString(agent), 1))
              }), 128))
            : (_openBlock(), _createElementBlock("span", _hoisted_34, "-"))
        ])
      ]),
      (__props.modelValue.ura !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(_unref(CalculatorIcon), {
              class: "h-4 w-4",
              "aria-hidden": "true"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("call.list.ura")) + ": ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue.ura, (ura) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "px-1 border border-b-gray-400 rounded",
                key: `${__props.modelValue.id}${ura.node}`
              }, _toDisplayString(ura.digit), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})