import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative w-full text-gray-400 focus-within:text-gray-600" }
const _hoisted_2 = ["placeholder", "value"]

import dbounce from "debounce";
import { SearchIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaySearchWord',
  props: {
  modelValue: {
    type: String,
  },
  debounce: {
    type: Number,
    default: 1000,
  },
  placeholder: {
    type: String,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const { t } = useI18n();

const emit = __emit;

const placeholderText = computed(() => {
  if (props.placeholder !== undefined) {
    return props.placeholder;
  }
  return t("core.actions.Search");
});

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(SearchIcon), {
      class: "pointer-events-none absolute top-2 left-2 mr-5 h-5 w-5",
      "aria-hidden": "true"
    }),
    _createElementVNode("input", {
      name: "search-field",
      id: "search-field",
      class: "h-full w-full border-transparent py-2 pl-9 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 bg-transparent",
      placeholder: placeholderText.value,
      type: "search",
      value: props.modelValue,
      autocomplete: "off",
      onInput: _cache[0] || (_cache[0] = ($event: any) => {
        _unref(dbounce)(() => {
          updateValue(($event.target as HTMLInputElement).value);
        }, props.debounce)()
      })
    }, null, 40, _hoisted_2)
  ]))
}
}

})