import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 w-full" }
const _hoisted_2 = { class: "bg-white flex flex-col items-center sm:items-start sm:justify-center pt-20 sm:pt-0 w-full px-8 lg:px-28 xl:px-40" }
const _hoisted_3 = {
  key: 0,
  class: "mb-8 text-2xl font-semibold text-gray-700"
}
const _hoisted_4 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_5 = { class: "text-sm mb-8" }
const _hoisted_6 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_7 = { class: "text-sm mb-8" }
const _hoisted_8 = { class: "flex items-center justify-between" }
const _hoisted_9 = { class: "text-sm" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { class: "block" }

import { computed, reactive, ref, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import AuthService from "@/services/AuthService";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength, email, requiredIf } from "@/utils/validators";
import { toastServiceError } from "@/utils/notification";
import { UiTextInput, UiCheckboxInput, UiButton } from "@/components";
import {
  UiActions,
  AuthActions,
  ILoginFormState,
  IUser,
  AuthGetters,
} from "@/definitions";
import { UserService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const formState = reactive<ILoginFormState>({
  email: "",
  password: "",
  rememberMe: true,
});

const FormType = {
  LOGIN: "LOGIN",
  RECOVER: "RECOVER",
  SENT_RECOVER: "SENT_RECOVER",
};

const rules = computed<ValidationArgs<ILoginFormState>>(() => ({
  email: {
    email,
    required,
    maxLength: maxLength(250),
  },
  password: {
    required: requiredIf(() => {
      return showFormType.value === FormType.LOGIN;
    }),
  },
  rememberMe: {},
}));

const v$: Ref<Validation<ValidationArgs<ILoginFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

const onSubmitLogin = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  AuthService.login(formState)
    .then(async (response) => {
      await store.dispatch(AuthActions.LOGIN, response.data);

      if (user.value.lastLogin === undefined || user.value.lastLogin === null) {
        router.push({ name: "UpdatePassword" }).then();
        return true;
      }
      router.push("/").then();
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const onSubmitResetPassword = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  UserService.recoveryPassword(formState)
    .then(
      async (/* response */) => {
        showFormType.value = FormType.SENT_RECOVER;
      },
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const showFormType = ref(FormType.LOGIN);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "bg-primary hidden sm:flex items-center justify-center w-full relative" }, [
      _createElementVNode("span", { class: "h-[150px] xl:h-[250px] login-bg" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mb-8 w-[225px] login-logo" }, null, -1)),
      (showFormType.value === FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t("welcome")), 1))
        : _createCommentVNode("", true),
      (showFormType.value === FormType.RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("forgotPassword.title")), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("forgotPassword.description")), 1)
          ], 64))
        : _createCommentVNode("", true),
      (showFormType.value === FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("forgotPassword.requested.title")), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("forgotPassword.requested.description")), 1)
          ], 64))
        : _createCommentVNode("", true),
      (showFormType.value === FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("form", {
            key: 3,
            class: "space-y-6 w-full",
            onSubmit: _withModifiers(onSubmitLogin, ["prevent"])
          }, [
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('login.form.emailAddress'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: _unref(v$).email.$errors,
              onBlur: _unref(v$).email.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formState.password) = $event)),
              name: "password",
              type: "password",
              label: _ctx.$t('login.form.password'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "password",
              errors: _unref(v$).password?.$errors,
              onBlur: _unref(v$).password?.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: formState.rememberMe,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.rememberMe) = $event)),
                name: "rememberMe",
                label: _ctx.$t('login.form.rememberMe'),
                class: "h-6"
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (showFormType.value = FormType.RECOVER)),
                  class: "text-sm font-medium text-primary hover:text-primary-darker cursor-pointer"
                }, _toDisplayString(_ctx.$t("login.form.forgotYourPassword")), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_unref(UiButton), {
                type: "submit",
                themed: "",
                variant: "primary",
                class: "w-full text-lg shadow",
                size: "xl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("login.form.signIn")), 1)
                ]),
                _: 1
              })
            ])
          ], 32))
        : _createCommentVNode("", true),
      (showFormType.value === FormType.RECOVER)
        ? (_openBlock(), _createElementBlock("form", {
            key: 4,
            class: "space-y-6 w-full",
            onSubmit: _withModifiers(onSubmitResetPassword, ["prevent"])
          }, [
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('forgotPassword.form.emailAddress'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: _unref(v$).email.$errors,
              onBlur: _unref(v$).email.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", null, [
              _createVNode(_unref(UiButton), {
                type: "submit",
                themed: "",
                variant: "primary",
                class: "w-full text-lg shadow",
                size: "xl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("forgotPassword.form.resetPass")), 1)
                ]),
                _: 1
              })
            ])
          ], 32))
        : _createCommentVNode("", true),
      (showFormType.value === FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_unref(UiButton), {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (showFormType.value = FormType.LOGIN)),
              themed: "",
              variant: "primary",
              class: "w-full text-lg shadow",
              size: "xl"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("forgotPassword.requested.action")), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})