import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { PlusIcon } from "@heroicons/vue/solid";
import { PropType } from "vue";
import { ConnectFlowModelingActions, IFlowNode } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenEdge',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

function openComponentSelector() {
  store.dispatch("flow/" + ConnectFlowModelingActions.SET_MENU, {
    show: true,
    action: "update",
    idTriggerBy: props.node.id,
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "rounded-full p-1 border border-red-400 bg-white shadow hover:bg-blue-50 hover:border-blue-400",
    onClick: openComponentSelector
  }, [
    _createVNode(_unref(PlusIcon), { class: "h-5 w-5" })
  ]))
}
}

})