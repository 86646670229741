import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCheckDirtyBeforeRouteLeave',
  props: {
  dirty: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

onBeforeRouteLeave(() => {
  if (props.dirty) {
    const answer = window.confirm(t("core.messages.confirmRouteLeave"));
    if (!answer) return false;
  } else return true;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})