import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white shadow-sm rounded-md ring-1 ring-black ring-opacity-5 flex flex-col" }
const _hoisted_2 = { class: "p-3 flex items-center justify-between bg-gray-100" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex items-center w-2/4" }
const _hoisted_6 = { class: "overflow-y-auto p-6 flex-1" }
const _hoisted_7 = { class: "space-y-4" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "mr-1 font-semibold text-gray-700" }
const _hoisted_10 = { class: "text-gray-400" }
const _hoisted_11 = { class: "text-gray-500" }

import { ref, computed } from "vue";
import { IMeetingMessageWithFormattedTimestamp } from "@/definitions";
import { UiListSearch } from "@/components";
import { useI18n } from "vue-i18n";
import { MenuIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'TranscriptSection',
  props: {
  messages: {
    type: Array as () => IMeetingMessageWithFormattedTimestamp[],
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const searchQuery = ref("");

const filteredMessages = computed(() => {
  if (!searchQuery.value) {
    return props.messages;
  }
  const query = searchQuery.value.toLowerCase();
  return props.messages.filter((message) =>
    message.transcript.toLowerCase().includes(query),
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(MenuIcon), {
          class: "mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]",
          "aria-hidden": "true"
        }),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(t)("meeting.view.transcription.title")), 1)
      ]),
      _createElementVNode("form", _hoisted_5, [
        _createVNode(_unref(UiListSearch), {
          class: "w-full bg-white rounded-md",
          modelValue: searchQuery.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredMessages.value, (message) => {
          return (_openBlock(), _createElementBlock("li", {
            key: message.id,
            class: "flex flex-col"
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(message.speakerName), 1),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mr-1" }, "-", -1)),
              _createElementVNode("p", _hoisted_10, _toDisplayString(message.formattedTimestamp), 1)
            ]),
            _createElementVNode("p", _hoisted_11, _toDisplayString(message.transcript), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})