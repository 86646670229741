import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_7 = { class: "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_8 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_9 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_10 = { class: "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_11 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_12 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_13 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_14 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_15 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_16 = { class: "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_17 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_18 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_19 = { class: "flex justify-end px-4 sm:px-0" }

import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import ConnectFlowService from "@/services/ConnectFlowService";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IConnectFlow, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiContainerSection } from "@/components";
import { AxiosResponse } from "axios";
import { applyPhoneMask } from "../../../utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowDetailPage',
  setup(__props) {

const route = useRoute();

const connectFlow: Ref<IConnectFlow | undefined> = ref(undefined);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getConnectFlow = (id: number) => {
  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      connectFlow.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getConnectFlow(parseInt(route.params.id as string));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlow.view.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.view.description")), 1)
      ]),
      actions: _withCtx(() => [
        (_ctx.$can(_unref(Permission).EDIT_CONNECT_FLOW))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
            name: 'EditConnectFlowById',
            params: {
              id: _unref(route).params.id,
            },
          },
              class: "block hover:bg-gray-50"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(UiButton), {
                  variant: "primary",
                  "text-variant": "white",
                  themed: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(UiContainerSection), null, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("connectFlow.view.section.title")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("connectFlow.view.section.description")), 1)
        ]),
        content: _withCtx(() => [
          _createElementVNode("dl", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("dt", _hoisted_8, _toDisplayString(_ctx.$t("connectFlow.labels.level")), 1),
              _createElementVNode("dd", _hoisted_9, _toDisplayString(connectFlow.value?.level.name), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("dt", _hoisted_11, _toDisplayString(_ctx.$t("connectFlow.labels.name")), 1),
              _createElementVNode("dd", _hoisted_12, _toDisplayString(connectFlow.value?.name), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.$t("connectFlow.labels.status")), 1),
              _createElementVNode("dd", _hoisted_15, [
                _createElementVNode("p", {
                  class: _normalizeClass([[
                    connectFlow.value?.isActive
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                  ], "inline-flex rounded-full px-2 text-xs font-semibold leading-5"])
                }, _toDisplayString(connectFlow.value?.isActive
                      ? _ctx.$t("core.options.Active")
                      : _ctx.$t("core.options.Inactive")), 3)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("dt", _hoisted_17, _toDisplayString(_ctx.$t("connectFlow.labels.number")), 1),
              _createElementVNode("dd", _hoisted_18, _toDisplayString(connectFlow.value?.number !== undefined
                    ? _unref(applyPhoneMask)(connectFlow.value?.number.number)
                    : "-"), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListConnectFlow' })))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})