import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = ["name", "id", "type", "placeholder", "autocomplete", "value", "disabled", "rows"]

import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTextArea',
  props: {
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: [String, Boolean],
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
  silentErrors: {
    type: Array<ErrorObject>,
    default: [],
  },
  inputClass: {
    type: String,
    default: "px-4 py-3",
  },
  rows: {
    type: Number,
    default: 3,
  },
},
  emits: ["update:modelValue", "blur"],
  setup(__props, { emit: __emit }) {

const props = __props;
const hasErros = computed(
  () => props.errors.length || props.silentErrors.length,
);
const emit = __emit;
function handleInput(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  emit("update:modelValue", value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label !== false)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: __props.name,
          class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
        }, [
          _createTextVNode(_toDisplayString(__props.label) + " ", 1),
          (__props.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      name: __props.name,
      id: __props.name,
      type: __props.type,
      "no-": "",
      placeholder: __props.placeholder,
      autocomplete: __props.autocomplete,
      class: _normalizeClass(["block w-full rounded-md border-0 text-gray-900 placeholder:text-gray-400 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm resize-none", [
        __props.inputClass,
        !hasErros.value
          ? 'ring-gray-300 focus:ring-primary'
          : 'ring-danger focus:ring-danger',
        __props.disabled ? 'bg-gray-300' : '',
      ]]),
      value: props.modelValue,
      onInput: handleInput,
      disabled: __props.disabled,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (emit('blur', $event))),
      rows: props.rows
    }, null, 42, _hoisted_4),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-sm text-danger absolute bg-red-50 px-2 py-2 w-full shadow z-10",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.silentErrors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-sm text-danger",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
      ]))
    }), 128))
  ]))
}
}

})