import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex flex-col items-center" }

import { PhoneIncomingIcon } from "@heroicons/vue/solid";
import { UiNode } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'StartNode',
  props: {
  nodeId: {
    type: String,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiNode), {
      "node-id": __props.nodeId,
      showHandleTarget: false,
      hideNodeId: "",
      bgColor: "bg-blue-50",
      borderColor: "border-blue-300",
      roundedStyle: "rounded-full",
      nodeWidth: "w-20",
      nodeHeight: "h-20"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(PhoneIncomingIcon), { class: "h-10 w-10 text-blue-600 mb-1" })
        ])
      ]),
      _: 1
    }, 8, ["node-id"])
  ]))
}
}

})