import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center absolute top-5 left-5" }

import { PropType } from "vue";
import { ConnectFlowModelingActions, IFlowNode } from "@/definitions";
import store from "@/store";
import { PhoneMissedCallIcon } from "@heroicons/vue/solid";
import { UiNode } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'HangUp',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: props.node.id,
    parentId: props.node.parentId,
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiNode), {
    nodeId: __props.node.id,
    showActions: "",
    noEdition: "",
    onRemoveNode: removeNode,
    showHandleSource: false,
    hideNodeId: "",
    bgColor: "bg-gray-50",
    borderColor: "border-gray-300",
    roundedStyle: "rounded-2xl",
    padding: "p-0 pt-2 pr-2",
    nodeWidth: "w-24",
    nodeHeight: "h-20"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(PhoneMissedCallIcon), { class: "h-10 w-10 text-gray-600 mb-1" })
      ])
    ]),
    _: 1
  }, 8, ["nodeId"]))
}
}

})