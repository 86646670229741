import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_4 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

import {
  computed,
  ComputedRef,
  onMounted,
  PropType,
  Ref,
  ref,
  WritableComputedRef,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IMultiSelectOption,
  IFlowNode,
  NodeType,
  IFlowConnection,
} from "@/definitions";
import store from "@/store";
import { ReceiptRefundIcon } from "@heroicons/vue/outline";
import { UiButton, UiMultiselect, UiNode, UiRightPanel } from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@/utils/validators";
import { useI18n } from "vue-i18n";

interface IGoToFormState {
  node?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoTo',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const { t } = useI18n();

const elementsOptions = computed(() => {
  const options: IMultiSelectOption[] = [];
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_NODES].forEach(
    (node: IFlowNode) => {
      if (
        ![
          NodeType.START,
          NodeType.OPEN_EDGE,
          NodeType.GO_TO,
          NodeType.HANG_UP,
        ].includes(node.data.component)
      ) {
        options.push({
          label: t(`connectFlow.node.${node.data.component}.title`),
          value: node.id,
        });
      }
    },
  );
  return options;
});
const formState: Ref<IGoToFormState> = ref({
  node: undefined,
});
const rules = computed<ValidationArgs<IGoToFormState>>(() => {
  const r: ValidationArgs<IGoToFormState> = {
    node: {
      required,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IGoToFormState>>> = useVuelidate(
  rules,
  formState,
);

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
const sourceConnection: ComputedRef<IFlowConnection | null> = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS].find(
    (c: IFlowConnection) => c.target === props.node.id,
  ),
);
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.goToNode = formState.value.node;
    showForm.value = false;
    const handle = sourceConnection.value?.sourceHandle ?? null;
    store
      .dispatch("flow/" + ConnectFlowModelingActions.GO_TO_CONNECTION, {
        source: props.node.id,
        target: formState.value.node,
        handle: handle,
      })
      .then();
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.goToNode !== undefined) {
    formState.value.node = nodeWritable.value.data.goToNode;
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(UiNode), {
      nodeId: __props.node.id,
      title: _ctx.$t('connectFlow.node.goTo.title'),
      icon: _unref(ReceiptRefundIcon),
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => (showForm.value = true)),
      onRemoveNode: removeNode,
      class: _normalizeClass(isFormCorrect.value ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("connectFlow.node.goTo.description")), 1)
        ])
      ]),
      _: 1
    }, 8, ["nodeId", "title", "icon", "class"]),
    _createVNode(_unref(UiRightPanel), {
      modelValue: showForm.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showForm).value = $event)),
      title: _ctx.$t('connectFlow.node.goTo.title'),
      icon: _unref(ReceiptRefundIcon)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.goTo.helpText")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "schedule",
              modelValue: _unref(v$).node.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).node.$model) = $event)),
              options: elementsOptions.value,
              errors: _unref(v$).node.$errors,
              primitive: "",
              required: ""
            }, {
              "option-label": _withCtx(({ option }) => [
                _createTextVNode(_toDisplayString(option.value) + " - " + _toDisplayString(option.label), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (showForm.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(UiButton), {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "icon"])
  ], 64))
}
}

})