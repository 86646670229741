import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-b border-gray-300 pt-4 relative mb-4 pb-7" }
const _hoisted_2 = { class: "grid sm:grid-cols-8 grid-cols-1 items-center gap-4 gap-y-5" }
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "grid sm:grid-cols-8 grid-cols-1 items-center gap-4 gap-y-5 mt-4" }
const _hoisted_6 = { class: "col-span-3 h-full flex flex-col" }
const _hoisted_7 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_9 = {
  controls: "",
  class: "w-full"
}
const _hoisted_10 = ["src", "type"]

import { Ref, ref, computed, watch, reactive } from "vue";
import {
  UiButton,
  UiTextInput,
  UiTextArea,
  UiAudioUpload,
  UiMultiselect,
} from "@/components";
import { TrashIcon } from "@heroicons/vue/outline";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredIf } from "@/utils/validators";
import { IQuestionForm, IMultiSelectOption } from "@/definitions";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyFormQuestion',
  props: {
  modelValue: {
    type: Object as () => IQuestionForm,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  showRemove: {
    type: Boolean,
    required: true,
  },
},
  emits: ["update:modelValue", "remove"],
  setup(__props, { emit: __emit }) {

const props = __props;

const formState = reactive<IQuestionForm>(props.modelValue);

const scores = [
  {
    label: "0-5",
    value: "0-5",
  },
  {
    label: "0-9",
    value: "0-9",
  },
  {
    label: "0-10",
    value: "0-10",
  },
  {
    label: "1-2",
    value: "1-2",
  },
  {
    label: "1-5",
    value: "1-5",
  },
  {
    label: "1-9",
    value: "1-9",
  },
  {
    label: "1-10",
    value: "1-10",
  },
] as IMultiSelectOption[];

const emit = __emit;

const rules = computed<ValidationArgs<IQuestionForm>>(() => {
  const r: ValidationArgs<IQuestionForm> = {
    id: {},
    label: { required },
    textToAudio: {
      required: requiredIf(() => {
        return !formState.audio && !formState.audioFilePath;
      }),
    },
    audio: {
      required: requiredIf(() => {
        return (
          (formState.textToAudio?.length ?? 0) < 1 && !formState.audioFilePath
        );
      }),
    },
    rangeAnswer: { required },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IQuestionForm>>> = useVuelidate(
  rules,
  formState,
);

const isTextAreaEmpty = computed(
  () => (formState.textToAudio?.length ?? 0) > 0,
);
const isAudioEmpty = computed(() => !!formState.audio);

const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

const audioData = ref();

const getAudioFile = async () => {
  if (formState.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

if (formState.audioFilePath) {
  getAudioFile();
}

watch(
  formState,
  () => {
    emit("update:modelValue", formState);
  },
  {
    deep: true,
  },
);

const removeQuestion = () => {
  emit("remove", formState.uuid);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.showRemove)
      ? (_openBlock(), _createBlock(_unref(UiButton), {
          key: 0,
          variant: "orange",
          class: "absolute top-1 right-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (removeQuestion()))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TrashIcon), { class: "w-4 h-4" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(UiTextInput), {
        id: `questionLabel-${props.index}`,
        name: "questionLabel",
        modelValue: _unref(v$).label.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).label.$model) = $event)),
        label: _ctx.$t('survey.labels.name'),
        placeholder: _ctx.$t('survey.create.section.questions.description'),
        class: "col-span-5",
        errors: _unref(v$).label.$errors
      }, null, 8, ["id", "modelValue", "label", "placeholder", "errors"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("survey.view.section.questions.scoreRange")), 1),
        _createVNode(_unref(UiMultiselect), {
          id: `initScore-${props.index}`,
          name: "initScore",
          primitive: true,
          modelValue: formState.rangeAnswer,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.rangeAnswer) = $event)),
          options: scores,
          class: "w-full min-w-[120px]",
          errors: _unref(v$).rangeAnswer.$errors
        }, null, 8, ["id", "modelValue", "errors"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(UiTextArea), {
        id: `textToAudio-${props.index}`,
        name: "textToAudio",
        label: _ctx.$t('survey.labels.text'),
        placeholder: _ctx.$t('survey.labels.questionLabel'),
        disabled: isAudioEmpty.value,
        rows: "2",
        modelValue: _unref(v$).textToAudio.$model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).textToAudio.$model) = $event)),
        class: "col-span-5",
        errors: _unref(v$).textToAudio.$errors
      }, null, 8, ["id", "label", "placeholder", "disabled", "modelValue", "errors"]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("survey.view.section.questions.audio")), 1),
        _createVNode(_unref(UiAudioUpload), {
          id: `audioUpload-${props.index}`,
          index: props.index,
          class: "flex-grow",
          disabled: isTextAreaEmpty.value,
          errors: _unref(v$).audio.$errors,
          "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules'),
          "onUpdate:file": handleFileUpdate
        }, null, 8, ["id", "index", "disabled", "errors", "text-rule"])
      ]),
      (audioData.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("audio", _hoisted_9, [
              _createElementVNode("source", {
                src: audioData.value,
                type: _unref(getAudioFormat)(formState.audioFilePath || '')
              }, null, 8, _hoisted_10)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})