import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_11 = {
  key: 0,
  class: "text-xs mt-2"
}
const _hoisted_12 = {
  for: "connectFlow",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_13 = {
  key: 0,
  class: "text-xs mt-2"
}
const _hoisted_14 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { NumberService, LevelService, ConnectFlowService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import router from "@/router";
import {
  UiButton,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiMultiselect,
  UiPanel,
  UiTextInput,
} from "@/components";
import {
  INumber,
  ILevel,
  IConnectFlow,
  IDefaultServiceResult,
  Permission,
  UiActions,
  FormAction,
  INumberFormState,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AxiosResponse } from "axios";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<INumberFormState>({
  id: 0,
  levelId: 0,
  connectFlowId: 0,
  mediaLabel: "",
  agents: [],
});

const rules = computed(() => ({
  id: {},
  levelId: {
    required,
  },
  connectFlowId: {},
  mediaLabel: {},
  agents: {},
}));

const v$: Ref<Validation<ValidationArgs<INumberFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const number: Ref<INumber | undefined> = ref();
const getNumber = (id: number) => {
  setLoading(true);
  NumberService.get<INumber>(id)
    .then((response: AxiosResponse<INumber>) => {
      number.value = response.data;
      formState.id = id;
      formState.levelId = response.data.level.id;
      formState.connectFlowId = response.data.connectFlowId;
      formState.mediaLabel = response.data.mediaLabel;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
getNumber(parseInt(route.params.id as string));

const connectFlows: Ref<IConnectFlow[]> = ref([]);
const getConnectFlows = () => {
  setLoading(true);
  ConnectFlowService.getAll<IConnectFlow[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  }).then((response) => (connectFlows.value = response.data));
};

watch([formState.levelId], () => {
  getConnectFlows();
});

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>()
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
      getConnectFlows();
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const id = formState.id || 0;
  NumberService.update<IDefaultServiceResult>(id, formState)
    .then((response: AxiosResponse<IDefaultServiceResult>) => {
      toast.success(response.data.message);
      v$.value.$reset();
      router.push({
        name: "ListNumber",
      });
    }, toastServiceError)
    .finally(() => setLoading(false));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("number." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("number.labels.number")), 1),
              _createElementVNode("input", {
                name: "number",
                type: "text",
                readonly: true,
                disabled: true,
                class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                value: number.value ? _unref(applyPhoneMask)(number.value.number) : ''
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("number.labels.city")), 1),
              _createElementVNode("input", {
                name: "city",
                type: "text",
                readonly: true,
                disabled: true,
                class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                value: `${number.value?.city}/${number.value?.state}`
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("number.labels.type")), 1),
              (number.value?.type)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    name: "city",
                    type: "text",
                    readonly: true,
                    disabled: true,
                    class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                    value: _ctx.$t(`number.types.${number.value?.type}`)
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("number.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                disabled: !levelFilter.value.root,
                primitive: true,
                options: levels.value
              }, null, 8, ["modelValue", "disabled", "options"]),
              (formState.agents.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("number.edit.blockLevel")), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("core.entity.ConnectFlow")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "connectFlowId",
                label: "name",
                "value-prop": "id",
                modelValue: formState.connectFlowId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formState.connectFlowId) = $event)),
                disabled: formState.agents.length > 0,
                primitive: true,
                options: connectFlows.value,
                "can-clear": ""
              }, null, 8, ["modelValue", "disabled", "options"]),
              (formState.agents.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("number.edit.blockLevel")), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_unref(UiTextInput), {
                modelValue: formState.mediaLabel,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.mediaLabel) = $event)),
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.edit.mediaLabel'),
                class: "col-span-6 sm:col-span-4",
                onBlur: _unref(v$).mediaLabel.$touch
              }, null, 8, ["modelValue", "label", "onBlur"])
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListNumber' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_NUMBER))
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})