import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { UiSortMenu } from "@/components";
import { ISortMenuItem } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyListSort',
  props: {
  modelValue: {
    type: Array<ISortMenuItem>,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const sortMenuItems = ref([
  {
    label: t("survey.sortMenu.items.name"),
    key: "name",
  },
] as ISortMenuItem[]);

const props = __props;

const emit = __emit;

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiSortMenu), {
    label: _ctx.$t('core.actions.Sort'),
    items: sortMenuItems.value,
    "model-value": props.modelValue,
    i18n: _ctx.$t,
    "onUpdate:modelValue": updateValue
  }, null, 8, ["label", "items", "model-value", "i18n"]))
}
}

})