export enum Permission {
  // call
  VIEW_CALLS = "viewCalls",
  VIEW_CALL = "viewCall",
  VIEW_ALL_USERS_CALLS = "viewAllUsersCalls",
  VIEW_LABEL_USERS_CALLS = "viewLabelUsersCalls",

  // connectFlow
  VIEW_CONNECT_FLOWS = "viewConnectFlows",
  VIEW_CONNECT_FLOW = "viewConnectFlow",
  EDIT_CONNECT_FLOW = "editConnectFlow",
  CREATE_CONNECT_FLOW = "createConnectFlow",

  // connectFlowAudio
  VIEW_CONNECT_FLOW_AUDIOS = "viewConnectFlowAudios",
  VIEW_CONNECT_FLOW_AUDIO = "viewConnectFlowAudio",
  EDIT_CONNECT_FLOW_AUDIO = "editConnectFlowAudio",
  CREATE_CONNECT_FLOW_AUDIO = "createConnectFlowAudio",

  // integration
  VIEW_INTEGRATIONS = "viewIntegrations",
  VIEW_INTEGRATION_LOGS = "viewIntegrationLogs",
  EDIT_INTEGRATION = "editIntegration",

  // level
  VIEW_LEVELS = "viewLevels",
  VIEW_LEVEL = "viewLevel",
  EDIT_LEVEL = "editLevel",
  CREATE_LEVEL = "createLevel",

  // number
  VIEW_NUMBERS = "viewNumbers",
  VIEW_NUMBER = "viewNumber",
  EDIT_NUMBER = "editNumber",

  // queue
  VIEW_QUEUES = "viewQueues",
  VIEW_QUEUE = "viewQueue",
  EDIT_QUEUE = "editQueue",
  CREATE_QUEUE = "createQueue",

  // report
  VIEW_AGENT_DEVICE_STATUS_HISTORY_REPORT = "viewAgentDeviceStatusHistoryReport",
  VIEW_AGENT_EXTENSION_REPORT = "viewAgentExtensionReport",
  VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT = "viewAgentProductivityPerAgentReport",
  VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT = "viewAgentProductivityPerDayReport",
  VIEW_CALL_REPORT = "viewCallReport",
  VIEW_BILLING_REPORT = "viewBillingReport",
  VIEW_CONTACT_CENTER_PER_AGENT_REPORT = "viewContactCenterPerAgentReport",
  VIEW_CONTACT_CENTER_PER_DAY_REPORT = "viewContactCenterPerDayReport",
  VIEW_QUEUE_TIME_REPORT = "viewQueueTimeReport",
  VIEW_REALTIME_REPORT = "viewRealtimeReport",

  // role
  VIEW_ROLE = "viewRole",
  EDIT_ROLE = "editRole",
  CREATE_ROLE = "createRole",
  ASSIGN_ROLE_TO_USER = "assignRoleToUser",
  ASSIGN_PERMISSION_TO_ROLE = "assignPermissionToRole",

  // schedule
  VIEW_SCHEDULES = "viewSchedules",
  VIEW_SCHEDULE = "viewSchedule",
  EDIT_SCHEDULE = "editSchedule",
  CREATE_SCHEDULE = "createSchedule",

  // spamNumber
  VIEW_SPAM_NUMBERS = "viewSpamNumbers",
  VIEW_SPAM_NUMBER = "viewSpamNumber",
  EDIT_SPAM_NUMBER = "editSpamNumber",
  CREATE_SPAM_NUMBER = "createSpamNumber",

  // survey
  VIEW_SURVEYS = "viewSurveys",
  VIEW_SURVEY = "viewSurvey",
  EDIT_SURVEY = "editSurvey",
  CREATE_SURVEY = "createSurvey",

  // user
  VIEW_USERS = "viewUsers",
  VIEW_USER = "viewUser",
  EDIT_USER = "editUser",
  CREATE_USER = "createUser",
  ASSIGN_LEVEL_TO_USER = "assignLevelToUser",

  // userLabel
  VIEW_USER_LABELS = "viewUserLabels",
  VIEW_USER_LABEL = "viewUserLabel",
  EDIT_USER_LABEL = "editUserLabel",
  CREATE_USER_LABEL = "createUserLabel",

  // word
  VIEW_WORDS = "viewWords",
  VIEW_WORD = "viewWord",
  EDIT_WORD = "editWord",
  CREATE_WORD = "createWord",

  // to be removed
  VIEW_MEETINGS = "viewMeetings",
  VIEW_MEETING = "viewMeeting",
  VIEW_LABEL_USERS_MEETINGS = "viewLabelUsersMeetings",
  VIEW_ALL_USERS_MEETINGS = "viewAllUsersMeetings",
}
