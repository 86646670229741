import { IUserLabel, IUser } from "@/definitions";

export interface IBillingReportFilter {
  dateRange?: [Date, Date] | null;
  user?: IUser | null;
  userLabel?: IUserLabel | null;
  sourceNumber?: string | null;
  destinationNumber?: string | null;
  status?: string | null;
  type?: string | null;
}

export interface IBillingReportState {
  filter: IBillingReportFilter;
}

export enum BillingReportMutations {
  SET_FILTER = "billingReport_setFilter",
  CLEAR_FILTER = "billingReport_clearFilter",
}

export enum BillingReportActions {
  SET_FILTER = "billingReport_setFilter",
  CLEAR_FILTER = "billingReport_clearFilter",
}

export enum BillingReportGetters {
  DEFAULT_FILTER = "billingReport_defaultFilter",
  FILTER = "billingReport_filter",
  ACTIVE_FILTERS = "billingReport_activeFilters",
}
