import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  GoTo,
  HangUp,
  OpenEdge,
  PlayAudio,
  ClientInfo,
  TransferToExtension,
  TransferToExternal,
  TransferToQueue,
  TransferToSurvey,
  TimeValidationNode,
} from "@/views/pages/connect-flow/components/modeling/nodes";
import { computed } from "vue";
import store from "@/store";
import { ConnectFlowModelingGetters, NodeType } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomNode',
  props: {
  nodeId: {
    type: String,
    required: true,
  },
},
  setup(__props) {

function getNodeComponent(component: string) {
  switch (component) {
    case NodeType.TIME_VALIDATION:
      return TimeValidationNode;
    case NodeType.OPEN_EDGE:
      return OpenEdge;
    case NodeType.PLAY_AUDIO:
      return PlayAudio;
    case NodeType.CLIENT_INFO:
      return ClientInfo;
    case NodeType.TRANSFER_EXTENSION:
      return TransferToExtension;
    case NodeType.TRANSFER_EXTERNAL:
      return TransferToExternal;
    case NodeType.TRANSFER_QUEUE:
      return TransferToQueue;
    case NodeType.TRANSFER_SURVEY:
      return TransferToSurvey;
    case NodeType.HANG_UP:
      return HangUp;
    case NodeType.GO_TO:
      return GoTo;
  }
}

const props = __props;

const node = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
    props.nodeId,
  ),
);

return (_ctx: any,_cache: any) => {
  return (node.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(getNodeComponent(node.value.data.component)), {
        key: 0,
        node: node.value
      }, null, 8, ["node"]))
    : _createCommentVNode("", true)
}
}

})