import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "bg-cyan-100 text-cyan-700 flex items-center justify-center font-semibold text-lg"
}

import md5 from "md5";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiGravatar',
  props: {
  email: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const gravatarUrl = computed<string>(() => {
  const hash = md5(props.email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=404`;
});

const initials = computed<string>(() => getInitials(props.name));

const showImage = ref(true);

function getInitials(name: string) {
  const names = name.split(" ");
  if (names.length >= 2) {
    const firstInitial = names[0].charAt(0).toUpperCase();
    const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else if (names.length === 1) {
    return names[0].charAt(0).toUpperCase();
  }
  return "";
}

function handleImageError() {
  showImage.value = false;
}

return (_ctx: any,_cache: any) => {
  return (showImage.value)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: gravatarUrl.value,
        onError: handleImageError
      }, null, 40, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(initials.value), 1))
}
}

})