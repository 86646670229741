import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-6 space-y-6 overflow-hidden"
}
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "text-sm text-gray-600 mt-1" }
const _hoisted_5 = { class: "flex flex-col lg:flex-row gap-6" }
const _hoisted_6 = { class: "lg:w-1/2 space-y-6 h-[75vh]" }
const _hoisted_7 = { class: "lg:w-1/2 flex flex-col h-[75vh]" }

import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import { useI18n } from "vue-i18n";
import {
  SpeakerInsights,
  TranscriptSection,
} from "@/views/pages/meeting/components";
import { IMeeting, UiActions } from "@/definitions";
import MeetingService from "@/services/MeetingService";
import { toastServiceError } from "@/utils/notification";
import { UiGetters } from "@/definitions";
import {
  calculateSpeechPercentage,
  formatMessagesWithElapsedTime,
  formatDateTime,
} from "@/utils/meeting";
import { ChevronLeftIcon } from "@heroicons/vue/outline"; // Importa o ícone ChevronLeftIcon


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingDetailPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const meeting = ref<IMeeting | null>(null);
const isLoading = computed(() => store.getters[UiGetters.IS_LOADING]);
const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getMeeting = (id: number) => {
  setLoading(true);
  MeetingService.get<IMeeting>(id)
    .then((response) => {
      meeting.value = response.data;

      if (meeting.value?.participants) {
        meeting.value.participants = calculateSpeechPercentage(
          meeting.value.participants,
        );
      }

      if (meeting.value?.messages) {
        meeting.value.messages = formatMessagesWithElapsedTime(
          meeting.value.messages,
        );
      }
    })
    .catch(toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const formattedCreatedAt = computed(() =>
  formatDateTime(meeting.value?.createdAt),
);

onMounted(() => {
  const meetingId = parseInt(route.params.id as string);
  getMeeting(meetingId);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (meeting.value !== null && !isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              class: "text-primary hover:text-primary-dark flex flex-row items-center ml-auto",
              to: { name: 'MeetingList' }
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(ChevronLeftIcon), { class: "h-5 w-6" }),
                _createTextVNode(" " + _toDisplayString(_unref(t)("core.actions.Back")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(meeting.value.title), 1),
          _createElementVNode("p", _hoisted_4, " Gravado por " + _toDisplayString(meeting.value.createdByUser?.name) + ", " + _toDisplayString(formattedCreatedAt.value) + ", " + _toDisplayString(Math.floor(meeting.value.speechDuration / 60)) + " min. ", 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(SpeakerInsights), {
              participants: meeting.value.participants
            }, null, 8, ["participants"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(TranscriptSection), {
              messages: meeting.value.messages,
              class: "overflow-y-auto rounded-md"
            }, null, 8, ["messages"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})