import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

import { computed, ref, Ref, watch, WritableComputedRef } from "vue";
import { UiButton, UiRightPanel, UiTextInput } from "@/components";
import {
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowConnection,
} from "@/definitions";
import store from "@/store";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

interface IEdgeValidationFormState {
  timer: number | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormAwaitableEdge',
  props: {
  edgeId: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

watch(
  () => props.modelValue,
  (show) => {
    internalShow.value = show;
    if (show) {
      if (edge.value?.data !== undefined) {
        formState.value.timer = edge.value.data?.timerWait ?? null;
      }
    }
  },
);

const emit = __emit;

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
  emit("update:modelValue", state);
};
watch(internalShow, () => {
  handleToggle(internalShow.value);
});

const edge: WritableComputedRef<IFlowConnection> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.edgeId,
    ),
  set: (value: IFlowConnection) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const formState: Ref<IEdgeValidationFormState> = ref({
  timer: null,
});

const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
  const r: ValidationArgs<IEdgeValidationFormState> = {
    timer: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> =
  useVuelidate(rules, formState);

const isFormCorrect = ref(true);

async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();

  if (
    isFormCorrect.value &&
    edge.value.data != undefined &&
    formState.value.timer != null
  ) {
    edge.value.data.timerWait = formState.value.timer;
    edge.value.data.hasErrors = false;

    if (formState.value.timer) {
      edge.value.label = t("connectFlow.edge.labelTimer", {
        time: formState.value.timer,
      });
    } else {
      edge.value.label = "";
    }
    internalShow.value = false;
  }
  if (edge.value.data !== undefined) {
    edge.value.data.hasErrors = !isFormCorrect.value;
  }

  v$.value.$reset();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: internalShow.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((internalShow).value = $event)),
    title: _ctx.$t('connectFlow.edge.titleAwaitable')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        autocomplete: "off",
        class: "flex flex-col gap-3",
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        _createVNode(_unref(UiTextInput), {
          label: _ctx.$t('connectFlow.edge.timerWait'),
          name: "timerWait",
          type: "number",
          modelValue: _unref(v$).timer.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).timer.$model) = $event)),
          errors: _unref(v$).timer.$errors,
          "silent-errors": _unref(v$).timer.$silentErrors
        }, null, 8, ["label", "modelValue", "errors", "silent-errors"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (internalShow.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            type: "submit",
            variant: "primary",
            "text-variant": "white",
            themed: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})