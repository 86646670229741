import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { controls: "" }
const _hoisted_11 = ["src", "type"]
const _hoisted_12 = { class: "font-semibold mb-1" }

import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { ConnectFlowAudioService, VaultService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IConnectFlowAudio, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiPanel } from "@/components";
import { AxiosResponse } from "axios";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'AudioDetailPage',
  setup(__props) {

const route = useRoute();

const audio: Ref<IConnectFlowAudio | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getAudio = (id: number) => {
  setLoading(true);
  ConnectFlowAudioService.get<IConnectFlowAudio>(id)
    .then(async (response: AxiosResponse<IConnectFlowAudio>) => {
      audio.value = response.data;
      await getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const getAudioFile = async () => {
  if (audio.value !== undefined) {
    const { data } = await VaultService.getAudioAsset(
      audio.value.audioFilePath,
    );
    const blob = new Blob([data], {
      type: getAudioFormat(audio.value?.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const audioData = ref();

getAudio(parseInt(route.params.id as string));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlowAudio.view.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlowAudio.view.description")), 1)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListConnectFlowAudio' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
            ]),
            _: 1
          }),
          (_ctx.$can(_unref(Permission).EDIT_CONNECT_FLOW_AUDIO))
            ? (_openBlock(), _createBlock(_unref(RouterLink), {
                key: 0,
                to: {
              name: 'EditConnectFlowAudioById',
              params: {
                id: _unref(route).params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(UiPanel), null, _createSlots({ _: 2 }, [
        (audio.value !== undefined)
          ? {
              name: "content",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("connectFlowAudio.labels.name")), 1),
                    _createElementVNode("p", null, _toDisplayString(audio.value.name), 1)
                  ]),
                  (audioData.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("connectFlowAudio.labels.file")), 1),
                        _createElementVNode("audio", _hoisted_10, [
                          _createElementVNode("source", {
                            src: audioData.value,
                            type: _unref(getAudioFormat)(audio.value.audioFilePath)
                          }, null, 8, _hoisted_11),
                          _cache[1] || (_cache[1] = _createTextVNode(" Your browser does not support the audio element. "))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("connectFlowAudio.labels.status")), 1),
                    _createElementVNode("p", {
                      class: _normalizeClass([[
                  audio.value?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
                    }, _toDisplayString(audio.value?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3)
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024)
    ])
  ]))
}
}

})