import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_6 = { class: "flex flex-col justify-end pb-2.5" }
const _hoisted_7 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import ConnectFlowService from "@/services/ConnectFlowService";
import {
  UiPageHeader,
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiMultiselect,
  UiPanel,
} from "@/components";
import {
  IConnectFlow,
  Permission,
  IDefaultServiceResult,
  IConnectFlowServiceCreateResult,
  UiActions,
  IConnectFlowFormState,
  FormAction,
  ILevel,
  FilterGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";

import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<IConnectFlowFormState>({
  levelId: undefined,
  name: undefined,
  isActive: true,
});

const rules = computed<ValidationArgs<IConnectFlowFormState>>(() => {
  const r: ValidationArgs<IConnectFlowFormState> = {
    levelId: {
      required,
    },
    name: {
      required,
      maxLength: maxLength(500),
    },
    isActive: {
      required,
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IConnectFlowFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (props.action == FormAction.EDIT) {
  const id = parseInt(route.params.id as string);

  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      const { levelId, name, isActive } = response.data;
      formState.id = id;
      formState.levelId = levelId;
      formState.name = name;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => setLoading(false));
}

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewConnectFlowById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ConnectFlowService.update<IDefaultServiceResult, IConnectFlowFormState>(
      id,
      formState,
    )
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ConnectFlowService.create<
      IConnectFlowServiceCreateResult,
      IConnectFlowFormState
    >(formState)
      .then(
        (response: AxiosResponse<IConnectFlowServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
};

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 0,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlow." + props.action + ".title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow." + props.action + ".description")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("connectFlow.labels.level")) + " ", 1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs" }, "*", -1))
              ]),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value,
                errors: _unref(v$).levelId.$errors,
                required: _unref(v$).levelId.required !== undefined,
                disabled: props.action == _unref(FormAction).EDIT
              }, null, 8, ["modelValue", "options", "errors", "required", "disabled"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('connectFlow.labels.name'),
              errors: _unref(v$).name.$errors,
              required: _unref(v$).name.required !== undefined,
              onBlur: _unref(v$).name.$touch
            }, null, 8, ["modelValue", "label", "errors", "required", "onBlur"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: _unref(v$).isActive.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('connectFlow.labels.isActive')
              }, null, 8, ["modelValue", "label"])
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: {
            name: 'ListConnectFlow',
          }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_CONNECT_FLOW)) ||
            (props.action == _unref(FormAction).EDIT &&
              _ctx.$can(_unref(Permission).EDIT_CONNECT_FLOW))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})