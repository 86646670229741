import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 sm:p-6 lg:p-8"
}
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_7 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_8 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_9 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_10 = { class: "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_11 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_12 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_13 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_14 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_15 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_16 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_17 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_18 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_19 = { class: "flex justify-end px-4 sm:px-0" }

import { ref, Ref } from "vue";
import store from "@/store";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import { useRoute, RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { Permission, UiActions, IWord } from "@/definitions";
import { UiButton, UiPageHeader, UiContainerSection } from "@/components";
import { WordService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordDetailPage',
  setup(__props) {

const route = useRoute();

const word: Ref<IWord | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getWord = (id: number) => {
  setLoading(true);
  WordService.get<IWord>(id)
    .then((response: AxiosResponse<IWord>) => {
      word.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getWord(parseInt(route.params.id as string));

return (_ctx: any,_cache: any) => {
  return (word.value !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(UiPageHeader), null, {
          info: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("word.view.title")), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("word.view.description")), 1)
          ]),
          actions: _withCtx(() => [
            (_ctx.$can(_unref(Permission).EDIT_WORD))
              ? (_openBlock(), _createBlock(_unref(RouterLink), {
                  key: 0,
                  to: {
            name: 'EditWordById',
            params: {
              id: word.value?.id,
            },
          },
                  class: "block hover:bg-gray-50"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(UiButton), {
                      variant: "primary",
                      "text-variant": "white",
                      themed: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(UiContainerSection), null, {
            title: _withCtx(() => [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("word.view.section.word.title")), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("word.view.section.word.description")), 1)
            ]),
            content: _withCtx(() => [
              _createElementVNode("dl", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("dt", _hoisted_8, _toDisplayString(_ctx.$t("word.labels.level")), 1),
                  _createElementVNode("dd", _hoisted_9, _toDisplayString(word.value?.level.name), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("dt", _hoisted_11, _toDisplayString(_ctx.$t("word.labels.word")), 1),
                  _createElementVNode("dd", _hoisted_12, _toDisplayString(word.value.word), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.$t("word.labels.callParticipant")), 1),
                  _createElementVNode("dd", _hoisted_15, _toDisplayString(_ctx.$t(`word.participant.${word.value.callParticipant}`)), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("dt", _hoisted_17, _toDisplayString(_ctx.$t("word.isActive.label")), 1),
                  _createElementVNode("dd", _hoisted_18, _toDisplayString(_ctx.$t(`word.isActive.options.${word.value.isActive}`)), 1)
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-500",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListWord' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})