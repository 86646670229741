import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden" }

import Typebot from "@typebot.io/js/dist/web";
import {
  computed,
  onMounted,
  onUnmounted,
  PropType,
  watch,
  ComputedRef,
} from "vue";
import {
  CallPlayerGetters,
  ICallTimelineItem,
  IUserIntegration,
} from "@/definitions";
import { useI18n } from "vue-i18n";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallChatBot',
  props: {
  chat: {
    type: Object as PropType<IUserIntegration>,
    required: true,
  },
},
  setup(__props) {



const { t } = useI18n();

const timeline: ComputedRef<ICallTimelineItem[]> = computed(
  () => store.getters[CallPlayerGetters.TIMELINE],
);

const base64Timeline = computed(() => {
  const transcript: string[] = [];
  timeline.value.forEach((itemTimeline) => {
    const participant = t(
      `call.view.participant.${itemTimeline.participant.toLowerCase()}`,
    );
    transcript.push(`${participant}: ${itemTimeline.transcript}`);
  });

  return btoa(transcript.join("\n"));
});

function startChat() {
  Typebot.initBubble({
    typebot: "bot-onni-plugin-mobicall",
    apiHost: "https://bot2.plataformareach.com.br",
    previewMessage: {
      message: "Você tem dúvidas ou precisa de ajuda? Pergunte agora!",
      autoShowDelay: 2500,
      avatarUrl:
        "https://api2.mobcall.com/video/api/2/a/meeting/favicon-32.png",
    },
    prefilledVariables: {
      Transcripts: base64Timeline.value,
    },
    theme: {
      button: { backgroundColor: "#5ac2ad", iconColor: "#FFFFFF" },
      chatWindow: { backgroundColor: "#ffffff" },
    },
  });
}

onMounted(() => {
  if (base64Timeline.value.length > 0) {
    startChat();
  }
});
watch(
  () => base64Timeline.value,
  () => {
    if (base64Timeline.value.length > 0) {
      startChat();
    }
  },
);
onUnmounted(() => {
  Typebot.unmount();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, "Chatbot"))
}
}

})