import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "hidden sm:block flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-gray-700"
}
const _hoisted_4 = {
  key: 1,
  class: "text-sm text-gray-700"
}
const _hoisted_5 = {
  class: "inline-flex rounded-md shadow-sm -space-x-px",
  "aria-label": "Pagination"
}
const _hoisted_6 = ["onClick"]

import { computed, PropType } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { VueI18nTranslation } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPagination',
  props: {
  modelValue: {
    type: Number,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
  perPage: {
    type: Number,
    required: true,
  },
  i18n: Function as PropType<VueI18nTranslation>,
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const pageCount = computed(() => {
  return Math.ceil(props.count / props.perPage);
});

const pages = computed(() => {
  const response: number[] = [];
  const current = props.modelValue;

  let start = current - 2 <= 0 ? 1 : current - 2;
  let end = current + 2 < pageCount.value ? current + 2 : pageCount.value;

  const diff = end - start;
  if (diff < 4 && end + (4 - diff) < pageCount.value) {
    end = end + (4 - diff);
  } else if (diff < 4 && start - (4 - diff) > 0) {
    start = start - (4 - diff);
  }

  for (let i = start; i <= end; i++) {
    response.push(i);
  }

  return response;
});

const paginationLabelData = computed(() => {
  let start = 1;
  let end = props.modelValue * props.perPage;
  const total = props.count;

  if (props.modelValue >= 1)
    start = props.modelValue * props.perPage - props.perPage + 1;

  if (props.modelValue == pageCount.value) end = props.count;

  return {
    start,
    end,
    total,
  };
});

const emit = __emit;
function goTo(page: number) {
  if (props.modelValue !== page) {
    emit("update:modelValue", page);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.count > 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.i18n?.("pagination.label", paginationLabelData.value)), 1))
        : _createCommentVNode("", true),
      (__props.count === 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(__props.i18n?.("pagination.labelOnePage", {
            total: __props.count,
          })), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("nav", _hoisted_5, [
        (props.modelValue > 1)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.modelValue - 1))),
              class: "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Previous", -1)),
              _createVNode(_unref(ChevronLeftIcon), {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pages.value, (page) => {
          return (_openBlock(), _createElementBlock("span", {
            key: `page${page}`,
            onClick: ($event: any) => (goTo(page)),
            "aria-current": "page",
            class: _normalizeClass(["relative inline-flex items-center px-4 py-2 border text-sm font-medium", 
              page === props.modelValue
                ? 'theme-border-color theme-color z-3'
                : 'border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer z-2'
            ])
          }, _toDisplayString(page), 11, _hoisted_6))
        }), 128)),
        (props.modelValue < pageCount.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo(props.modelValue + 1))),
              class: "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Next", -1)),
              _createVNode(_unref(ChevronRightIcon), {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})