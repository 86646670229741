import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden sm:block" }
const _hoisted_2 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_3 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_4 = {
  key: 0,
  class: "sm:col-span-4"
}
const _hoisted_5 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_6 = {
  key: 1,
  class: "sm:col-span-4"
}
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "sm:col-span-4" }
const _hoisted_9 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_10 = { class: "sm:col-span-4" }
const _hoisted_11 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_12 = { class: "flex flex-col gap-0.5 py-1" }
const _hoisted_13 = { class: "flex flex-row gap-3" }
const _hoisted_14 = { class: "text-sm text-gray-600" }
const _hoisted_15 = { class: "sm:col-span-4" }
const _hoisted_16 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_17 = { class: "sm:col-span-4" }
const _hoisted_18 = { class: "block text-sm font-medium leading-6 text-gray-900 text-left" }
const _hoisted_19 = { class: "flex items-center justify-center mb-1 p-0 pt-1 rounded-md shadow bg-gray-100" }
const _hoisted_20 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_21 = { class: "sm:col-span-4" }
const _hoisted_22 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_23 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

import { ref, Ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { AxiosResponse } from "axios";
import { toastServiceError } from "@/utils/notification";
import { UiButton, UiMultiselect, UiRightPanel } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  CallActions,
  CallGetters,
  CallStatus,
  CallType,
  FilterGetters,
  ICallFilter,
  ILevel,
  IMultiSelectOption,
  ISelectOptionsServiceGetWordsParams,
  ParticipantType,
  UiActions,
} from "@/definitions";
import { CallService, SelectOptionsService } from "@/services";
import { useRoute } from "vue-router";
import {
  ISelectOptionsServiceGetUsersResult,
  ISelectOptionsServiceGetUsersLabelsResult,
  ISelectOptionsServiceGetWordsResult,
} from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallListFilters',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();

const filtersOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const words: Ref<ISelectOptionsServiceGetWordsResult[]> = ref([]);

const getWords = (participant: string) => {
  const params: ISelectOptionsServiceGetWordsParams = {
    level: level.value.id,
    call_participant: participant,
  };

  setLoading(true);
  SelectOptionsService.getWords({
    params,
  })
    .then((response) => {
      words.value = response.data;
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

const filter: Ref<ICallFilter> = ref({
  type: null,
  status: null,
  userLabel: null,
  media: null,
  numberClient: null,
  user: null,
  word: null,
});

const selectedParticipant = ref<ParticipantType | null>(null);

const callParticipantOptions = [
  { label: t("word.participant.ALL"), value: ParticipantType.All },
  { label: t("word.participant.AGENT"), value: ParticipantType.Agent },
  { label: t("word.participant.CLIENT"), value: ParticipantType.Client },
];

const filterCallParticipants = (value: ParticipantType | null) => {
  selectedParticipant.value = value || ParticipantType.All;
  filter.value.word = null;
  getWords(value || ParticipantType.All);
};

const getButtonVariant = (value: ParticipantType | null) => {
  if (selectedParticipant.value === value) {
    if (value === ParticipantType.Agent) {
      return "purple-200";
    }
    if (value === ParticipantType.Client) {
      return "blue-200";
    }
    if (value === ParticipantType.All) {
      return "gray-300";
    }
  }
  return "neutral";
};

const getTextVariant = (value: ParticipantType | null) => {
  if (selectedParticipant.value === value) {
    if (value === ParticipantType.Agent) {
      return "purple-700";
    }
    if (value === ParticipantType.Client) {
      return "blue-700";
    }
    if (value === ParticipantType.All) {
      return "gray-700";
    }
  }
  return "gray-700";
};

const storeFilters = computed<ICallFilter>(
  () => store.getters[CallGetters.FILTER],
);

function applyFilters() {
  store.dispatch(CallActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(CallActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.type = storeFilters.value.type;
  filter.value.status = storeFilters.value.status;
  filter.value.userLabel = storeFilters.value.userLabel;
  filter.value.media = storeFilters.value.media;
  filter.value.numberClient = storeFilters.value.numberClient;
  filter.value.user = storeFilters.value.user;
  filter.value.word = storeFilters.value.word;
}

watch(filtersOpened, () => {
  refreshLocalFilter();
});

watch(
  () => filter.value.word?.callParticipant,
  (newParticipant) => {
    if (newParticipant !== undefined && newParticipant !== null) {
      getWords(newParticipant);
    }
  },
  { immediate: true },
);

const statusFilterOptions = [
  {
    label: t(`call.status.${CallStatus.ANSWERED}`),
    value: CallStatus.ANSWERED,
  },
  {
    label: t(`call.status.${CallStatus.BUSY}`),
    value: CallStatus.BUSY,
  },
  {
    label: t(`call.status.${CallStatus.NO_ANSWER}`),
    value: CallStatus.NO_ANSWER,
  },
  {
    label: t(`call.status.${CallStatus.DITCHED}`),
    value: CallStatus.DITCHED,
  },
];

const typeFilterOptions = [
  {
    label: t(`call.type.${CallType.INBOUND}`),
    value: CallType.INBOUND,
  },
  {
    label: t(`call.type.${CallType.OUTBOUND}`),
    value: CallType.OUTBOUND,
  },
  {
    label: t(`call.type.${CallType.INTERNAL}`),
    value: CallType.INTERNAL,
  },
];

const userLabelOptions: Ref<ISelectOptionsServiceGetUsersLabelsResult[]> = ref(
  [],
);
const getUserLabels = () => {
  if (level.value) {
    SelectOptionsService.getUsersLabels({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
  }
};

const agentOptions: Ref<ISelectOptionsServiceGetUsersResult[]> = ref([]);

const getAgents = () => {
  SelectOptionsService.getUsers({
    params: {
      level: level.value.id,
    },
  }).then((response) => {
    agentOptions.value = response.data;
  }, toastServiceError);
};

const mediaFilterOptions: Ref<IMultiSelectOption[]> = ref([]);
function getMedias() {
  if (level.value) {
    CallService.getMedias<IMultiSelectOption[]>({
      params: {
        level: level.value.id,
      },
    }).then((response: AxiosResponse<IMultiSelectOption[]>) => {
      mediaFilterOptions.value = response.data;
    }, toastServiceError);
  }
}

const isCallTypeRoute = computed(() => {
  return Object.values(CallType)
    .map((value) => value.toLowerCase())
    .some((callTypeValue) => route.path.toLowerCase().includes(callTypeValue));
});

const isCallStatusRoute = computed(() => {
  return Object.values(CallStatus)
    .map((value) => value.toLowerCase())
    .some((callStatusValue) =>
      route.path.toLowerCase().includes(callStatusValue),
    );
});

const getClientNumbers = async (query: string) => {
  const params = {
    search: query,
  };
  const { data } = await CallService.getClientNumbers({ params });
  return data;
};

watch(level, () => {
  store.dispatch(CallActions.CLEAR_FILTER, "userLabel");
  getUserLabels();
});

onMounted(() => {
  getMedias();
  getUserLabels();
  getAgents();
  getWords(ParticipantType.All);
  filterCallParticipants(ParticipantType.All);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filtersOpened.value,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((filtersOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filtersOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "h-5 w-5 text-gray-400 mr-0 sm:mr-3",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!isCallTypeRoute.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("call.filters.type")), 1),
                _createVNode(_unref(UiMultiselect), {
                  name: "filters_type",
                  modelValue: filter.value.type,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.type) = $event)),
                  "can-clear": true,
                  options: typeFilterOptions,
                  placeholder: _unref(t)('core.options.All')
                }, null, 8, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          (!isCallStatusRoute.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("call.filters.status")), 1),
                _createVNode(_unref(UiMultiselect), {
                  name: "filters_status",
                  modelValue: filter.value.status,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.status) = $event)),
                  "can-clear": true,
                  options: statusFilterOptions,
                  placeholder: _unref(t)('core.options.All')
                }, null, 8, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("call.filters.numberClient")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filters_number_client",
              modelValue: filter.value.numberClient,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.numberClient) = $event)),
              searchable: "async",
              "can-clear": true,
              minCharMessage: true,
              placeholder: _ctx.$t('core.multiselect.typeSearch'),
              options: getClientNumbers
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("call.filters.nameAgent")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filters_user",
              modelValue: filter.value.user,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.user) = $event)),
              label: "name",
              "value-prop": "id",
              searchable: "local",
              "can-clear": true,
              placeholder: _ctx.$t('core.multiselect.typeSearch'),
              options: agentOptions.value
            }, {
              "option-label": _withCtx(({ option }) => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(option.name), 1),
                    _createElementVNode("span", {
                      class: _normalizeClass([
                        option.isActive
                          ? 'bg-green-100 text-green-700'
                          : 'bg-red-100 text-red-700',
                        'px-2 py-0.5 rounded text-xs border',
                      ])
                    }, _toDisplayString(option.isActive
                          ? _ctx.$t("core.options.Active")
                          : _ctx.$t("core.options.Inactive")), 3)
                  ]),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(option.email), 1)
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "options"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("report.call.filters.userLabel")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_userLabel",
              modelValue: filter.value.userLabel,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.userLabel) = $event)),
              label: "label",
              "value-prop": "id",
              options: userLabelOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("call.filters.words")), 1),
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(callParticipantOptions, (option, index) => {
                return _createVNode(_unref(UiButton), {
                  key: option.value,
                  variant: getButtonVariant(option.value),
                  "text-variant": getTextVariant(option.value),
                  themed: true,
                  size: 'md',
                  shape: 'rounded',
                  shadow: 'sm',
                  id: `participant-button-${index}`,
                  class: "w-full text-center mx-0 flex-grow",
                  onClick: ($event: any) => (filterCallParticipants(option.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1032, ["variant", "text-variant", "id", "onClick"])
              }), 64))
            ]),
            _createVNode(_unref(UiMultiselect), {
              class: "mb-4",
              name: "filters_word",
              modelValue: filter.value.word,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filter.value.word) = $event)),
              label: "word",
              "value-prop": "id",
              options: words.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("call.filters.media")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filters_media",
              modelValue: filter.value.media,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((filter.value.media) = $event)),
              "can-clear": true,
              options: mediaFilterOptions.value,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})