import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white/40 w-full p-2 pb-0 flex items-center justify-center font-medium gap-x-2 ring-1 ring-black ring-opacity-5"
}
const _hoisted_2 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900 mb-5" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row justify-between" }
const _hoisted_5 = { class: "md:flex flex-wrap items-center gap-2 hidden" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "w-full lg:w-64 md:mt-3 lg:mt-0" }
const _hoisted_9 = { class: "w-full mt-5" }
const _hoisted_10 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_11 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_12 = {
  class: "flex flex-1",
  action: "#",
  method: "GET"
}
const _hoisted_13 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_14 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_15 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_16 = {
  role: "list",
  class: ""
}
const _hoisted_17 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import moment from "moment";
import { DocumentDownloadIcon } from "@heroicons/vue/solid";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { useI18n } from "vue-i18n";
import {
  UiPageHeader,
  UiDatePicker,
  UiBadge,
  UiListSearch,
  UiPagination,
  UiSortMenuBadge,
  UiButton,
} from "@/components";
import CallService from "@/services/CallService";
import {
  IFilterItem,
  ILevel,
  ICall,
  ISortMenuItem,
  CallType,
  CallStatus,
  CallActions,
  CallGetters,
  FilterGetters,
  FilterActions,
  UiActions,
  Permission,
} from "@/definitions";
import {
  CallListFilters,
  CallListItem,
  CallListSort,
} from "@/views/pages/call/components";
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
} from "@heroicons/vue/outline";
import Analytics from "@/utils/analytics";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'CallPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

// Pagination
const count = ref(0);
const currentPage = computed<number>({
  get: () => store.getters[CallGetters.PAGE],
  set: (page: number) => store.dispatch(CallActions.SET_PAGE, page),
});

//Filters
const search = computed<string>({
  get: () => store.getters[CallGetters.SEARCH],
  set: (search: string) => store.dispatch(CallActions.SET_SEARCH, search),
});
const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[CallGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(CallActions.SET_SORT, sort),
});
const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const type = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].type !== null &&
  store.getters[CallGetters.FILTER].type.value !== null
    ? store.getters[CallGetters.FILTER].type.value.toString()
    : null,
);
const status = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].status !== null &&
  store.getters[CallGetters.FILTER].status.value !== null
    ? store.getters[CallGetters.FILTER].status.value.toString()
    : null,
);
const userLabel = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].userLabel !== null &&
  store.getters[CallGetters.FILTER].userLabel.id !== null
    ? store.getters[CallGetters.FILTER].userLabel.id.toString()
    : null,
);
const media = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].media !== null &&
  store.getters[CallGetters.FILTER].media.value !== null
    ? store.getters[CallGetters.FILTER].media.value.toString()
    : null,
);
const numberClient = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].numberClient !== null &&
  store.getters[CallGetters.FILTER].numberClient.value !== null
    ? store.getters[CallGetters.FILTER].numberClient.value.toString()
    : null,
);
const word = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].word !== null
    ? store.getters[CallGetters.FILTER].word
    : null,
);

const user = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].user !== null &&
  store.getters[CallGetters.FILTER].user !== null
    ? store.getters[CallGetters.FILTER].user.id.toString()
    : null,
);

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[CallGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (route.query.clearStateOnSetup) {
  currentPage.value = 1;
  router.replace({ query: {} });
}
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};
function handleRemoveFilter(field: string) {
  store.dispatch(CallActions.CLEAR_FILTER, field);
}

const tabFilter = computed((): IFilterItem | null => {
  if (route.params.tab !== undefined) {
    const filter = route.params.tab.toString().toUpperCase() as CallStatus;
    if (Object.keys(CallType).includes(filter)) {
      return {
        field: "type",
        label: "",
        value: filter,
      };
    }

    if (Object.values(CallStatus).includes(filter)) {
      return {
        field: "status",
        label: "",
        value: filter,
      };
    }
  }

  return null;
});

const calls: Ref<ICall[]> = ref([]);
const prepareCallParams = () => {
  if (!level.value || dateRange.value.length < 2) return null;

  const params = {
    search: search.value,
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: type.value,
    status: status.value,
    userLabel: userLabel.value,
    media: media.value,
    numberClient: numberClient.value,
    word: word.value,
    user: user.value,
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
  };

  if (tabFilter.value !== null) {
    if (tabFilter.value?.field === "type") {
      params.type = tabFilter.value.value as string;
    }
    if (tabFilter.value?.field === "status") {
      params.status = tabFilter.value.value as string;
    }
  }

  return params;
};

const getCalls = () => {
  const params = prepareCallParams();
  if (!params) return;

  params.page = currentPage.value;
  params.per_page = perPage;

  setLoading(true);

  CallService.getAll({
    params,
  })
    .then((response) => {
      calls.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

const formatFilename = (startDate: string, endDate: string) => {
  const formattedStartDate = moment(startDate).format("DD/MM/YYYY");
  const formattedEndDate = moment(endDate).format("DD/MM/YYYY");
  return `${t("dashboard.report.call")} - ${formattedStartDate} - ${formattedEndDate}.csv`;
};

const getCsv = async () => {
  const params = prepareCallParams();
  if (!params) return;

  setLoading(true);
  Analytics.trackingAction(`CallCSVDownload`).then();
  CallService.getCsvReport({ params })
    .then((response) => {
      const filename = formatFilename(params.startDate, params.endDate);

      const csvString =
        typeof response.data === "string"
          ? response.data
          : JSON.stringify(response.data);
      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

getCalls();

function applyTabFilter(value: string) {
  router.push({
    name: "CallList",
    params: {
      tab: value.toLowerCase(),
    },
  });
}

function showAllCalls() {
  router.push({ name: "CallList" });
}

function bulletCallStatusColor(status: string) {
  switch (status) {
    case CallStatus.ANSWERED:
      return "bg-green-600";
    case CallStatus.BUSY:
      return "bg-yellow-500";
    case CallStatus.NO_ANSWER:
      return "bg-red-500";
    default:
      return "bg-gray-600";
  }
}

watch(
  [
    search,
    level,
    dateRange,
    orderBy,
    status,
    userLabel,
    type,
    media,
    numberClient,
    user,
    word,
    tabFilter,
  ],
  () => {
    if (currentPage.value === 1) getCalls();
    else currentPage.value = 1;
  },
);

watch([currentPage], () => {
  getCalls();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$can(_unref(Permission).VIEW_CALL_REPORT))
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _unref(route).name === 'CallList'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallList' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("dashboard.calls")), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _unref(route).name === 'CallReport'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallReport' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("report.call.title")), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(UiPageHeader), null, {
        info: _withCtx(() => [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("dashboard.calls")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showAllCalls())),
            class: _normalizeClass([tabFilter.value === null ? 'bg-white/50' : '', "text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50"])
          }, [
            _createVNode(_unref(PhoneIcon), {
              class: "h-4 w-4 mr-1 text-gray-500",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("call.filters.allTypesAndStatus")), 1)
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CallType), (type) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `filterBar${type}`,
              onClick: ($event: any) => (applyTabFilter(type)),
              class: _normalizeClass(["text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50", 
              tabFilter.value !== null && tabFilter.value?.value === type
                ? 'bg-white/50'
                : ''
            ])
            }, [
              (type === _unref(CallType).INBOUND)
                ? (_openBlock(), _createBlock(_unref(PhoneIncomingIcon), {
                    key: 0,
                    class: "h-4 w-4 mr-1 text-cyan-500",
                    "aria-hidden": "true"
                  }))
                : (type === _unref(CallType).OUTBOUND)
                  ? (_openBlock(), _createBlock(_unref(PhoneOutgoingIcon), {
                      key: 1,
                      class: "h-4 w-4 mr-1 text-orange-500",
                      "aria-hidden": "true"
                    }))
                  : (type === _unref(CallType).INTERNAL)
                    ? (_openBlock(), _createBlock(_unref(PhoneIcon), {
                        key: 2,
                        class: "h-4 w-4 mr-1 text-gray-500",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.type.${type}`)), 1)
            ], 10, _hoisted_6))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(CallStatus)), (status) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: `filterBar${status}`
            }, [
              (status !== _unref(CallStatus).ON_PROGRESS)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: ($event: any) => (applyTabFilter(status)),
                    class: _normalizeClass(["text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50", 
              tabFilter.value !== null && tabFilter.value?.value === status
                ? 'bg-white/50'
                : ''
            ])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex rounded-full h-2.5 w-2.5 mr-1", bulletCallStatusColor(status)])
                    }, null, 2),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.status.${status}`)), 1)
                  ], 10, _hoisted_7))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(UiDatePicker), {
            modelValue: dateRange.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dateRange).value = $event)),
            range: "",
            multiCalendars: ""
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("form", _hoisted_12, [
              _createVNode(_unref(UiListSearch), {
                modelValue: search.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((search).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(UiButton), {
                variant: "white",
                "text-variant": "primary",
                themed: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (getCsv()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DocumentDownloadIcon), {
                    class: "-ml-1 mr-2 h-5 w-5",
                    "aria-hidden": "true"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.export")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(CallListSort), {
                modelValue: sortMenuSelected.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((sortMenuSelected).value = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(CallListFilters))
            ])
          ])
        ]),
        (sortMenuSelected.value.length || activeFilters.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
                return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                  key: selected.key,
                  item: selected,
                  onRemove: ($event: any) => (handleRemoveSortClick(selected))
                }, null, 8, ["item", "onRemove"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
                return (_openBlock(), _createBlock(_unref(UiBadge), {
                  key: filter,
                  removable: "",
                  onRemove: ($event: any) => (handleRemoveFilter(filter.field))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`call.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onRemove"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calls.value, (call) => {
            return (_openBlock(), _createBlock(_unref(CallListItem), {
              key: call.id,
              "model-value": call
            }, null, 8, ["model-value"]))
          }), 128)),
          (!calls.value.length)
            ? (_openBlock(), _createElementBlock("li", _hoisted_17, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1))
            : _createCommentVNode("", true)
        ]),
        (calls.value.length)
          ? (_openBlock(), _createBlock(_unref(UiPagination), {
              key: 1,
              modelValue: currentPage.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((currentPage).value = $event)),
              count: count.value,
              perPage: perPage,
              i18n: _ctx.$t,
              class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
            }, null, 8, ["modelValue", "count", "i18n"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})