import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bg-gray-100 shadow-sm rounded-md p-6 ring-1 ring-black ring-opacity-5 flex flex-col h-[75vh]" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = { class: "text-lg font-bold text-gray-900" }
const _hoisted_4 = { class: "space-y-3 flex-1 overflow-y-auto" }
const _hoisted_5 = { class: "font-semibold text-gray-700 min-w-[30%] max-w-[30%]" }
const _hoisted_6 = { class: "flex items-center flex-1" }
const _hoisted_7 = { class: "w-full h-2 bg-gray-300 rounded-full mr-3" }
const _hoisted_8 = { class: "font-semibold text-gray-700 text-sm min-w-[40px] text-right" }

import { IParticipantWithSpeechPercentage } from "@/definitions";
import { useI18n } from "vue-i18n";
import { SparklesIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpeakerInsights',
  props: {
  participants: {
    type: Array as () => IParticipantWithSpeechPercentage[],
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const getParticipantColor = (index: number) => {
  const colors = ["#7c3aed", "#14b8a6", "#0ea5e9", "#6b7280"];
  return colors[index % colors.length];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(SparklesIcon), {
        class: "mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]",
        "aria-hidden": "true"
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_unref(t)("meeting.view.insights.title")), 1)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.participants, (participant, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: participant.id,
          class: "flex items-center justify-between"
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(participant.name), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "h-2 rounded-full",
                style: _normalizeStyle({
                width: participant.speechPercentage + '%',
                backgroundColor: getParticipantColor(index),
              })
              }, null, 4)
            ]),
            _createElementVNode("span", _hoisted_8, _toDisplayString(participant.speechPercentage) + "%", 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})