import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 pt-0 sm:p-6 sm:pt-0 lg:p-8 lg:pt-0"
}
const _hoisted_2 = { class: "flex flex-row-reverse" }

import { computed, ComputedRef, onMounted, WritableComputedRef } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { AxiosResponse } from "axios";
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import CallService from "@/services/CallService";
import { toastServiceError } from "@/utils/notification";
import {
  AuthGetters,
  CallPlayerGetters,
  CallPlayerMutations,
  ICall,
  ICallTimelineItem,
  IUserIntegration,
  IWordsByPersona,
  UiActions,
  UiGetters,
} from "@/definitions";
import CallDetails from "@/views/pages/call/components/CallDetails.vue";
import CallPlayer from "@/views/pages/call/components/CallPlayer.vue";
import CallTags from "@/views/pages/call/components/CallTags.vue";
import CallWordsInsight from "@/views/pages/call/components/CallWordsInsight.vue";
import CallSurveyAnswers from "@/views/pages/call/components/CallSurveyAnswers.vue";
import CallChatBot from "@/views/pages/call/components/CallChatBot.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetailPage',
  setup(__props) {

const route = useRoute();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};
const isLoading = computed(() => store.getters[UiGetters.IS_LOADING]);

const chatBotIntegration: ComputedRef<IUserIntegration> =
  computed<IUserIntegration>(
    () => store.getters[AuthGetters.CHAT_BOT_INTEGRATION],
  );

const call: WritableComputedRef<ICall> = computed({
  get: () => store.getters[CallPlayerGetters.CALL],
  set: (value: ICall) => {
    store.commit(CallPlayerMutations.SET_CALL, value);
  },
});

const getCall = (id: number) => {
  setLoading(true);
  CallService.get<ICall>(id)
    .then((response: AxiosResponse<ICall>) => {
      call.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const timeline: WritableComputedRef<ICallTimelineItem[]> = computed({
  get: () => store.getters[CallPlayerGetters.TIMELINE],
  set: (value: ICallTimelineItem[]) => {
    store.commit(CallPlayerMutations.SET_TIMELINE, value);
  },
});
const getTimeline = (id: number) => {
  CallService.getTimeline<ICallTimelineItem[]>(id).then(
    (response: AxiosResponse<ICallTimelineItem[]>) => {
      timeline.value = response.data;
    },
    toastServiceError,
  );
};

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, value);
  },
});
const getWords = (id: number) => {
  CallService.getWords<IWordsByPersona>(id).then(
    (response: AxiosResponse<IWordsByPersona>) => {
      words.value = response.data;
    },
    toastServiceError,
  );
};

onMounted(() => {
  getCall(parseInt(route.params.id as string));
  getTimeline(parseInt(route.params.id as string));
  getWords(parseInt(route.params.id as string));
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (call.value !== null && !isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "text-primary hover:text-primary-dark right-0 p-1.5 w-fit m-1.5 flex flex-row items-center",
            to: {
          name: 'CallList',
        }
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(ChevronLeftIcon), { class: "h-5 w-6" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Back")), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(CallDetails),
        _createVNode(CallTags),
        _createVNode(CallPlayer, { class: "mt-5" }),
        _createVNode(CallWordsInsight),
        (call.value?.survey)
          ? (_openBlock(), _createBlock(CallSurveyAnswers, {
              key: 0,
              surveyAnswers: call.value.survey
            }, null, 8, ["surveyAnswers"]))
          : _createCommentVNode("", true),
        (chatBotIntegration.value)
          ? (_openBlock(), _createBlock(CallChatBot, {
              key: 1,
              chat: chatBotIntegration.value
            }, null, 8, ["chat"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})