import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_6 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-4" }
const _hoisted_8 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_9 = { role: "list" }
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toast, toastServiceError } from "@/utils/notification";
import UserService from "@/services/UserService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
} from "@/components";
import {
  UserListItem,
  UserListSort,
  UserListFilters,
} from "@/views/pages/user/components";
import {
  IUser,
  Permission,
  IUserServiceGetAllParams,
  UserGetters,
  UserActions,
  IUserFilter,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { UsersIcon, DocumentDownloadIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const users: Ref<IUser[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[UserGetters.SEARCH],
  set: (search: string) => store.dispatch(UserActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[UserGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(UserActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[UserGetters.PAGE],
  set: (page: number) => store.dispatch(UserActions.SET_PAGE, page),
});

const filter: IUserFilter = store.getters[UserGetters.FILTER];

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[UserGetters.ACTIVE_FILTERS],
);

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getParams = (): IUserServiceGetAllParams => {
  return {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    roles: filter.roles.map((r) => r.id),
    search: search.value,
  };
};

const getUsers = () => {
  const params = getParams();

  setLoading(true);
  UserService.getAll<IUser[]>({
    params,
  })
    .then((response: AxiosResponse<IUser[]>) => {
      users.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const getCsv = () => {
  const params = getParams();

  setLoading(true);
  UserService.getCsvReport<Array<IUser>>({
    params,
  })
    .then((response) => {
      const dateNow = moment(Date.now()).format("DD/MM/YYYY HH:mm:ss");
      const filename = `${t("dashboard.report.users")} - ${dateNow}.csv`;

      const csvString =
        typeof response.data === "string"
          ? response.data
          : JSON.stringify(response.data);
      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getUsers();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getUsers();
});

getUsers();

const setIsActive = async (item: IUser, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getUsers();
  };

  (isActive ? UserService.activate : UserService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) =>
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);

const handleRemoveStatusFilterClick = (field: string) => {
  store.dispatch(UserActions.CLEAR_FILTER, field);
};

const handleActivateClick = (item: IUser) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IUser) => {
  setIsActive(item, false);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(UsersIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Users")), 1)
        ])
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_USER))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateUser',
          } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
              placeholder: "Pesquisar usuário"
            }, null, 8, ["modelValue"])
          ], 32),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "primary",
              themed: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (getCsv()))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DocumentDownloadIcon), {
                  class: "-ml-1 mr-2 h-5 w-5",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.export")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(UserListSort), {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(UserListFilters))
          ])
        ])
      ]),
      (sortMenuSelected.value.length || activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (item) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                removable: "",
                key: `activeFilter${item.field}`,
                onRemove: ($event: any) => (handleRemoveStatusFilterClick(item.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`user.filters.${item.field}.label`)) + ": " + _toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
          return (_openBlock(), _createBlock(_unref(UserListItem), {
            key: user.id,
            "model-value": user,
            onActivate: handleActivateClick,
            onInactivate: handleInactivateClick
          }, null, 8, ["model-value"]))
        }), 128)),
        (!users.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1))
          : _createCommentVNode("", true)
      ]),
      (users.value.length)
        ? (_openBlock(), _createBlock(_unref(UiPagination), {
            key: 1,
            modelValue: currentPage.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((currentPage).value = $event)),
            count: count.value,
            perPage: perPage,
            i18n: _ctx.$t,
            class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
          }, null, 8, ["modelValue", "count", "i18n"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})