import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_6 = {
  class: "flex flex-1",
  action: "#",
  method: "GET"
}
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_8 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm"
}
const _hoisted_9 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_10 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_11 = { class: "px-4 py-4 sm:px-6" }

import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { QueueService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
} from "@/components";
import {
  QueueListItem,
  QueueListSort,
  QueueListFilters,
} from "@/views/pages/queue/components";
import {
  IQueue,
  Permission,
  QueueGetters,
  QueueActions,
  IQueueFilter,
  IQueueServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { MenuAlt2Icon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'QueuePage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const queues: Ref<IQueue[]> = ref([]);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const search = computed<string>({
  get: () => store.getters[QueueGetters.SEARCH],
  set: (search: string) => store.dispatch(QueueActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[QueueGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(QueueActions.SET_SORT, sort),
});

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[QueueGetters.PAGE],
  set: (page: number) => store.dispatch(QueueActions.SET_PAGE, page),
});

const filter: IQueueFilter = store.getters[QueueGetters.FILTER];

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getQueues = () => {
  const params: IQueueServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive as boolean) : undefined,
    search: search.value,
  };

  setLoading(true);
  QueueService.getAll<Array<IQueue>>({
    params,
  })
    .then((response: AxiosResponse<IQueue[]>) => {
      queues.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getQueues();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getQueues();
});

getQueues();

const setIsActive = async (item: IQueue, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getQueues();
  };

  (isActive ? QueueService.activate : QueueService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveStatusFilterClick = () => {
  store.dispatch(QueueActions.CLEAR_FILTER, "isActive");
};

const handleActivateClick = (item: IQueue) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IQueue) => {
  setIsActive(item, false);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(MenuAlt2Icon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Queues")), 1)
        ])
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_QUEUE))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateQueue',
          } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", _hoisted_6, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(QueueListSort), {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(QueueListFilters))
          ])
        ])
      ]),
      (sortMenuSelected.value.length || _unref(filter).isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_unref(filter).isActive)
              ? (_openBlock(), _createBlock(_unref(UiBadge), {
                  key: 0,
                  removable: "",
                  onRemove: handleRemoveStatusFilterClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("queue.filters.isActive.label")) + ": ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass([[
              _unref(filter).isActive
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800',
            ], "rounded-full ml-1 px-1 text-xs"])
                    }, _toDisplayString(_unref(filter).isActive
                ? _ctx.$t("queue.filters.isActive.options.actives")
                : _ctx.$t("queue.filters.isActive.options.inactives")), 3)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(queues.value, (queue) => {
          return (_openBlock(), _createBlock(_unref(QueueListItem), {
            key: queue.id,
            "model-value": queue,
            onActivate: handleActivateClick,
            onInactivate: handleInactivateClick
          }, null, 8, ["model-value"]))
        }), 128)),
        (!queues.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})