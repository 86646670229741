import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50" }
const _hoisted_2 = { class: "px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "sr-only" }
const _hoisted_9 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_10 = { class: "sm:flex" }
const _hoisted_11 = { class: "flex items-center text-sm text-gray-500 mr-4" }
const _hoisted_12 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
}
const _hoisted_13 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_14 = ["datetime"]

import { computed, PropType } from "vue";
import { IUserLabel, Permission } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { CalendarIcon, FolderIcon, UsersIcon } from "@heroicons/vue/solid";
import { UiButton } from "@/components";
import {
  CheckCircleIcon,
  PencilAltIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelListItem',
  props: {
  modelValue: {
    type: Object as PropType<IUserLabel>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER_LABEL) ? 'RouterLink' : 'span'), {
            to: {
              name: 'ViewUserLabelById',
              params: {
                id: __props.modelValue.id,
              },
            },
            class: "truncate text-md font-medium text-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.modelValue.label), 1)
            ]),
            _: 1
          }, 8, ["to"])),
          _createElementVNode("span", {
            class: _normalizeClass([[
              __props.modelValue.isActive
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800',
            ], "inline-flex ml-2 rounded-full px-2 text-xs font-semibold leading-5"])
          }, _toDisplayString(__props.modelValue.isActive
                ? _ctx.$t("core.options.Active")
                : _ctx.$t("core.options.Inactive")), 3)
        ]),
        (_ctx.$can(_unref(Permission).EDIT_USER_LABEL))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (__props.modelValue.isActive)
                ? _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                    key: 0,
                    size: "",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "danger",
                    shadow: "none",
                    themed: "",
                    class: "border-transparent focus:ring-danger shadow-none text-xs",
                    onClick: handleInactivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("core.actions.inactivate")), 1),
                      _createVNode(_unref(XCircleIcon), {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
                  ])
                : _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                    key: 1,
                    size: "",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "success",
                    shadow: "none",
                    themed: "",
                    class: "border-transparent focus:ring-success shadow-none text-xs",
                    onClick: handleActivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("core.actions.Activate")), 1),
                      _createVNode(_unref(CheckCircleIcon), {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Activate')]
                  ]),
              _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                to: {
              name: 'EditUserLabelById',
              params: {
                id: __props.modelValue.id,
              },
            }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("core.actions.Edit")), 1),
                  _createVNode(_unref(PencilAltIcon), {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1
              }, 8, ["to"])), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, [
            _createVNode(_unref(FolderIcon), {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(__props.modelValue.level.name), 1)
          ]),
          (__props.modelValue.users?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                _createVNode(_unref(UsersIcon), {
                  class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("number.list.agentCount", props.modelValue.users.length || 0, {
                named: { n: props.modelValue.users.length },
              })), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (lastModifiedBy.value != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_unref(CalendarIcon), {
                class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(__props.modelValue.updatedByUser
                ? _ctx.$t("core.updatedAt")
                : _ctx.$t("core.createdAt")) + " ", 1),
                _createElementVNode("time", {
                  datetime: __props.modelValue.updatedAt || __props.modelValue.createdAt
                }, _toDisplayString(_unref(formatDateAsString)(
                  __props.modelValue.updatedAt || __props.modelValue.createdAt,
                  "LL",
                )), 9, _hoisted_14),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("core.by")) + " ", 1),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER_LABEL) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ViewUserById',
                params: {
                  id: lastModifiedBy.value.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(lastModifiedBy.value.name), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})