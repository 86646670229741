import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-3" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-3" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, onMounted, Ref, ref, watch } from "vue";
import store from "@/store";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  ILevel,
  IMultiSelectOption,
  IWordFilter,
  WordGetters,
  WordActions,
  ParticipantType,
} from "@/definitions";
import LevelService from "@/services/LevelService";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordListFilters',
  setup(__props) {

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[WordGetters.DEFAULT_FILTER]);

const filter: Ref<IWordFilter> = ref({
  callParticipant: defaultFilter.value.callParticipant,
  isActive: defaultFilter.value.isActive,
});

const storeFilter = computed(() => store.getters[WordGetters.FILTER]);

const levelOptions: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  }).then((response) => (levelOptions.value = response.data));
};

const { t } = useI18n();
const participants = [
  {
    value: ParticipantType.All,
    label: t(`word.participant.${ParticipantType.All}`),
  },
  {
    value: ParticipantType.Agent,
    label: t(`word.participant.${ParticipantType.Agent}`),
  },
  {
    value: ParticipantType.Client,
    label: t(`word.participant.${ParticipantType.Client}`),
  },
] as IMultiSelectOption[];

const isActiveFilterOptions = [
  {
    label: t("level.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("level.filters.isActive.options.inactives"),
    value: false,
  },
] as IMultiSelectOption[];

function applyFilters() {
  store.dispatch(WordActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(WordActions.CLEAR_FILTER);
  refreshLocalFilter();
}
onMounted(() => {
  getLevels();
});
function refreshLocalFilter() {
  filter.value.callParticipant = storeFilter.value.callParticipant;
  filter.value.isActive = storeFilter.value.isActive;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("word.filters.callParticipant")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_call_participant",
              modelValue: filter.value.callParticipant,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.callParticipant) = $event)),
              options: participants,
              "can-clear": true
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("word.filters.isActive")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_isActive",
              modelValue: filter.value.isActive,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.isActive) = $event)),
              options: isActiveFilterOptions,
              primitive: true,
              "can-clear": true
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(UiButton), {
            size: "md",
            variant: "white",
            "text-variant": "gray-700",
            class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            size: "md",
            variant: "primary",
            "text-variant": "gray-700",
            class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})