import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_4 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

import { ref, Ref, computed, WritableComputedRef, PropType, watch } from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IConnectFlowAudio,
  IFlowNode,
} from "@/definitions";
import store from "@/store";
import { QuestionMarkCircleIcon } from "@heroicons/vue/outline";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiTextArea,
  UiTextInput,
} from "@/components";
import { Handle, Position } from "@vue-flow/core";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

interface IClientInfoFormState {
  audio: null | IConnectFlowAudio;
  text: undefined | string;
  timeout: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientInfo',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const audioOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.AUDIO_OPTIONS],
);
const formState: Ref<IClientInfoFormState> = ref({
  audio: null,
  text: undefined,
  timeout: 10,
});

const textValidation = t("connectFlow.node.clientInfo.validation");
const atLeastOneFilled = helpers.withMessage(textValidation, (value, state) => {
  return !!state.audio || !!state.text;
});
const rules = computed<ValidationArgs<IClientInfoFormState>>(() => {
  const r: ValidationArgs<IClientInfoFormState> = {
    audio: {
      atLeastOneFilled,
    },
    text: {
      atLeastOneFilled,
    },
    timeout: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IClientInfoFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.clientInfo.empty");
  if (nodeWritable.value.data.related) {
    text = nodeWritable.value.data.related.label;
  } else if (nodeWritable.value.data.textToAudio) {
    text = t("connectFlow.node.clientInfo.textToAudio");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    if (formState.value.audio) {
      nodeWritable.value.data.related = {
        value: formState.value.audio.id,
        label: formState.value.audio.name,
      };
      nodeWritable.value.data.textToAudio = undefined;
    } else if (formState.value.text) {
      nodeWritable.value.data.textToAudio = formState.value.text;
      nodeWritable.value.data.related = null;
    }
    showForm.value = false;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}
watch([nodeWritable.value], () => {
  formState.value.audio = audioOptions.value.find(
    (audio: IConnectFlowAudio) =>
      audio.id == nodeWritable.value.data.related?.value,
  );
  formState.value.text = nodeWritable.value.data.textToAudio;

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(UiNode), {
      nodeId: __props.node.id,
      title: _ctx.$t('connectFlow.node.clientInfo.title'),
      icon: _unref(QuestionMarkCircleIcon),
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => (showForm.value = true)),
      onRemoveNode: removeNode,
      class: _normalizeClass(isFormCorrect.value ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(nodeText.value), 1)
        ]),
        _createVNode(_unref(Handle), {
          id: "timeout",
          type: "target",
          position: _unref(Position).Left,
          connectable: false,
          style: {"top":"45px"}
        }, null, 8, ["position"]),
        _createVNode(_unref(Handle), {
          id: "timeout",
          type: "source",
          connectable: false,
          position: _unref(Position).Left,
          style: {"top":"35px"}
        }, null, 8, ["position"]),
        _createVNode(_unref(Handle), {
          id: "invalid",
          type: "target",
          connectable: false,
          position: _unref(Position).Left,
          style: {"top":"auto","bottom":"25px"}
        }, null, 8, ["position"]),
        _createVNode(_unref(Handle), {
          id: "invalid",
          type: "source",
          position: _unref(Position).Left,
          connectable: false,
          style: {"top":"auto","bottom":"35px"}
        }, null, 8, ["position"])
      ]),
      _: 1
    }, 8, ["nodeId", "title", "icon", "class"]),
    _createVNode(_unref(UiRightPanel), {
      modelValue: showForm.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showForm).value = $event)),
      title: _ctx.$t('connectFlow.node.clientInfo.title'),
      icon: _unref(QuestionMarkCircleIcon)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.clientInfo.select")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "audio",
              modelValue: _unref(v$).audio.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).audio.$model) = $event)),
              options: audioOptions.value,
              errors: _unref(v$).audio.$silentErrors,
              disabled: formState.value.text?.length > 0,
              label: "name",
              "value-prop": "id",
              "can-clear": ""
            }, null, 8, ["modelValue", "options", "errors", "disabled"])
          ]),
          _createVNode(_unref(UiTextArea), {
            label: _ctx.$t('connectFlow.node.clientInfo.write'),
            name: "text",
            modelValue: _unref(v$).text.$model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).text.$model) = $event)),
            disabled: formState.value.audio?.name.length > 0,
            errors: _unref(v$).text.$silentErrors
          }, null, 8, ["label", "modelValue", "disabled", "errors"]),
          _createVNode(_unref(UiTextInput), {
            label: _ctx.$t('connectFlow.node.clientInfo.timeout'),
            name: "timeout",
            modelValue: _unref(v$).timeout.$model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).timeout.$model) = $event))
          }, null, 8, ["label", "modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (showForm.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(UiButton), {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "icon"])
  ], 64))
}
}

})