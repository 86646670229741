import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { StepEdge, EdgeText, getSmoothStepPath } from "@vue-flow/core";
import { computed, ComputedRef } from "vue";
import { ConnectFlowModelingGetters, IFlowConnection } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'ClientInfoEdge',
  props: {
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: false,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const path = computed(() => getSmoothStepPath(props));

const edge: ComputedRef<IFlowConnection> = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.id),
);
const color = computed(() =>
  (edge.value.data?.uraOption !== undefined &&
    edge.value.data?.uraOption !== null) ||
  (edge.value.data?.waitUser !== undefined && edge.value.data?.waitUser)
    ? "#4ADE80"
    : "#F87171",
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(StepEdge), {
      id: __props.id,
      style: _normalizeStyle(__props.style),
      path: path.value[0],
      "marker-end": __props.markerEnd
    }, null, 8, ["id", "style", "path", "marker-end"]),
    _createVNode(_unref(EdgeText), {
      x: props.targetX - 0,
      y: props.targetY - 30,
      style: _normalizeStyle([`stroke: ${color.value}`, {"stroke-width":"0.5","filter":"drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))"}]),
      "label-show-bg": true,
      "label-bg-padding": [8, 4],
      "label-bg-border-radius": 5
    }, {
      default: _withCtx(() => [
        (
        (edge.value.data?.uraOption !== undefined && edge.value.data?.uraOption !== null) ||
        edge.value.data.waitUser
      )
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(edge.value.label), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t("connectFlow.edge.uraOption")), 1)
            ], 64))
      ]),
      _: 1
    }, 8, ["x", "y", "style"])
  ], 64))
}
}

})