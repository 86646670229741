import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-3 w-full h-full" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "bg-gray-50 py-5 h-full flex flex-col justify-center px-2 rounded-l-lg" }
const _hoisted_5 = { class: "flex flex-col items-start p-3" }
const _hoisted_6 = { class: "text-gray-700 font-semibold" }
const _hoisted_7 = { class: "text-gray-500 text-xs text-left" }

import { UiRightPanel } from "@/components";
import { computed, ComputedRef } from "vue";
import store from "@/store";
import {
  PlusCircleIcon,
  ClockIcon,
  PlayIcon,
  ReplyIcon,
  PhoneMissedCallIcon,
  ReceiptRefundIcon,
  QuestionMarkCircleIcon,
  AnnotationIcon,
} from "@heroicons/vue/outline";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  IConnectFlowModelingMenu,
  IFlowNode,
  NodeType,
} from "@/definitions";
import NodesRules, { INodeRules } from "@/definitions/FlowNodeRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentSelector',
  setup(__props) {

const menu: ComputedRef<IConnectFlowModelingMenu> = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.MENU],
);

const showForm = computed({
  get: () => menu.value.show,
  set: (value) =>
    store.dispatch("flow/" + ConnectFlowModelingActions.SET_MENU, value),
});

const triggerNode: ComputedRef<IFlowNode> = computed(() => {
  if (menu.value.idTriggerBy) {
    return store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      menu.value.idTriggerBy,
    );
  }
  return null;
});

const rules: ComputedRef<INodeRules> = computed(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => NodesRules[triggerNode.value?.data.component],
);

function setUpNode(component: string) {
  const action = menu.value.action;

  switch (action) {
    case "add":
      store.dispatch("flow/" + ConnectFlowModelingActions.PUSH_NEW_NODE, {
        data: { component },
      });

      break;
    case "update":
      store.dispatch("flow/" + ConnectFlowModelingActions.EDIT_NODE, {
        data: { component },
      });
  }
  showForm.value = false;
}

function getNodeComponent(component: string) {
  switch (component) {
    case NodeType.TIME_VALIDATION:
      return ClockIcon;
    case NodeType.OPEN_EDGE:
      return PlusCircleIcon;
    case NodeType.PLAY_AUDIO:
      return PlayIcon;
    case NodeType.CLIENT_INFO:
      return QuestionMarkCircleIcon;
    case NodeType.TRANSFER_EXTENSION:
    case NodeType.TRANSFER_EXTERNAL:
    case NodeType.TRANSFER_QUEUE:
      return ReplyIcon;
    case NodeType.TRANSFER_SURVEY:
      return AnnotationIcon;
    case NodeType.HANG_UP:
      return PhoneMissedCallIcon;
    case NodeType.GO_TO:
      return ReceiptRefundIcon;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: showForm.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showForm).value = $event)),
    title: _ctx.$t('connectFlow.componentSelector')
  }, {
    default: _withCtx(() => [
      (rules.value !== undefined)
        ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NodeType), (type) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: `nodeButton${type}`
                }, [
                  (rules.value.typesChildren.includes(type))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        type: "button",
                        onClick: ($event: any) => (setUpNode(type)),
                        class: "bg-gray-100 hover:bg-white cursor-pointer rounded-lg shadow ring-1 ring-black ring-opacity-5 flex items-center"
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(getNodeComponent(type)), { class: "h-8 w-8 text-secondary" }))
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t(`connectFlow.node.${type}.title`)), 1),
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(`connectFlow.node.${type}.helpText`)), 1)
                        ])
                      ], 8, _hoisted_3))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})