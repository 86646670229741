import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 inline-block" }
const _hoisted_3 = { class: "grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:lg:grid-cols-8 gap-2 mt-4" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "font-semibold text-xs text-gray-600" }
const _hoisted_6 = { class: "font-semibold text-xs text-gray-600" }
const _hoisted_7 = { class: "font-semibold text-xs text-gray-600" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-1 text-xs text-center pt-1" }

import { UiPageHeader, UiSpinner } from "@/components";
import { IUserDetails } from "@/definitions/chart-typings/Realtime";
import { computed, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { applyPhoneMask } from "@/utils/number";
import { UserIcon, DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import {
  FilterGetters,
  ILevel,
  UiActions,
  UserDevice,
  UserDeviceStatus,
} from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentExtensionReportPage',
  setup(__props) {

const loading = ref(true);

const items: Ref<IUserDetails[]> = ref([]);

const getData = () => {
  loading.value = true;
  RealtimeReportService.getAgentExtensions<IUserDetails[]>()
    .then((response) => {
      items.value = response.data;
    }, toastServiceError)
    .finally(() => (loading.value = false));
  setLoading(false);
};

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  items.value = [];
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onMounted(() => {
  setLoading(true);
});
onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), { class: "inline-block" }, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.agentExtension.title")), 1),
        (loading.value)
          ? (_openBlock(), _createBlock(_unref(UiSpinner), {
              key: 0,
              class: "h-5 w-5 left inline-block ml-2 mb-1.5"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `queueDetailItem${idx}`,
          class: _normalizeClass([
          item.status == _unref(UserDeviceStatus).OFFLINE
            ? 'bg-red-50'
            : 'bg-green-100',
          'shadow rounded-md flex flex-col items-center justify-center px-1 py-2 space-y-1',
        ])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UserIcon), { class: "h-12 w-12 border border-gray-300 bg-gray-50 p-0.5 shadow-sm rounded-full text-gray-400" }),
            (item.userDevice === _unref(UserDevice).DESKTOP)
              ? (_openBlock(), _createBlock(_unref(DesktopComputerIcon), {
                  key: 0,
                  class: "h-6 w-6 absolute top-0 -right-3 text-cyan-600"
                }))
              : (item.userDevice === _unref(UserDevice).MOBILE)
                ? (_openBlock(), _createBlock(_unref(DeviceMobileIcon), {
                    key: 1,
                    class: "h-6 w-6 absolute top-0 -right-3 text-cyan-600"
                  }))
                : (item.userDevice === _unref(UserDevice).WEB)
                  ? (_openBlock(), _createBlock(SVGChromeLogo, {
                      key: 2,
                      class: "h-6 w-6 absolute top-0 -right-3 text-cyan-600"
                    }))
                  : (item.userDevice === _unref(UserDevice).EXTERNAL)
                    ? (_openBlock(), _createBlock(SVGDeviceExternal, {
                        key: 3,
                        class: "h-6 w-6 absolute top-0 -right-3 text-cyan-600"
                      }))
                    : _createCommentVNode("", true)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(item.user), 1),
          _createElementVNode("p", _hoisted_6, " ramal " + _toDisplayString(item.extension), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(applyPhoneMask)(item.numberClient) || "-"), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", {
              class: _normalizeClass([
              item.status == _unref(UserDeviceStatus).OFFLINE
                ? 'text-red-700 border-red-200'
                : 'text-green-700 border-green-200',
              'px-1 py-0.5 rounded-md border-2 bg-white font-semibold',
            ])
            }, _toDisplayString(_ctx.$t(`report.realtime.device.status.${item.status}`)), 3)
          ])
        ], 2))
      }), 128))
    ])
  ]))
}
}

})