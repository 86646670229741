import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "-mx-4 mt-8 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_5 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_6 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_8 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-1.5"
}
const _hoisted_9 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_10 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_11 = { class: "px-4 py-4 sm:px-6" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { WordService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
  UiButton,
} from "@/components";
import WordListItem from "@/views/pages/word/components/WordListItem.vue";
import WordListSort from "@/views/pages/word/components/WordListSort.vue";
import WordListFilters from "@/views/pages/word/components/WordListFilters.vue";
import {
  WordGetters,
  WordActions,
  IWordServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IFilterItem,
  IWord,
  Permission,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'WordPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const words: Ref<IWord[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[WordGetters.SEARCH],
  set: (search: string) => store.dispatch(WordActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[WordGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(WordActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[WordGetters.PAGE],
  set: (page: number) => store.dispatch(WordActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const filter = computed(() => store.getters[WordGetters.FILTER]);

const getWords = () => {
  const params: IWordServiceGetAllParams = {
    page: currentPage.value,
    call_participant: filter.value.callParticipant
      ? filter.value.callParticipant?.value
      : undefined,
    is_active: filter.value.isActive,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  WordService.getAll<Array<IWord>>({
    params,
  })
    .then((response: AxiosResponse<IWord[]>) => {
      words.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch(
  [search, level, filter, orderBy],
  () => {
    if (currentPage.value === 1) getWords();
    else currentPage.value = 1;
  },
  { deep: true },
);

watch([currentPage], () => {
  getWords();
});

getWords();
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[WordGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(WordActions.CLEAR_FILTER, filter);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("core.entity.Word")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("word.description")), 1)
      ]),
      _: 2
    }, [
      (_ctx.$can(_unref(Permission).CREATE_WORD))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateWord',
          } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
              placeholder: _ctx.$t('word.filters.search')
            }, null, 8, ["modelValue", "placeholder"])
          ], 32),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(WordListSort, {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(WordListFilters)
          ])
        ])
      ]),
      (sortMenuSelected.value.length || activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                key: filter,
                removable: "",
                onRemove: ($event: any) => (handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`word.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(words.value, (number) => {
          return (_openBlock(), _createBlock(WordListItem, {
            key: number.id,
            "model-value": number
          }, null, 8, ["model-value"]))
        }), 128)),
        (!words.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})