import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_6 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_8 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_9 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_11 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { WordService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiContainerSection,
  UiMultiselect,
} from "@/components";
import {
  IWord,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IWordServiceCreateResult,
  FormAction,
  ParticipantType,
  FilterGetters,
} from "@/definitions";
import { IWordFormState } from "@/definitions/ui-typings/IWordFormState";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import LevelService from "@/services/LevelService";
import { useI18n } from "vue-i18n";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<IWordFormState>({
  id: 0,
  levelId: 0,
  word: "",
  callParticipant: "",
  isActive: true,
});

const rules = computed<ValidationArgs<IWordFormState>>(() => ({
  id: {
    required,
  },
  levelId: {
    required,
  },
  word: {
    required,
    maxLength: maxLength(15),
  },
  callParticipant: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IWordFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getWord = (id: number) => {
  setLoading(true);
  WordService.get<IWord>(id)
    .then((response: AxiosResponse<IWord>) => {
      const { id, word, callParticipant, levelId, isActive } = response.data;
      formState.id = id;
      formState.word = word;
      formState.callParticipant = callParticipant;
      formState.levelId = levelId;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getWord(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewWordById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    WordService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    WordService.create<IWordServiceCreateResult>(formState)
      .then((response: AxiosResponse<IWordServiceCreateResult>) => {
        Analytics.trackingAction(`NewWord`).then();
        handleServiceSuccess(response.data.message, response.data.result.id);
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);

const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewLevelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListLevel",
    });
};

const { t } = useI18n();
const participants = [
  {
    value: ParticipantType.All,
    label: t(`word.participant.${ParticipantType.All}`),
  },
  {
    value: ParticipantType.Agent,
    label: t(`word.participant.${ParticipantType.Agent}`),
  },
  {
    value: ParticipantType.Client,
    label: t(`word.participant.${ParticipantType.Client}`),
  },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("word." + props.action + ".title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("word." + props.action + ".description")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiContainerSection), null, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("word." + props.action + ".section.title")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("word." + props.action + ".section.description")), 1)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("word.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value
              }, null, 8, ["modelValue", "options"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).word.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).word.$model) = $event)),
              name: "word",
              type: "text",
              label: _ctx.$t('word.labels.word'),
              class: "col-span-6 sm:col-span-4",
              errors: _unref(v$).word.$errors,
              onBlur: _unref(v$).word.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("word.labels.callParticipant")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "callParticipants",
                modelValue: formState.callParticipant,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.callParticipant) = $event)),
                primitive: true,
                options: participants,
                errors: _unref(v$).callParticipant.$errors
              }, null, 8, ["modelValue", "errors"])
            ]),
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).isActive.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('word.labels.isActive'),
              class: "h-6 col-span-6 sm:col-span-4"
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          onClick: handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_WORD)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_WORD))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})