import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-5" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-5" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_9 = { class: "sm:col-span-5" }
const _hoisted_10 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_11 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_12 = { class: "sm:col-span-5" }
const _hoisted_13 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_14 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_15 = { class: "sm:col-span-5" }
const _hoisted_16 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_17 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IBillingReportFilter,
  BillingReportGetters,
  BillingReportActions,
  IUser,
  CallType,
  CallStatus,
} from "@/definitions";
import { UserService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportFilters',
  props: {
    sources: {},
    destinations: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[BillingReportGetters.DEFAULT_FILTER],
);

const props = __props;

const filter: Ref<IBillingReportFilter> = ref({
  user: defaultFilter.value.user,
  sourceNumber: defaultFilter.value.sourceNumber,
  destinationNumber: defaultFilter.value.destinationNumber,
  status: defaultFilter.value.status,
  type: defaultFilter.value.type,
});
const storeFilter = computed(() => store.getters[BillingReportGetters.FILTER]);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  UserService.getAll<IUser[]>({
    params: {
      isAgent: true,
      page: 1,
      per_page: 100,
    },
  }).then((response) => (userOptions.value = response.data));
};

function applyFilters() {
  store.dispatch(BillingReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(BillingReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.user = storeFilter.value.user;
  filter.value.sourceNumber = storeFilter.value.sourceNumber;
  filter.value.destinationNumber = storeFilter.value.destinationNumber;
  filter.value.status = storeFilter.value.status;
  filter.value.type = storeFilter.value.type;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

onMounted(() => {
  getUsers();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("report.billing.filters.user")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_user",
              modelValue: filter.value.user,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.user) = $event)),
              label: "name",
              "value-prop": "id",
              options: userOptions.value,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("report.billing.filters.sourceNumber")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_source_number",
              modelValue: filter.value.sourceNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.sourceNumber) = $event)),
              label: "value",
              "value-prop": "value",
              options: 
                props.sources.map((source) => ({
                  value: source,
                  label: source,
                }))
              ,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("report.billing.filters.destinationNumber")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_destination_number",
              modelValue: filter.value.destinationNumber,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.destinationNumber) = $event)),
              label: "value",
              "value-prop": "value",
              options: 
                props.destinations.map((destination) => ({
                  value: destination,
                  label: destination,
                }))
              ,
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("report.billing.filters.status")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_status",
              modelValue: filter.value.status,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.status) = $event)),
              "value-prop": "value",
              options: [
                {
                  value: _unref(CallStatus).ANSWERED,
                  label: _unref(t)('core.options.call.status.answered'),
                },
                {
                  value: _unref(CallStatus).NO_ANSWER,
                  label: _unref(t)('core.options.call.status.no_answer'),
                },
                {
                  value: _unref(CallStatus).BUSY,
                  label: _unref(t)('core.options.call.status.busy'),
                },
                {
                  value: _unref(CallStatus).DITCHED,
                  label: _unref(t)('core.options.call.status.ditched'),
                },
              ],
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("report.billing.filters.type")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_type",
              modelValue: filter.value.type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.type) = $event)),
              "value-prop": "value",
              options: [
                {
                  value: _unref(CallType).INBOUND,
                  label: _unref(t)('core.options.call.type.inbound'),
                },
                {
                  value: _unref(CallType).OUTBOUND,
                  label: _unref(t)('core.options.call.type.outbound'),
                },
              ],
              "can-clear": true,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})