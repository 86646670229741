import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-3" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-3" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_9 = { class: "sm:col-span-3" }
const _hoisted_10 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_11 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, onMounted, Ref, ref, watch } from "vue";
import store from "@/store";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  INumberFilter,
  NumberGetters,
  NumberActions,
  ICityOption,
} from "@/definitions";
import NumberService from "@/services/NumberService";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListFilters',
  setup(__props) {

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[NumberGetters.DEFAULT_FILTER],
);
const filter: Ref<INumberFilter> = ref({
  hasConnectFlow: defaultFilter.value.level,
  level: defaultFilter.value.level,
  state: defaultFilter.value.state,
  city: defaultFilter.value.city,
});
const storeFilter = computed(() => store.getters[NumberGetters.FILTER]);

const stateOptions: Ref<IMultiSelectOption[]> = ref([]);
const getLevelsStates = () => {
  NumberService.getStates<IMultiSelectOption[]>().then(
    (response) => (stateOptions.value = response.data),
  );
};

const cityOptions: Ref<ICityOption[]> = ref([]);
const getLevelsCities = () => {
  NumberService.getCities<ICityOption[]>().then(
    (response) => (cityOptions.value = response.data),
  );
};
const cityOptionsScoped = computed(() => {
  if (filter.value.state !== null && cityOptions.value.length) {
    return cityOptions.value.filter((city) => {
      if (city.state !== undefined) {
        return city.state === filter.value.state?.value;
      }
    });
  }
  return cityOptions.value;
});

const { t } = useI18n();
const hasConnectFlowFilterOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t("number.filters.withConnectFlow"),
    value: true,
  } as IMultiSelectOption,
  {
    label: t("number.filters.withoutConnectFlow"),
    value: false,
  } as IMultiSelectOption,
]);

function applyFilters() {
  store.dispatch(NumberActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(NumberActions.CLEAR_FILTER);
  refreshLocalFilter();
}

onMounted(() => {
  getLevelsStates();
  getLevelsCities();
});
function refreshLocalFilter() {
  filter.value.hasConnectFlow = storeFilter.value.hasConnectFlow;
  filter.value.state = storeFilter.value.state;
  filter.value.city = storeFilter.value.city;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("number.filters.hasConnectFlow")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_hasConnectFlow",
              modelValue: filter.value.hasConnectFlow,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.hasConnectFlow) = $event)),
              options: hasConnectFlowFilterOptions.value,
              "can-clear": ""
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("number.filters.state")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_state",
              modelValue: filter.value.state,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.state) = $event)),
              options: stateOptions.value,
              "can-clear": ""
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("number.filters.city")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_city",
              modelValue: filter.value.city,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.city) = $event)),
              options: cityOptionsScoped.value,
              "can-clear": ""
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_unref(UiButton), {
            size: "md",
            variant: "white",
            "text-variant": "gray-700",
            class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            size: "md",
            variant: "primary",
            "text-variant": "gray-700",
            class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})