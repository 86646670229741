import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "sm:col-span-12" }
const _hoisted_5 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_6 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

import {
  PropType,
  computed,
  WritableComputedRef,
  ref,
  Ref,
  onMounted,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  ISchedule,
} from "@/definitions";
import store from "@/store";
import { ClockIcon } from "@heroicons/vue/outline";
import { UiRightPanel, UiNode, UiButton, UiMultiselect } from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@/utils/validators";

interface ITimeFormState {
  schedule: null | ISchedule;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeValidation',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const scheduleOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.SCHEDULE_OPTIONS],
);
const formState: Ref<ITimeFormState> = ref({
  schedule: null,
});
const rules = computed<ValidationArgs<ITimeFormState>>(() => {
  const r: ValidationArgs<ITimeFormState> = {
    schedule: {
      required,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<ITimeFormState>>> = useVuelidate(
  rules,
  formState,
);

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = {
      value: formState.value.schedule?.id,
      label: formState.value.schedule?.name,
    } as IMultiSelectOption;
    showForm.value = false;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related !== null) {
    formState.value.schedule = scheduleOptions.value.find(
      (schedule: ISchedule) =>
        schedule.id == nodeWritable.value.data.related?.value,
    );
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(UiNode), {
      nodeId: __props.node.id,
      showActions: "",
      title: _ctx.$t('connectFlow.node.timeValidation.title'),
      icon: _unref(ClockIcon),
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => (showForm.value = true)),
      onRemoveNode: removeNode,
      class: _normalizeClass(isFormCorrect.value ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (nodeWritable.value.data.related)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(nodeWritable.value.data.related.label), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.timeValidation.empty")), 1))
        ])
      ]),
      _: 1
    }, 8, ["nodeId", "title", "icon", "class"]),
    _createVNode(_unref(UiRightPanel), {
      modelValue: showForm.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showForm).value = $event)),
      title: _ctx.$t('connectFlow.node.timeValidation.title'),
      icon: _unref(ClockIcon)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("connectFlow.node.timeValidation.selectLabel")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "schedule",
              modelValue: _unref(v$).schedule.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).schedule.$model) = $event)),
              options: scheduleOptions.value,
              label: "name",
              "value-prop": "id",
              errors: _unref(v$).schedule.$errors,
              required: ""
            }, null, 8, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(UiButton), {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (showForm.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(UiButton), {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "icon"])
  ], 64))
}
}

})