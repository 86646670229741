import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg relative" }
const _hoisted_2 = { class: "absolute z-30 md:rounded-lg py-30 px-4 flex w-full h-full items-center place-content-center" }
const _hoisted_3 = { class: "text-white" }

import { computed } from "vue";
import { LockClosedIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPanel',
  props: {
  noPadding: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  textLock: {
    type: String,
    default: "",
  },
},
  setup(__props) {

const props = __props;

const defaultClasses = computed(() => {
  let classes = "h-full ";
  if (!props.noPadding) {
    classes += "p-8";
  }

  return classes;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.locked)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(["absolute w-full opacity-50 h-full bg-gray-700 md:rounded-lg z-20", defaultClasses.value])
          }, null, 2),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(LockClosedIcon), { class: "h-6 w-6 mr-3 text-white" }),
            _createElementVNode("p", _hoisted_3, _toDisplayString(__props.textLock), 1)
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["mt-8", defaultClasses.value])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ]))
}
}

})