import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_6 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_7 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-1.5"
}
const _hoisted_8 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_9 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_10 = { class: "px-4 py-4 sm:px-6" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import NumberService from "@/services/NumberService";
import { toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
} from "@/components";
import NumberListItem from "@/views/pages/number/components/NumberListItem.vue";
import NumberListSort from "@/views/pages/number/components/NumberListSort.vue";
import NumberListFilters from "@/views/pages/number/components/NumberListFilters.vue";
import {
  INumber,
  NumberGetters,
  NumberActions,
  INumberServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { PhoneIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberPage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const numbers: Ref<INumber[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[NumberGetters.SEARCH],
  set: (search: string) => store.dispatch(NumberActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[NumberGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(NumberActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[NumberGetters.PAGE],
  set: (page: number) => store.dispatch(NumberActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const hasConnectFlow = computed(
  () => store.getters[NumberGetters.FILTER].hasConnectFlow,
);
const filterState = computed(() => store.getters[NumberGetters.FILTER].state);
const filterCity = computed(() => store.getters[NumberGetters.FILTER].city);

const getNumbers = () => {
  const params: INumberServiceGetAllParams = {
    page: currentPage.value,
    hasConnectFlow:
      hasConnectFlow.value !== null ? hasConnectFlow.value.value : null,
    state: filterState.value ? filterState.value.value : undefined,
    city: filterCity.value ? filterCity.value.value : undefined,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  NumberService.getAll<Array<INumber>>({
    params,
  })
    .then((response: AxiosResponse<INumber[]>) => {
      numbers.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, hasConnectFlow, filterState, filterCity], () => {
  if (currentPage.value === 1) getNumbers();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getNumbers();
});

getNumbers();
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[NumberGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(NumberActions.CLEAR_FILTER, filter);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(PhoneIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Numbers")), 1)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_unref(UiListSearch), {
              modelValue: search.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
              placeholder: _ctx.$t('number.filters.search')
            }, null, 8, ["modelValue", "placeholder"])
          ], 32),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(NumberListSort, {
              modelValue: sortMenuSelected.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sortMenuSelected).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(NumberListFilters)
          ])
        ])
      ]),
      (sortMenuSelected.value.length || activeFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortMenuSelected.value, (selected) => {
              return (_openBlock(), _createBlock(_unref(UiSortMenuBadge), {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => (handleRemoveSortClick(selected))
              }, null, 8, ["item", "onRemove"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
              return (_openBlock(), _createBlock(_unref(UiBadge), {
                key: filter,
                removable: "",
                onRemove: ($event: any) => (handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`number.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
                ]),
                _: 2
              }, 1032, ["onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numbers.value, (number) => {
          return (_openBlock(), _createBlock(NumberListItem, {
            key: number.id,
            "model-value": number
          }, null, 8, ["model-value"]))
        }), 128)),
        (!numbers.value.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(UiPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentPage).value = $event)),
        count: count.value,
        perPage: perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8, ["modelValue", "count", "i18n"])
    ])
  ]))
}
}

})