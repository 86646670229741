import { INavigationItem, Permission } from "@/definitions";
import {
  HomeIcon,
  ChartBarIcon,
  PhoneIcon,
  BanIcon,
  CogIcon,
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  OfficeBuildingIcon,
  TagIcon,
  ClockIcon,
  CloudUploadIcon,
  MenuAlt2Icon,
  AnnotationIcon,
  LinkIcon,
  VideoCameraIcon,
} from "@heroicons/vue/outline";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";

const clearStateOnSetup = {
  query: {
    clearStateOnSetup: "true",
  },
};
export interface IDashboardItem {
  title: string;
  description: string;
  callToAction: string;
  permission?: Permission;
  routeName?: string;
  icon: FunctionalComponent<HTMLAttributes & VNodeProps>;
}

export const SIDE_NAVIGATION_ITEMS: INavigationItem[] = [
  {
    localeKey: "sideNavigation.home",
    icon: HomeIcon,
    name: "Home",
  },
  {
    localeKey: "sideNavigation.report.group",
    icon: ChartBarIcon,
    isOpen: false,
    children: [
      {
        localeKey: "sideNavigation.call",
        icon: PhoneIcon,
        name: "CallList",
        permission: Permission.VIEW_CALLS,
        ...clearStateOnSetup,
        isOpen: false,
        activateBy: ["CallList", "ViewCallById"],
      },
      {
        localeKey: "sideNavigation.report.call",
        name: "CallReport",
        permission: Permission.VIEW_CALL_REPORT,
      },
      {
        localeKey: "sideNavigation.report.contactCenterPerDay",
        name: "ContactCenterPerDayReport",
        permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
      },
      {
        localeKey: "sideNavigation.report.contactCenterPerAgent",
        name: "ContactCenterPerAgentReport",
        permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
      },
      {
        localeKey: "sideNavigation.report.agentProductivityPerDay",
        name: "AgentProductivityPerDayReport",
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
      },
      {
        localeKey: "sideNavigation.report.agentProductivityPerAgent",
        name: "AgentProductivityPerAgentReport",
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
      },
      // {
      //   localeKey: "sideNavigation.report.queueTime",
      //   name: "QueueTimeReport",
      //   permission: Permission.VIEW_QUEUE_TIME_REPORT,
      // },
      {
        localeKey: "sideNavigation.report.realtime",
        name: "RealtimeReport",
        permission: Permission.VIEW_REALTIME_REPORT,
      },
      {
        localeKey: "sideNavigation.report.realtimeAgentExtension",
        name: "RealtimeAgentExtensionReport",
        permission: Permission.VIEW_AGENT_EXTENSION_REPORT,
      },
      {
        localeKey: "sideNavigation.report.agentDeviceStatusHistory",
        name: "AgentDeviceReportPage",
        permission: Permission.VIEW_AGENT_DEVICE_STATUS_HISTORY_REPORT,
      },
      {
        localeKey: "sideNavigation.report.billing",
        name: "BillingReportPage",
        permission: Permission.VIEW_BILLING_REPORT,
      },
    ],
  },
  {
    localeKey: "sideNavigation.meet.group",
    icon: VideoCameraIcon,
    isOpen: false,
    children: [
      {
        localeKey: "sideNavigation.meeting",
        icon: VideoCameraIcon,
        name: "MeetingList",
        permission: Permission.VIEW_MEETINGS,
        ...clearStateOnSetup,
        isOpen: false,
        activateBy: ["MeetingList", "ViewMeetingById"],
      },
    ],
  },
  {
    localeKey: "sideNavigation.config",
    icon: CogIcon,
    name: "Configurations",
  },
];

export const CONFIG_SIDE_NAVIGATION_ITEMS: INavigationItem[] = [
  {
    localeKey: "core.entity.Users",
    icon: UsersIcon,
    name: "ListUser",
    permission: Permission.VIEW_USERS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.UsersLabels",
    icon: UserGroupIcon,
    name: "ListUserLabel",
    permission: Permission.VIEW_USER_LABELS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Permissions",
    icon: LockClosedIcon,
    name: "Permissions",
    permission: Permission.ASSIGN_PERMISSION_TO_ROLE,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Levels",
    icon: OfficeBuildingIcon,
    name: "ListLevel",
    permission: Permission.VIEW_LEVELS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Numbers",
    icon: PhoneIcon,
    name: "ListNumber",
    permission: Permission.VIEW_NUMBERS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Words",
    icon: TagIcon,
    name: "ListWord",
    permission: Permission.VIEW_WORDS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.ConnectFlow",
    icon: ConnectFlowIcon,
    name: "ListConnectFlow",
    permission: Permission.VIEW_CONNECT_FLOWS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Schedules",
    icon: ClockIcon,
    name: "ListSchedule",
    permission: Permission.VIEW_SCHEDULES,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Queues",
    icon: MenuAlt2Icon,
    name: "ListQueue",
    permission: Permission.VIEW_QUEUES,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.ConnectFlowAudio",
    icon: CloudUploadIcon,
    name: "ListConnectFlowAudio",
    permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Surveys",
    icon: AnnotationIcon,
    name: "ListSurvey",
    permission: Permission.VIEW_SURVEYS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.SpamNumbers",
    icon: BanIcon,
    name: "ListSpamNumber",
    permission: Permission.VIEW_SPAM_NUMBERS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "core.entity.Integrations",
    icon: LinkIcon,
    name: "ListIntegration",
    permission: Permission.VIEW_INTEGRATIONS,
    ...clearStateOnSetup,
  },
  {
    localeKey: "sideNavigation.home",
    icon: HomeIcon,
    name: "Home",
  },
];
