import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative w-full h-full" }
const _hoisted_2 = { class: "invisible group-hover:visible absolute flex flex-col bg-white border top-7 border-gray-200 rounded-md px-2 -ml-1" }

import { computed } from "vue";
import {
  AudioPlayerActions,
  AudioPlayerGetters,
  ICallTimelineItem,
} from "@/definitions";
import store from "@/store";
import { PlayIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayerBullets',
  props: {
  type: {
    type: String,
    required: true,
  },
  timeline: {
    type: Array as () => Array<ICallTimelineItem>,
    required: false,
  },
},
  setup(__props) {



const playerHasError = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_ERROR] !== null,
);
const secondsDuration = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_DURATION],
);

function playSnippet(start: number, end: number) {
  if (!playerHasError.value) {
    store.dispatch(AudioPlayerActions.PLAY_SNIPPET, [start, end]);
  }
}

function getPositionBullet(item: ICallTimelineItem) {
  return (item.startedAt / secondsDuration.value) * 100 + "%";
}

const insideColors = {
  words: {
    bullet: "bg-blue-500/50",
    play: "text-blue-500",
  },
  insights: {
    bullet: "bg-yellow-500/50",
    play: "text-yellow-500",
  },
  search: {
    bullet: "bg-orange-500/50",
    play: "text-orange-500",
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.timeline, (bullet) => {
    return (_openBlock(), _createElementBlock("div", {
      key: `bulletAttendant-${bullet.id}`,
      class: _normalizeClass(["rounded-full absolute group h-3 w-3 hover:transition-all hover:bg-white hover:h-6 hover:w-6 p-0 hover:-mx-1", insideColors[__props.type].bullet]),
      style: _normalizeStyle({ left: getPositionBullet(bullet) })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(PlayIcon), {
          class: _normalizeClass(["invisible group-hover:visible w-full h-full cursor-pointer", insideColors[__props.type].play]),
          onClick: ($event: any) => (playSnippet(bullet.startedAt, bullet.endedAt))
        }, null, 8, ["class", "onClick"]),
        _createElementVNode("div", _hoisted_2, [
          (__props.type === 'words')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(bullet.registeredWords, (word) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${word}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(word), 1))
              }), 128))
            : _createCommentVNode("", true),
          (__props.type === 'insights')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(bullet.insights, (insight) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${insight.id}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(insight.value), 1))
              }), 128))
            : _createCommentVNode("", true),
          (__props.type === 'search')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(bullet.matchedWords, (word) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${word.text}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(word.text), 1))
              }), 128))
            : _createCommentVNode("", true)
        ])
      ])
    ], 6))
  }), 128))
}
}

})