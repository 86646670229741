import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-end pb-1.5 mt-6"
}
const _hoisted_2 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

import {
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
  WritableComputedRef,
} from "vue";
import {
  UiButton,
  UiCheckboxInput,
  UiRightPanel,
  UiTextInput,
} from "@/components";
import {
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  EdgeType,
  IFlowConnection,
  IFlowNode,
  NodeType,
} from "@/definitions";
import store from "@/store";
import { helpers } from "@vuelidate/validators";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

interface IEdgeValidationFormState {
  waitUser: boolean | null;
  option: number | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormClienteInfoEdge',
  props: {
  edgeId: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

watch(
  () => props.modelValue,
  (show) => {
    internalShow.value = show;
    if (show) {
      if (edge.value?.data !== undefined) {
        formState.value.waitUser = edge.value.data?.waitUser;
        formState.value.option = edge.value.data?.uraOption;
      }
    }
  },
);

const emit = __emit;

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
  emit("update:modelValue", state);
};
watch(internalShow, () => {
  handleToggle(internalShow.value);
});

const edge: WritableComputedRef<IFlowConnection> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.edgeId,
    ),
  set: (value: IFlowConnection) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const formState: Ref<IEdgeValidationFormState> = ref({
  waitUser: null,
  option: null,
});
const validationFill = t("connectFlow.edge.validation.fill");

const checkFill = helpers.withMessage(validationFill, (value, state) => {
  return state.waitUser || !!state.option;
});

const connections: ComputedRef<IFlowConnection[]> = computed(
  () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS],
);

const nodeTarget: ComputedRef<IFlowNode | undefined> = computed(() => {
  const nodes: IFlowNode[] =
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_NODES];
  return nodes.find((n) => n.id === edge.value.target);
});

const validationUsed = t("connectFlow.edge.validation.used");
const isUsed = helpers.withMessage(validationUsed, (value, state) => {
  const siblings = connections.value.filter(
    (c) => c.source === edge.value.source,
  );
  let used = null;

  if (state.option >= 0) {
    used = siblings.find(
      (s) =>
        s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
        s.data?.uraOption === state.option &&
        s.id !== edge.value.id,
    );
  } else if (state.waitUser != null) {
    used = siblings.find(
      (s) =>
        s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
        s.data?.waitUser === state.waitUser &&
        s.id !== edge.value.id,
    );
  }
  return used === undefined;
});
const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
  const r: ValidationArgs<IEdgeValidationFormState> = {
    waitUser: {
      checkFill: checkFill,
      isUsed: isUsed,
    },
    option: {
      checkFill: checkFill,
      isUsed: isUsed,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> =
  useVuelidate(rules, formState);

const isFormCorrect = ref(true);

async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();

  if (isFormCorrect.value && edge.value.data != undefined) {
    edge.value.data.uraOption = formState.value.waitUser
      ? null
      : formState.value.option;
    edge.value.data.waitUser = formState.value.waitUser ?? false;
    edge.value.data.hasErrors = false;

    if (formState.value.waitUser) {
      edge.value.label = t("connectFlow.edge.waitUser");
    } else {
      edge.value.label = t("connectFlow.edge.option", {
        number: formState.value.option,
      });
    }
    internalShow.value = false;
  }
  if (edge.value.data !== undefined) {
    edge.value.data.hasErrors = !isFormCorrect.value;
  }

  v$.value.$reset();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: internalShow.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((internalShow).value = $event)),
    title: _ctx.$t('connectFlow.edge.titleInfoClient')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        autocomplete: "off",
        class: "flex flex-col gap-3",
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        _createVNode(_unref(UiTextInput), {
          label: _ctx.$t('connectFlow.edge.uraOption'),
          name: "uraOption",
          type: "number",
          modelValue: _unref(v$).option.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).option.$model) = $event)),
          disabled: formState.value.waitUser ?? false,
          errors: _unref(v$).waitUser.$errors,
          "silent-errors": _unref(v$).waitUser.$silentErrors
        }, null, 8, ["label", "modelValue", "disabled", "errors", "silent-errors"]),
        (nodeTarget.value?.data.component === _unref(NodeType).TRANSFER_EXTENSION)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: _unref(v$).waitUser.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).waitUser.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('connectFlow.edge.waitUser'),
                class: "h-6",
                errors: _unref(v$).waitUser.$errors
              }, null, 8, ["modelValue", "label", "errors"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).waitUser.$errors, (error) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-sm text-danger bg-red-50 px-2 py-2 w-full shadow z-10",
                  key: error.$uid
                }, [
                  _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (internalShow.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            type: "submit",
            variant: "primary",
            "text-variant": "white",
            themed: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})