import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-12 sm:pt-0 mb-10" }
const _hoisted_2 = { class: "hidden md:flex flex-col-reverse md:flex-row items-center justify-between xl:space-x-8 mb-6" }
const _hoisted_3 = { class: "flex flex-col h-[230px] md:h-[330px] xl:h-[350px] relative" }
const _hoisted_4 = {
  key: 0,
  class: "text-2xl md:my-8 xl:text-4xl font-bold text-gray-800 my-4 xl:mt-16 xl:mb-8"
}
const _hoisted_5 = { class: "font-medium text-gray-500 xl:text-md md:w-5/6 block w-full" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "text-xl xl:text-2xl font-semibold text-gray-700 absolute bottom-1 flex items-center" }
const _hoisted_8 = ["alt"]
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4" }
const _hoisted_10 = { class: "border border-gray-300 bg-gray-100 rounded-xl shadow hover:bg-white min-h-full flex flex-col justify-between" }
const _hoisted_11 = { class: "flex items-center justify-between relative" }
const _hoisted_12 = { class: "px-4 pt-3 font-semibold text-gray-700 flex items-center leading-5 w-2/3" }
const _hoisted_13 = { class: "p-1 bg-teal-100 rounded-tr-xl absolute right-0 top-0" }
const _hoisted_14 = { class: "px-4 pt-2 text-sm text-gray-600" }
const _hoisted_15 = { class: "px-4 mt-3 py-2 border-t border-gray-300/50 text-sm rounded-b-xl flex items-center text-primary font-semibold" }

import { computed } from "vue";
import store from "@/store";
import {
  ArrowRightIcon,
  ChartBarIcon,
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  ReplyIcon,
  CurrencyDollarIcon,
} from "@heroicons/vue/solid";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import { Permission, IDashboardItem, AuthGetters, IUser } from "@/definitions";
import { UiPageHeader } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

const items: IDashboardItem[] = [
  {
    title: "dashboard.calls",
    description: "call.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CALLS,
    routeName: "CallList",
    icon: ChartBarIcon,
  },
  {
    title: "report.call.title",
    description: "report.call.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CALL_REPORT,
    routeName: "CallReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.billing.title",
    description: "report.billing.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_BILLING_REPORT,
    routeName: "BillingReportPage",
    icon: CurrencyDollarIcon,
  },
  {
    title: "report.contactCenterPerDay.title",
    description: "report.contactCenterPerDay.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
    routeName: "ContactCenterPerDayReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.contactCenterPerAgent.title",
    description: "report.contactCenterPerAgent.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
    routeName: "ContactCenterPerAgentReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentProductivityPerDay.title",
    description: "report.agentProductivityPerDay.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
    routeName: "AgentProductivityPerDayReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentProductivityPerAgent.title",
    description: "report.agentProductivityPerAgent.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
    routeName: "AgentProductivityPerAgentReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.realtime.title",
    description: "report.realtime.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_REALTIME_REPORT,
    routeName: "RealtimeReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentExtension.title",
    description: "report.agentExtension.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_EXTENSION_REPORT,
    routeName: "RealtimeAgentExtensionReport",
    icon: ChartBarIcon,
  },
  {
    title: "dashboard.configuration.users",
    description: "user.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USERS,
    routeName: "ListUser",
    icon: UsersIcon,
  },
  {
    title: "dashboard.configuration.userLabel",
    description: "userLabel.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USER_LABELS,
    routeName: "ListUserLabel",
    icon: UserGroupIcon,
  },
  {
    title: "dashboard.configuration.roles",
    description: "role.description",
    callToAction: "core.actions.goPage",
    permission: Permission.ASSIGN_ROLE_TO_USER,
    routeName: "Permissions",
    icon: LockClosedIcon,
  },
  {
    title: "dashboard.configuration.connectFlow",
    description: "connectFlow.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOWS,
    routeName: "ListConnectFlow",
    icon: ConnectFlowIcon,
  },
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(UiPageHeader), null, {
          info: _withCtx(() => [
            (user.value !== null)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$t("welcomeLogged")) + ", " + _toDisplayString(user.value.name) + ". ", 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t("dashboard.welcome")) + " ", 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("dashboard.welcomeAct")), 1)
        ]),
        _createElementVNode("h2", _hoisted_7, [
          _createVNode(_unref(ReplyIcon), {
            class: "h-8 w-8 text-secondary rotate-180 mr-1",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.gettingStarted")), 1)
        ])
      ]),
      _createElementVNode("img", {
        src: "/img/image-home-verde.png",
        class: "h-[200px] md:h-[200px] xl:h-[350px]",
        alt: _ctx.$t('welcomeLogged')
      }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(items, (block, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: `dashboardItem${index}`
        }, [
          (block.permission && _ctx.$can(block.permission))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: block.routeName }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t(block.title)), 1),
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(block.icon), {
                          class: "h-8 w-8 text-secondary",
                          "aria-hidden": "true"
                        }))
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t(block.description)), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_unref(ArrowRightIcon), {
                        class: "-ml-1 mr-1 h-4 w-4",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(block.callToAction)), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 64))
    ])
  ]))
}
}

})