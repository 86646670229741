import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sr-only" }

import { computed, useSlots } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiBadge',
  props: {
  removable: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "gray",
  },
  textVariant: {
    type: String,
  },
  size: {
    type: String,
    default: "md",
  },
  disabled: Boolean,
},
  emits: ["remove"],
  setup(__props, { emit: __emit }) {

const props = __props;

const variantExceptions = [
  "inherit",
  "current",
  "transparent",
  "black",
  "white",
];
const getVariantSuffix = (variant: string, darker = 400) => {
  let variantColor: string = variant;
  let variantIntensity: number | undefined = undefined;

  if (!variantExceptions.includes(variant)) {
    const variantContainsIntensity = variant.lastIndexOf("-") != -1;

    if (variantContainsIntensity) {
      variantIntensity = parseInt(
        variant.substring(variant.lastIndexOf("-") + 1, variant.length),
      );

      variantColor = variant.substring(0, variant.lastIndexOf("-"));
    } else variantIntensity = 200;
  }

  const getSuffix = (intensity: number | undefined, variation: number) => {
    if (intensity == undefined) return "";
    else {
      let suffix = intensity + variation;
      if (suffix > 900) suffix = 900;
      else if (suffix < 50) suffix = 50;

      return "-" + suffix;
    }
  };

  return {
    default: variantColor + getSuffix(variantIntensity, 0),
    darker: variantColor + getSuffix(variantIntensity, darker),
    lighter: variantColor + getSuffix(variantIntensity, -100),
  };
};

const textVariant = computed(() =>
  props.textVariant !== undefined ? props.textVariant : props.variant,
);

const classes = computed(() => {
  let sizes: string[] = [];

  switch (props.size) {
    case "xs":
      sizes = ["px-1", "py-1", "text-xs"];
      break;
    case "sm":
      sizes = ["px-2.5", "py-1.5", "text-xs"];
      break;
    case "md":
      sizes = ["px-3", "py-2", "text-sm"];
      break;
    case "lg":
      sizes = ["px-4", "py-2", "text-sm"];
      break;
    case "xl":
      sizes = ["px-4", "py-2", "text-base"];
      break;
    case "2xl":
      sizes = ["px-6", "py-3", "text-base"];
      break;
    default:
      break;
  }

  const variantWithSuffixes = getVariantSuffix(props.variant);
  const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

  let classes: (string | object)[] = [
    `bg-${variantWithSuffixes.default}`,
    `text-${textVariantWithSuffixes.darker}`,
    {
      disabled: props.disabled,
    },
  ];

  classes = classes.concat(sizes);

  return classes;
});

const numberClasses = computed(() => {
  const variantWithSuffixes = getVariantSuffix(props.variant, 100);
  const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

  return `bg-${variantWithSuffixes.darker} text-${textVariantWithSuffixes.darker}`;
});

const emit = __emit;
const slots = useSlots();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["inline-flex items-center rounded-full py-0.5 px-1 text-xs font-medium", classes.value])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_unref(slots).number)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["inline-flex items-center ml-1 rounded-full px-1 text-xs font-medium", numberClasses.value])
        }, [
          _renderSlot(_ctx.$slots, "number")
        ], 2))
      : _createCommentVNode("", true),
    (props.removable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:bg-gray-500 focus:text-white focus:outline-none",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('remove')))
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Remove")), 1),
          _cache[1] || (_cache[1] = _createElementVNode("svg", {
            class: "h-2 w-2",
            stroke: "currentColor",
            fill: "none",
            viewBox: "0 0 8 8"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-width": "1.5",
              d: "M1 1l6 6m0-6L1 7"
            })
          ], -1))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})