import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { ECBasicOption } from "echarts/types/dist/shared";
import { PieChart, GaugeChart, BarChart, HeatmapChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { PropType, provide } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiChart',
  props: {
  option: {
    type: Object as PropType<ECBasicOption>,
  },
  autoresize: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  GaugeChart,
  HeatmapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
]);

provide(THEME_KEY, "light");

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VChart), {
    class: "chart",
    option: props.option,
    autoresize: props.autoresize
  }, null, 8, ["option", "autoresize"]))
}
}

})