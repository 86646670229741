import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pb-6 border-b border-gray-200" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { class: "font-semibold mb-1" }
const _hoisted_11 = {
  key: 0,
  controls: "",
  class: "min-w-[120px] mt-6"
}
const _hoisted_12 = ["src", "type"]
const _hoisted_13 = { class: "text-base font-semibold leading-6 text-gray-900 mb-6" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "font-semibold mt-10" }
const _hoisted_16 = {
  key: 0,
  class: "mt=10"
}
const _hoisted_17 = {
  key: 1,
  controls: "",
  class: "mt=10"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 2,
  class: "text-sm text-gray-500 mt=10"
}

import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { SurveyService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { ISurvey, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiPanel } from "@/components";
import { AxiosResponse } from "axios";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyDetailPage',
  setup(__props) {

const route = useRoute();

const survey: Ref<ISurvey | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSurvey = (id: number) => {
  setLoading(true);
  SurveyService.get<ISurvey>(id)
    .then((response: AxiosResponse<ISurvey>) => {
      survey.value = response.data;
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const audioData = ref();
const getAudioFile = async () => {
  if (survey.value?.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(
      survey.value?.audioFilePath,
    );
    const blob = new Blob([data], {
      type: getAudioFormat(survey.value?.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

getSurvey(parseInt(route.params.id as string));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("survey.view.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("survey.view.description")), 1)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goBack)({ name: 'ListSurvey' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1)
            ]),
            _: 1
          }),
          (_ctx.$can(_unref(Permission).EDIT_SURVEY))
            ? (_openBlock(), _createBlock(_unref(RouterLink), {
                key: 0,
                to: {
              name: 'EditSurveyById',
              params: {
                id: _unref(route).params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(UiButton), {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("survey.labels.name")), 1),
              _createElementVNode("p", null, _toDisplayString(survey.value?.name), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("survey.labels.text")), 1),
              _createElementVNode("p", null, _toDisplayString(survey.value?.textToAudio), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("survey.labels.status")), 1),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  survey.value?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
              }, _toDisplayString(survey.value?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("survey.labels.audio")), 1),
              (audioData.value)
                ? (_openBlock(), _createElementBlock("audio", _hoisted_11, [
                    _createElementVNode("source", {
                      src: audioData.value,
                      type: _unref(getAudioFormat)(survey.value.audioFilePath || '')
                    }, null, 8, _hoisted_12)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t("survey.view.section.questions.title")), 1),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(survey.value?.questions, (question) => {
              return (_openBlock(), _createElementBlock("div", {
                key: question.id
              }, [
                _createElementVNode("h4", _hoisted_15, _toDisplayString(question.label), 1),
                (question.textToAudio)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(question.textToAudio), 1))
                  : _createCommentVNode("", true),
                (question.audioFilePath)
                  ? (_openBlock(), _createElementBlock("audio", _hoisted_17, [
                      _createElementVNode("source", {
                        src: question.audioFilePath,
                        type: "audio/mpeg"
                      }, null, 8, _hoisted_18),
                      _cache[1] || (_cache[1] = _createTextVNode(" Seu navegador não suporta o elemento de áudio. "))
                    ]))
                  : _createCommentVNode("", true),
                (question.rangeAnswer)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$t("survey.view.section.questions.scoreRange")) + ": " + _toDisplayString(question.rangeAnswer), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})