import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_4 = { class: "w-full mt-6" }
const _hoisted_5 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import LevelService from "@/services/LevelService";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
} from "@/components";
import {
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  ILevelServiceCreateResult,
  FormAction,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<ILevel>({
  id: 0,
  name: "",
  isActive: true,
});

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(500),
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ILevel>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getLevel = (id: number) => {
  setLoading(true);
  LevelService.get<ILevel>(id)
    .then((response: AxiosResponse<ILevel>) => {
      const { name, isActive } = response.data;
      formState.id = id;
      formState.name = name;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getLevel(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewLevelById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    LevelService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    LevelService.create<ILevelServiceCreateResult>(formState)
      .then(
        (response: AxiosResponse<ILevelServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewLevelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListLevel",
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("level." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).name.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('level.labels.name'),
              errors: _unref(v$).name.$errors,
              onBlur: _unref(v$).name.$touch
            }, null, 8, ["modelValue", "label", "errors", "onBlur"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).isActive.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('level.labels.isActive')
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          onClick: handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_LEVEL)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_LEVEL))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})