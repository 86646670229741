import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, PropType } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import store from "@/store";
import { UiGetters } from "@/definitions";
import moment from "moment";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDatePicker',
  props: {
  modelValue: {
    type: [Date, Array<Date>, String, Array<string>] as PropType<
      Date | Array<Date> | string | Array<string>
    >,
  },
  modelType: {
    // timestamp - uses timestamp for binding
    // format - uses provided format or fallbacks to the default one. Must be a string
    // string - use custom format by providing a custom pattern with unicode tokens
    type: String,
  },
  range: {
    type: Boolean,
    default: false,
  },
  partialRange: {
    type: Boolean,
    default: false,
  },
  multiCalendars: {
    type: Boolean,
    default: false,
  },
  autoApply: {
    type: Boolean,
    default: true,
  },
  enableTimePicker: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  utc: {
    type: [Boolean, String] as PropType<boolean | "preserve">,
    default: "preserve",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const currentLocale = computed(() => store.getters[UiGetters.CURRENT_LOCALE]);

const { t } = useI18n();

const format = (selected: Date | Date[]) => {
  let response = "";
  if (Array.isArray(selected)) {
    const firstDate = moment(selected[0]);
    const secondDate = moment(selected[1]);

    response = `${firstDate.format(t("core.dateFormat"))} - ${secondDate.format(t("core.dateFormat"))}`;
  } else {
    const date = moment(selected);
    response = `${date.format(t("core.dateFormat"))}`;
    response = `Selected coisa`;
  }
  return response;
};

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VueDatePicker), {
    "model-value": props.modelValue,
    "model-type": props.modelType,
    range: props.range,
    partialRange: props.partialRange,
    multiCalendars: props.multiCalendars,
    autoApply: props.autoApply,
    enableTimePicker: props.enableTimePicker,
    clearable: props.clearable,
    utc: props.utc,
    disabled: props.disabled,
    readonly: props.readonly,
    locale: currentLocale.value,
    format: format,
    "max-date": new Date(),
    "onUpdate:modelValue": updateValue
  }, null, 8, ["model-value", "model-type", "range", "partialRange", "multiCalendars", "autoApply", "enableTimePicker", "clearable", "utc", "disabled", "readonly", "locale", "max-date"]))
}
}

})