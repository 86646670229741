import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-3 gap-4" }
const _hoisted_5 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "bg-white my-2 items-center rounded ring-1 ring-black ring-opacity-5 pb-2 overflow-auto" }
const _hoisted_12 = { class: "relative h-full w-full rounded text-sm" }
const _hoisted_13 = { class: "min-w-full border-separate border-spacing-0 rounded" }
const _hoisted_14 = {
  scope: "col",
  class: "sticky top-0 z-10 rounded-tl border-b border-gray-300 bg-gray-200 whitespace-nowrap px-3 py-1.5 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_15 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 text-left"
}
const _hoisted_16 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_17 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 text-center md:whitespace-nowrap"
}
const _hoisted_18 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_19 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_20 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_21 = {
  scope: "col",
  class: "sticky top-0 z-10 rounded-tr border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_22 = { class: "px-3 py-1.5 w-full flex items-center" }
const _hoisted_23 = { class: "whitespace-nowrap" }
const _hoisted_24 = { class: "px-3 py-1.5 text-right" }
const _hoisted_25 = { class: "px-3 py-1.5 text-right" }
const _hoisted_26 = { class: "px-3 py-1.5 text-right" }
const _hoisted_27 = { class: "px-3 py-1.5 text-right" }
const _hoisted_28 = { class: "px-3 py-1.5 flex flex-col items-center" }
const _hoisted_29 = { class: "flex" }
const _hoisted_30 = { class: "px-3 py-1.5 text-xs" }

import { UiDatePicker, UiPageHeader } from "@/components";
import { computed, Ref, ref, watch } from "vue";
import moment from "moment/moment";
import store from "@/store";
import {
  FilterActions,
  FilterGetters,
  ILevel,
  UiActions,
  UserDevice,
  UserDeviceStatus,
  UserExtensionQueueStatus,
} from "@/definitions";

import { DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import { formatSecToTime } from "@/utils/datetimeCommon";
import { toastServiceError } from "@/utils/notification";
import AgentDeviceStatusHistoryReportService from "@/services/AgentDeviceStatusHistoryReportService";
import {
  IAgentDeviceStatus,
  IAgentDeviceStatusHistoryReportServiceGetInfoParams,
} from "@/definitions/service-typings/AgentDeviceStatusHistoryReportService";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentDeviceReportPage',
  setup(__props) {

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const totalTimePaused = ref(0);

const totalTimeCall = ref(0);

const agentsDevices: Ref<IAgentDeviceStatus[]> = ref([]);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

function getDeviceIcon(device: IAgentDeviceStatus) {
  switch (device.deviceType) {
    case UserDevice.MOBILE:
      return DeviceMobileIcon;
    case UserDevice.DESKTOP:
      return DesktopComputerIcon;
    case UserDevice.WEB:
      return SVGChromeLogo;
    case UserDevice.EXTERNAL:
      return SVGDeviceExternal;
  }
}

function getStatusColor(status: string) {
  switch (status) {
    case UserDeviceStatus.CONNECTED:
      return "text-blue-600";
    case UserDeviceStatus.UNAVAILABLE:
      return "text-red-500";
    case UserDeviceStatus.IN_CALL:
      return "text-green-600";
  }
}

function getQueueColorStatus(status: string) {
  switch (status) {
    case UserExtensionQueueStatus.PAUSED:
      return "text-yellow-600";
    case UserExtensionQueueStatus.AVAILABLE:
      return "text-green-500";
  }
}

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IAgentDeviceStatusHistoryReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
  };

  setLoading(true);

  AgentDeviceStatusHistoryReportService.getInfo({
    params,
  })
    .then((response) => {
      totalTimePaused.value = response.data.totalTimePaused;
      totalTimeCall.value = response.data.totalTimeCall;
      agentsDevices.value = response.data.devices;
    }, toastServiceError)
    .finally(() => setLoading(false));
};
getReportData();

watch([level, dateRange], () => {
  getReportData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.title")), 1)
      ]),
      actions: _withCtx(() => [
        _createVNode(_unref(UiDatePicker), {
          modelValue: dateRange.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("dl", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.totalTimePaused")), 1),
          _createElementVNode("dd", _hoisted_7, _toDisplayString(_unref(formatSecToTime)(totalTimePaused.value)), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("dt", _hoisted_9, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.totalTimeCall")), 1),
          _createElementVNode("dd", _hoisted_10, _toDisplayString(_unref(formatSecToTime)(totalTimeCall.value)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("table", _hoisted_13, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.userName")), 1),
              _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.extension")), 1),
              _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimeOnline")), 1),
              _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimePaused")), 1),
              _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimeInCall")), 1),
              _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.lastStatus")), 1),
              _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.queues")), 1),
              _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.queueStatus")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(agentsDevices.value, (device, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: `queueDetailItem${idx}`,
                class: "even:bg-gray-100"
              }, [
                _createElementVNode("td", _hoisted_22, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(getDeviceIcon(device)), { class: "h-4 w-4 mr-1" })),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(device.userName), 1)
                ]),
                _createElementVNode("td", _hoisted_24, _toDisplayString(device.extension), 1),
                _createElementVNode("td", _hoisted_25, _toDisplayString(_unref(formatSecToTime)(device.totalTime.CONNECTED, true)), 1),
                _createElementVNode("td", _hoisted_26, _toDisplayString(device.queues.length
                    ? _unref(formatSecToTime)(device.queues[0].pauseDuration, true)
                    : "-"), 1),
                _createElementVNode("td", _hoisted_27, _toDisplayString(_unref(formatSecToTime)(device.totalTime.IN_CALL, true)), 1),
                _createElementVNode("td", _hoisted_28, [
                  (device.lastStatus !== null)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_29, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(getDeviceIcon(device)), { class: "h-4 w-4 mr-1" })),
                          _createElementVNode("span", {
                            class: _normalizeClass(getStatusColor(device.lastStatus))
                          }, _toDisplayString(_ctx.$t(
                          `report.agentDeviceStatusHistory.deviceStatus.${device.lastStatus}`,
                        )), 3)
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["text-xs whitespace-nowrap", getStatusColor(device.lastStatus)])
                        }, _toDisplayString(_unref(moment)(device.lastStatusAt).fromNow()), 3)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode("-")
                      ], 64))
                ]),
                _createElementVNode("td", _hoisted_30, _toDisplayString(device.queues.map((q) => q.name).join(", ")), 1),
                _createElementVNode("td", {
                  class: _normalizeClass(["px-3 py-1.5 text-center text-xs flex flex-col", getQueueColorStatus(device.queueStatus)])
                }, [
                  (device.queueStatus)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(
                        `report.agentDeviceStatusHistory.queueStatus.${device.queueStatus}`,
                      )), 1),
                        _createElementVNode("span", null, _toDisplayString(_unref(moment)(device.queueStatusAt).fromNow()), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ], 2)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})