import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-3" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

import { computed, Ref, ref, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  IConnectFlowFilter,
  ConnectFlowGetters,
  ConnectFlowActions,
} from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowListFilters',
  setup(__props) {

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[ConnectFlowGetters.DEFAULT_FILTER],
);
const filter: Ref<IConnectFlowFilter> = ref({
  isActive: defaultFilter.value.isActive,
  status: defaultFilter.value.status,
  user: defaultFilter.value.user,
  serviceTimeRange: defaultFilter.value.serviceTimeRange,
});
const storeFilter = computed(() => store.getters[ConnectFlowGetters.FILTER]);

const isActiveOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t("connectFlow.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("connectFlow.filters.isActive.options.inactives"),
    value: false,
  },
]);

function applyFilters() {
  store.dispatch(ConnectFlowActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(ConnectFlowActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.isActive = storeFilter.value.isActive;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filterOpened.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filterOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("connectFlow.filters.isActive.label")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_isActive",
              modelValue: filter.value.isActive,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.isActive) = $event)),
              options: isActiveOptions.value,
              placeholder: _unref(t)('core.options.All'),
              "can-clear": "",
              primitive: ""
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})