import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-6" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  key: 0,
  class: "grid grid-cols-3 gap-6 mb-10"
}
const _hoisted_7 = {
  key: 0,
  class: "col-span-2"
}
const _hoisted_8 = {
  controls: "",
  class: "w-full"
}
const _hoisted_9 = ["src", "type"]
const _hoisted_10 = { class: "mb-5" }
const _hoisted_11 = { class: "mt-2 sm:mt-0 grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_12 = { class: "flex col-span-2 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10" }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = { class: "mt-4 flex justify-center text-sm leading-6 text-gray-600" }
const _hoisted_15 = {
  key: 0,
  for: "audio",
  class: "relative cursor-pointer rounded-md bg-white font-semibold text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-600 focus-within:ring-offset-2 hover:text-cyan-500"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "text-xs leading-5 text-gray-600" }
const _hoisted_18 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import {
  ConnectFlowAudioService,
  LevelService,
  VaultService,
} from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
} from "@/components";
import {
  IConnectFlowAudio,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  FormAction,
  IConnectFlowAudioForm,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'AudioFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<IConnectFlowAudioForm>({
  id: 0,
  levelId: null,
  name: "",
  file: null,
  isActive: true,
});

const rules = computed<ValidationArgs<IConnectFlowAudioForm>>(() => {
  const r: ValidationArgs<IConnectFlowAudioForm> = {
    id: {},
    name: {
      required,
      maxLength: maxLength(500),
    },
    file: {},
    levelId: {
      required,
    },
    isActive: {
      required,
    },
  };

  if (props.action == FormAction.CREATE) {
    r.file = {
      required,
    };
  }

  return r;
});

const v$: Ref<Validation<ValidationArgs<IConnectFlowAudioForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getAudio = (id: number) => {
  setLoading(true);
  ConnectFlowAudioService.get<IConnectFlowAudio>(id)
    .then((response: AxiosResponse<IConnectFlowAudio>) => {
      const { name, levelId, audioFilePath, isActive } = response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      filePath.value = audioFilePath;
      formState.isActive = isActive;
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const filePath = ref();

const audioData = ref();
const getAudioFile = async () => {
  if (filePath.value !== undefined) {
    const { data } = await VaultService.getAudioAsset(filePath.value);
    const blob = new Blob([data], {
      type: getAudioFormat(filePath.value),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

if (props.action === FormAction.EDIT) {
  getAudio(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewConnectFlowAudioById",
      params: {
        id,
      },
    });
  };

  const formData = new FormData();

  formData.append("id", formState.id.toString());

  if (formState.file !== null) {
    formData.append("audio", formState.file);
  }
  if (formState.levelId) {
    formData.append("levelId", formState?.levelId?.toString());
  }
  formData.append("name", formState.name);
  formData.append("isActive", formState.isActive.toString());

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ConnectFlowAudioService.update(id, formData)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ConnectFlowAudioService.create(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

function handleAudioUpload(event: Event) {
  const input = event.target as HTMLInputElement;
  if (!input.files) return;

  const file = input.files[0];

  if (file) {
    formState.file = file;
  } else {
    alert("Please select an audio file.");
    formState.file = null;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlowAudio." + props.action + ".title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlowAudio." + props.action + ".description")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("userLabel.labels.level")) + " ", 1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs" }, "*", -1))
              ]),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: _unref(v$).levelId.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).levelId.$model) = $event)),
                primitive: true,
                options: levels.value,
                errors: _unref(v$).levelId.$errors,
                required: ""
              }, null, 8, ["modelValue", "options", "errors"])
            ]),
            _createVNode(_unref(UiTextInput), {
              modelValue: _unref(v$).name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('connectFlowAudio.labels.name'),
              errors: _unref(v$).name.$errors,
              onBlur: _unref(v$).name.$touch,
              required: ""
            }, null, 8, ["modelValue", "label", "errors", "onBlur"])
          ]),
          (props.action === _unref(FormAction).EDIT && formState.file === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (audioData.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("audio", _hoisted_8, [
                        _createElementVNode("source", {
                          src: audioData.value,
                          type: _unref(getAudioFormat)(filePath.value)
                        }, null, 8, _hoisted_9),
                        _cache[4] || (_cache[4] = _createTextVNode(" Your browser does not support the audio element. "))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[6] || (_cache[6] = _createElementVNode("svg", {
                    class: "mx-auto h-12 w-12 text-gray-300",
                    viewBox: "0 0 24 24",
                    fill: "currentColor",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z",
                      "clip-rule": "evenodd"
                    })
                  ], -1)),
                  _createElementVNode("div", _hoisted_14, [
                    (formState.file === null)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_15, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("connectFlowAudio.create.fileLabel")), 1),
                          _createElementVNode("input", {
                            onChange: handleAudioUpload,
                            id: "audio",
                            name: "audio",
                            type: "file",
                            class: "sr-only"
                          }, null, 32),
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-xs ml-0.5" }, "*", -1))
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(formState.file?.name), 1))
                  ]),
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("connectFlowAudio.create.uploadRules")), 1)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).file.$errors, (error, idx) => {
                return (_openBlock(), _createElementBlock("p", {
                  class: "text-sm text-danger bg-red-50 px-2 py-2 max-w-2xl shadow z-10",
                  key: `error${error.$propertyPath}${idx}`
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_unref(UiCheckboxInput), {
              modelValue: _unref(v$).isActive.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('connectFlowAudio.labels.isActive')
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListConnectFlowAudio' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_CONNECT_FLOW_AUDIO)) ||
            (props.action == _unref(FormAction).EDIT &&
              _ctx.$can(_unref(Permission).EDIT_CONNECT_FLOW_AUDIO))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})