import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_3 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_4 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_5 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_6 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "whitespace-nowrap border-b border-gray-200 py-1.5 px-3 text-sm font-medium" }
const _hoisted_9 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_10 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_11 = { class: "whitespace-nowrap border-b border-gray-200 py-1.5 px-3 text-sm font-medium text-center" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "text-gray-700" }
const _hoisted_14 = {
  colspan: "4",
  class: "whitespace-nowrap border-b border-gray-200 px-3 text-sm font-medium py-5 text-center"
}

import { formatSecToTime } from "@/utils/datetimeCommon";
import { IQueueItem } from "@/definitions/chart-typings/Realtime";
import { computed, onUnmounted, ref, Ref, watch } from "vue";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { UiSpinner } from "@/components";
import { applyPhoneMask } from "@/utils/number";
import { FilterGetters, ILevel } from "@/definitions";
import store from "@/store";

export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDetailsTable',
  setup(__props) {

const loading = ref(true);

const items: Ref<IQueueItem[]> = ref([]);

const getData = () => {
  loading.value = true;
  RealtimeReportService.getQueueCalls<IQueueItem[]>()
    .then((response) => {
      items.value = response.data;
    }, toastServiceError)
    .finally(() => (loading.value = false));
};
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  items.value = [];
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.sourceNumber")), 1),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.destinationNumber")), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.queueName")), 1),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.duration")), 1)
        ])
      ]),
      (items.value.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: `queueDetailItem${idx}`,
                class: "text-gray-700"
              }, [
                _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(applyPhoneMask)(item.numberFrom)), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(_unref(applyPhoneMask)(item.numberTo)), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(item.queueName), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(item.duration ? _unref(formatSecToTime)(item.duration) : "-"), 1)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
            _createElementVNode("tr", _hoisted_13, [
              _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.empty")), 1)
            ])
          ]))
    ]),
    (loading.value)
      ? (_openBlock(), _createBlock(_unref(UiSpinner), {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})