import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-center" }
const _hoisted_2 = { class: "w-screen flex items-center justify-between" }

import store from "@/store";
import { NavigationActions } from "@/definitions";
import { MenuIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateHeader',
  setup(__props) {

function toogleMenu() {
  store.dispatch(NavigationActions.TOGGLE_SIDE_NAVIGATION, true);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden mr-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toogleMenu()))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Open sidebar", -1)),
      _createVNode(_unref(MenuIcon), {
        class: "h-6 w-6",
        "aria-hidden": "true"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})