import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 hover:bg-white my-2 py-3 px-4 items-center rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_2 = { class: "mb-2 sm:mb-0" }
const _hoisted_3 = { class: "text-xs bg-gray-50 px-1.5 py-0.5 rounded-lg border-gray-200 ring-1 ring-black ring-opacity-5" }
const _hoisted_4 = { class: "grid grid-cols-2 lg:grid-cols-6 gap-3" }
const _hoisted_5 = { class: "text-sm flex flex-wrap items-center space-x-1 truncate font-medium text-primary" }
const _hoisted_6 = { class: "truncate w-full md:w-auto block overflow-hidden whitespace-normal" }
const _hoisted_7 = { class: "flex flex-row text-sm lg:col-span-2" }
const _hoisted_8 = { class: "font-bold text-gray-600 text-xs" }
const _hoisted_9 = { class: "ml-1 truncate block overflow-hidden whitespace-normal" }
const _hoisted_10 = { class: "flex items-center lg:justify-end lg:col-span-1" }
const _hoisted_11 = { class: "grid grid-cols-2 lg:grid-cols-6 gap-3 border-t pt-3 mt-2" }
const _hoisted_12 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_13 = { class: "truncate" }
const _hoisted_14 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_15 = { class: "text-gray-600 text-xs flex items-center space-x-1" }
const _hoisted_16 = { class: "text-gray-600 text-xs flex items-center space-x-1 lg:col-span-3" }
const _hoisted_17 = { class: "font-bold text-gray-600 text-xs" }

import { computed, PropType } from "vue";
import { UiButton } from "@/components";
import {
  CalendarIcon,
  ClockIcon,
  UserGroupIcon,
  PlayIcon,
  VideoCameraIcon,
  UserIcon,
} from "@heroicons/vue/outline";
import { IMeeting, Permission, PlatformDisplayNames } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingListItem',
  props: {
  modelValue: {
    type: Object as PropType<IMeeting>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const participantsNames = computed(() =>
  props.modelValue.participants.map((p) => p.name).join(", "),
);

const formattedCreatedAt = computed(() => {
  return props.modelValue.createdAt
    ? formatDateAsString(props.modelValue.createdAt, "DD/MM/YYYY HH:mm:ss")
    : "-";
});

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(__props.modelValue.id), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(__props.modelValue.title), 1)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(UserGroupIcon), {
          class: "h-5 w-5 mr-1 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("meeting.list.participants") + ":"), 1),
        _createElementVNode("span", _hoisted_9, _toDisplayString(participantsNames.value), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.$can(_unref(Permission).VIEW_MEETING))
          ? _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              size: "",
              shape: "rounded-full",
              variant: "inherit",
              "text-variant": "primary",
              shadow: "none",
              themed: "",
              class: "border-transparent focus:ring-primary shadow-none text-xs flex items-center",
              to: {
            name: 'ViewMeetingById',
            params: {
              id: __props.modelValue.id,
            },
          }
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(PlayIcon), {
                  class: "h-7 w-7 mr-1",
                  "aria-hidden": "true"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("core.actions.View")), 1)
              ]),
              _: 1
            }, 8, ["to"])), [
              [_directive_tippy, _ctx.$t('core.actions.View')]
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_unref(CalendarIcon), {
          class: "h-5 w-5 mr-1 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_13, _toDisplayString(formattedCreatedAt.value), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_unref(ClockIcon), {
          class: "h-5 w-5 mr-1 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(__props.modelValue.speechDuration) + " segundos", 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_unref(VideoCameraIcon), {
          class: "h-5 w-5 mr-1 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_unref(PlatformDisplayNames)[__props.modelValue.platform]), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_unref(UserIcon), {
          class: "h-5 w-5 mr-1 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("meeting.list.recordedBy")) + ":", 1),
        _createElementVNode("span", null, _toDisplayString(__props.modelValue.createdByUser?.name), 1)
      ])
    ])
  ]))
}
}

})