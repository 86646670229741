import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-wrap sm:flex-row gap-2 pb-3 relative" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { ref } from "vue";
import { ICallWord } from "@/definitions";
import { UiBadge } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordsBlock',
  props: {
  persona: {
    type: String,
    required: true,
  },
  words: {
    type: Array as () => Array<ICallWord>,
    required: true,
  },
},
  setup(__props) {



function searchWord(word: ICallWord): void {
  word.selected = !word.selected;
}

const hiddenItems = ref(true);
function toggleItems() {
  hiddenItems.value = !hiddenItems.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.words, (word, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `${__props.persona}-word-${word.text}`
      }, [
        (idx <= 10)
          ? (_openBlock(), _createBlock(_unref(UiBadge), {
              key: 0,
              class: "rounded-md cursor-pointer",
              variant: 
          word.selected ? 'orange' : word.registered ? 'purple' : 'gray'
        ,
              border: word.selected,
              onClick: ($event: any) => (searchWord(word))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.text), 1)
              ]),
              number: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.counter), 1)
              ]),
              _: 2
            }, 1032, ["variant", "border", "onClick"]))
          : _createCommentVNode("", true),
        (idx > 10)
          ? (_openBlock(), _createBlock(_unref(UiBadge), {
              key: 1,
              class: _normalizeClass(["rounded-md cursor-pointer", { hidden: hiddenItems.value }]),
              onClick: ($event: any) => (searchWord(word)),
              variant: 
          word.selected ? 'orange' : word.registered ? 'purple' : 'gray'
        ,
              border: word.selected
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.text), 1)
              ]),
              number: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.counter), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick", "variant", "border"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (__props.words.length > 10)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "p-2 text-sm text-orange-600 cursor-pointer hover:text-orange-800 right-5 bottom-0 absolute",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleItems()))
        }, [
          (hiddenItems.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("core.actions.SeeMore")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("core.actions.SeeLess")), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})