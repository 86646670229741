/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IBillingReportState,
  IBillingReportFilter,
  BillingReportMutations,
  BillingReportActions,
  BillingReportGetters,
  IFilterItem,
} from "@/definitions";

const getDefaultState = (): IBillingReportState => {
  return {
    filter: {
      dateRange: null,
      user: null,
      userLabel: null,
      sourceNumber: null,
      destinationNumber: null,
      status: null,
      type: null,
    },
  } as IBillingReportState;
};

const state: IBillingReportState = getDefaultState();

const mutations: MutationTree<IBillingReportState> = {
  [BillingReportMutations.SET_FILTER](
    state,
    payload: Partial<IBillingReportFilter>,
  ) {
    state.filter = { ...state.filter, ...payload };
  },
  [BillingReportMutations.CLEAR_FILTER](
    state,
    field?: keyof IBillingReportFilter,
  ) {
    switch (field) {
      case "dateRange":
        state.filter.dateRange = getDefaultState().filter.dateRange;
        break;
      case "user":
        state.filter.user = getDefaultState().filter.user;
        break;
      case "userLabel":
        state.filter.userLabel = getDefaultState().filter.userLabel;
        break;
      case "sourceNumber":
        state.filter.sourceNumber = getDefaultState().filter.sourceNumber;
        break;
      case "destinationNumber":
        state.filter.destinationNumber =
          getDefaultState().filter.destinationNumber;
        break;
      case "status":
        state.filter.status = getDefaultState().filter.status;
        break;
      case "type":
        state.filter.type = getDefaultState().filter.type;
        break;
      default:
        state.filter = getDefaultState().filter;
    }
  },
};

const actions: ActionTree<IBillingReportState, any> = {
  [BillingReportActions.SET_FILTER]: (
    { commit },
    filter: Partial<IBillingReportFilter>,
  ) => commit(BillingReportMutations.SET_FILTER, filter),
  [BillingReportActions.CLEAR_FILTER]: (
    { commit },
    field?: keyof IBillingReportFilter,
  ) => commit(BillingReportMutations.CLEAR_FILTER, field),
};

const getters: GetterTree<IBillingReportState, any> = {
  [BillingReportGetters.DEFAULT_FILTER]: (): IBillingReportFilter =>
    getDefaultState().filter,
  [BillingReportGetters.FILTER]: (state): IBillingReportFilter => state.filter,
  [BillingReportGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: IFilterItem[] = [];

    if (state.filter.dateRange) {
      filters.push({
        field: "dateRange",
        value: `${state.filter.dateRange[0].toISOString()} - ${state.filter.dateRange[1].toISOString()}`,
        label: "Date Range",
      });
    }

    if (state.filter.user) {
      filters.push({
        field: "user",
        value: state.filter.user.id,
        label: state.filter.user.name,
      });
    }

    if (state.filter.userLabel) {
      filters.push({
        field: "userLabel",
        value: state.filter.userLabel.id,
        label: state.filter.userLabel.label,
      });
    }

    if (state.filter.sourceNumber) {
      filters.push({
        field: "sourceNumber",
        value: state.filter.sourceNumber,
        label: state.filter.sourceNumber,
      });
    }

    if (state.filter.destinationNumber) {
      filters.push({
        field: "destinationNumber",
        value: state.filter.destinationNumber,
        label: state.filter.destinationNumber,
      });
    }

    if (state.filter.status) {
      filters.push({
        field: "status",
        value: state.filter.status,
        label: state.filter.status,
      });
    }

    if (state.filter.type) {
      filters.push({
        field: "type",
        value: state.filter.type,
        label: state.filter.type,
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
