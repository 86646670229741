import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }

import { computed } from "vue";
import store from "@/store";
import { AuthGetters, Permission, IUser, ILevel, IRole } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePage',
  setup(__props) {

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);
const userLevels = computed<ILevel[]>(() =>
  store.getters[AuthGetters.USER_LEVELS].map(
    (level: ILevel) => level.id + ":" + level.name,
  ),
);
const userRoles = computed<IRole[]>(() =>
  store.getters[AuthGetters.USER_ROLES].map(
    (role: IRole) => role.id + ":" + role.name,
  ),
);
const userPermissions = computed<Permission[]>(
  () => store.getters[AuthGetters.USER_PERMISSIONS],
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "ProfilePage", -1)),
    _createElementVNode("p", null, "id: " + _toDisplayString(user.value.id), 1),
    _createElementVNode("p", null, "name: " + _toDisplayString(user.value.name), 1),
    _createElementVNode("p", null, "email: " + _toDisplayString(user.value.email), 1),
    _createElementVNode("p", null, "levels: " + _toDisplayString(userLevels.value), 1),
    _createElementVNode("p", null, "roles: " + _toDisplayString(userRoles.value), 1),
    _createElementVNode("p", null, "permissions: " + _toDisplayString(userPermissions.value), 1)
  ]))
}
}

})