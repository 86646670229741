import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-span-3" }
const _hoisted_2 = { class: "block text-sm font-medium leading-6 text-gray-800" }

import { computed, PropType, reactive, Ref, watch } from "vue";
import { requiredIf } from "@/utils/validators";
import { UiTextInput } from "@/components";
import { UserService } from "@/services";
import { IExtensionFormState, IUserNumberFormState } from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormExtension',
  props: {
  modelValue: {
    type: Object as PropType<IExtensionFormState>,
    required: true,
  },
  devices: {
    type: Object as PropType<IUserNumberFormState>,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

const noDevice = computed(
  () => Object.values(props.devices).filter((v) => v).length === 0,
);

const formState = reactive<IExtensionFormState>(props.modelValue);

const isTaken = helpers.withAsync(
  async (value: number, siblings: IExtensionFormState) => {
    if (value === null || value.toString() === "") {
      return true;
    }

    const r = await UserService.checkExtensionNumber(siblings);
    if (r) {
      return true;
    } else {
      throw t("user.error.extensionUsed");
    }
  },
);

const rules = computed<ValidationArgs<IExtensionFormState>>(() => {
  const r: ValidationArgs<IExtensionFormState> = {
    id: {},
    levelId: {},
    label: {},
    number: {
      required: requiredIf(() => {
        return (
          formState.levelId !== null &&
          Object.values(props.devices).filter((v) => v).length > 0
        );
      }),
      isUnique: isTaken,
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IExtensionFormState>>> = useVuelidate(
  rules,
  formState,
);

const emit = __emit;
watch(
  formState,
  () => {
    emit("update:modelValue", formState);
  },
  {
    deep: true,
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("user.labels.extension.number")), 1),
    _createVNode(_unref(UiTextInput), {
      label: false,
      modelValue: formState.number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.number) = $event)),
      modelModifiers: { lazy: true, number: true },
      name: "extension",
      type: "number",
      errors: _unref(v$).number.$errors,
      silentErrors: _unref(v$).number.$silentErrors,
      class: "col-span-3",
      disabled: noDevice.value || __props.modelValue.levelId === null
    }, null, 8, ["modelValue", "errors", "silentErrors", "disabled"])
  ]))
}
}

})