import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center rounded-md" }
const _hoisted_2 = { class: "w-64" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_5 = { class: "flex rounded-md" }
const _hoisted_6 = ["name", "id", "value", "disabled"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["name", "id", "value", "disabled"]
const _hoisted_9 = ["value"]

import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTimePicker',
  props: {
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: [String, Boolean],
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
  silentErrors: {
    type: Array<ErrorObject>,
    default: [],
  },
},
  emits: ["update:modelValue", "blur"],
  setup(__props, { emit: __emit }) {

const props = __props;

const hasErrors = computed(
  () => props.errors.length || props.silentErrors.length,
);

const emit = __emit;

function handleHoursInput(value: string) {
  const hours = parseInt(value, 10).toString().padStart(2, "0");
  const minutes = getMinutes(props.modelValue);
  emit("update:modelValue", formatTime(hours, minutes));
}

function handleMinutesInput(value: string) {
  const hours = getHours(props.modelValue);
  const minutes = parseInt(value, 10).toString().padStart(2, "0");
  emit("update:modelValue", formatTime(hours, minutes));
}

function getHours(time: string): string {
  const hours = parseInt(time.slice(0, 2)) || 0;
  return hours < 10 ? `0${hours}` : `${hours}`;
}

function getMinutes(time: string): string {
  const minutes = parseInt(time.slice(3, 5), 10) || 0;
  return minutes.toString().padStart(2, "0");
}

function formatTime(hours: string, minutes: string): string {
  return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.label !== false)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: __props.name,
            class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
          }, [
            _createTextVNode(_toDisplayString(__props.label) + " ", 1),
            (__props.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("select", {
          name: __props.name + '-hours',
          id: __props.name + '-hours',
          class: _normalizeClass(["block w-1/2 rounded-md border border-gray-300 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring focus:border-primary shadow-sm py-2 px-3 pb-2 pt-2", [
            !hasErrors.value
              ? 'ring-gray-300 focus:ring-primary'
              : 'ring-danger focus:ring-danger',
          ]]),
          value: getHours(props.modelValue),
          onChange: _cache[0] || (_cache[0] = ($event: any) => (handleHoursInput(($event.target as HTMLInputElement).value))),
          disabled: __props.disabled,
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (emit('blur', $event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({ length: 24 }, (_, i) => i), (hour) => {
            return (_openBlock(), _createElementBlock("option", {
              value: hour.toString().padStart(2, '0'),
              key: hour
            }, _toDisplayString(hour.toString().padStart(2, "0")), 9, _hoisted_7))
          }), 128))
        ], 42, _hoisted_6),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mx-2 mt-2 mb-2 text-lg font-bold" }, ":", -1)),
        _createElementVNode("select", {
          name: __props.name + '-minutes',
          id: __props.name + '-minutes',
          class: _normalizeClass(["block w-1/2 rounded-md border border-gray-300 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring focus:border-primary shadow-sm py-2 px-3 pb-2 pt-2", [
            !hasErrors.value
              ? 'ring-gray-300 focus:ring-primary'
              : 'ring-danger focus:ring-danger',
          ]]),
          value: getMinutes(props.modelValue),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (
            handleMinutesInput(
              $event.target ? ($event.target as HTMLInputElement).value : '',
            )
          )),
          disabled: __props.disabled,
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (emit('blur', $event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({ length: 60 }, (_, i) => i), (minute) => {
            return (_openBlock(), _createElementBlock("option", {
              value: minute.toString().padStart(2, '0'),
              key: minute
            }, _toDisplayString(minute.toString().padStart(2, "0")), 9, _hoisted_9))
          }), 128))
        ], 42, _hoisted_8)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, (error) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "text-sm text-danger",
          key: error.$uid
        }, [
          _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.silentErrors, (error) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "text-sm text-danger",
          key: error.$uid
        }, [
          _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})