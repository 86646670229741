import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid sm:grid-cols-8 grid-cols-1 gap-4" }
const _hoisted_5 = { class: "col-span-4" }
const _hoisted_6 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = { class: "col-span-3" }
const _hoisted_8 = {
  for: "isActive",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_9 = { class: "col-span-3 h-full flex flex-col" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_11 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_12 = {
  controls: "",
  class: "w-full"
}
const _hoisted_13 = ["src", "type"]
const _hoisted_14 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_15 = {
  for: "addQuestion",
  class: "block text-sm font-medium leading-6 mb-2 text-red-500"
}
const _hoisted_16 = { class: "w-full relative h-10" }
const _hoisted_17 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { SurveyService, LevelService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { v4 as uuidv4 } from "uuid";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
  UiTextArea,
  UiAudioUpload,
} from "@/components";
import {
  ISurvey,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  FormAction,
  ISurveyForm,
  FilterGetters,
} from "@/definitions";
import { VaultService } from "@/services";
import { requiredIf } from "@/utils/validators";
import { SurveyFormQuestion } from "./components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { getAudioFormat } from "@/utils/audio";
import { AxiosResponse } from "axios";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const formState = reactive<ISurveyForm>({
  id: 0,
  name: "",
  isActive: true,
  levelId: 0,
  audioFilePath: "",
  textToAudio: "",
  audio: undefined,
  questions: [],
});

const isTextAreaDisabled = computed(() => !!formState.audio);

const rules = computed<ValidationArgs<ISurveyForm>>(() => {
  const r: ValidationArgs<ISurveyForm> = {
    id: {},
    name: {
      required,
      maxLength: maxLength(100),
    },
    levelId: {
      required,
    },
    questions: { required },
    isActive: {
      required,
    },
    audioFilePath: {
      maxLength: maxLength(250),
    },
    textToAudio: {
      required: requiredIf(() => {
        return !formState.audio && !formState.audioFilePath;
      }),
    },
    audio: {
      required: requiredIf(() => {
        return (
          (formState.textToAudio?.length ?? 0) < 1 && !formState.audioFilePath
        );
      }),
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<ISurveyForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSurvey = (id: number) => {
  setLoading(true);
  SurveyService.get<ISurvey>(id)
    .then((response: AxiosResponse<ISurvey>) => {
      const { name, levelId, textToAudio, audioFilePath, isActive, questions } =
        response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      formState.isActive = isActive;
      formState.textToAudio = textToAudio || "";
      formState.audioFilePath = audioFilePath || "";
      formState.questions = (questions || []).map((question) => ({
        id: question.id,
        uuid: uuidv4(),
        levelId: question.levelId,
        label: question.label,
        rangeAnswer: question.rangeAnswer,
        audioFilePath: question.audioFilePath || "",
        textToAudio: question.textToAudio || "",
      }));
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getSurvey(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const formData = new FormData();

  formData.append("id", formState.id.toString());
  formData.append("levelId", formState.levelId.toString());
  formData.append("name", formState.name);
  formData.append("isActive", formState.isActive.toString());
  formData.append("textToAudio", formState.textToAudio || "");

  if (formState.audio) {
    formData.append("audio", formState.audio);
  }

  formState.questions.forEach((question, index) => {
    formData.append(`questions[${index}][id]`, question.id.toString());
    formData.append(
      `questions[${index}][levelId]`,
      question.levelId.toString(),
    );
    formData.append(`questions[${index}][label]`, question.label);
    formData.append(`questions[${index}][rangeAnswer]`, question.rangeAnswer);
    formData.append(
      `questions[${index}][textToAudio]`,
      question.textToAudio || "",
    );
    if (question.audio) {
      formData.append(`questions[${index}][question-audio]`, question.audio);
    }
    if (question.audioFilePath) {
      formData.append(
        `questions[${index}][audioFilePath]`,
        question.audioFilePath,
      );
    }
  });

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewSurveyById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    SurveyService.update(id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    SurveyService.create(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        Analytics.trackingAction(`NewSurvey`).then();
        handleServiceSuccess(response.data.message, response.data.result.id);
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
}

const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

const audioData = ref();
const getAudioFile = async () => {
  if (formState.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const addQuestion = () => {
  const newQuestion = {
    id: 0,
    uuid: uuidv4(),
    levelId: formState.levelId,
    surveyId: formState.id,
    label: "",
    rangeAnswer: "",
    audioFilePath: "",
    textToAudio: "",
    isActive: true,
  };

  formState.questions.push(newQuestion);
};

const removeQuestion = (index: number) => {
  formState.questions.splice(index, 1);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("survey." + props.action + ".title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("survey." + props.action + ".description")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1),
              _createVNode(_unref(UiMultiselect), {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.levelId) = $event)),
                primitive: true,
                options: levels.value,
                disabled: formState.questions.length > 0
              }, null, 8, ["modelValue", "options", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(UiTextInput), {
                name: "surveyName",
                modelValue: _unref(v$).name.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).name.$model) = $event)),
                type: "text",
                label: _ctx.$t('survey.labels.name'),
                errors: _unref(v$).name.$errors,
                onBlur: _unref(v$).name.$touch
              }, null, 8, ["modelValue", "label", "errors", "onBlur"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("survey.labels.isActive")), 1),
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: formState.isActive,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.isActive) = $event)),
                name: "isActive",
                label: _ctx.$t('survey.labels.isActive')
              }, null, 8, ["modelValue", "label"])
            ]),
            _createVNode(_unref(UiTextArea), {
              id: "textToAudio",
              name: "textToAudio",
              label: _ctx.$t('survey.labels.text'),
              placeholder: _ctx.$t('survey.labels.questionLabel'),
              disabled: isTextAreaDisabled.value,
              errors: _unref(v$).textToAudio.$errors,
              modelValue: formState.textToAudio,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formState.textToAudio) = $event)),
              class: "col-span-5"
            }, null, 8, ["label", "placeholder", "disabled", "errors", "modelValue"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("survey.view.section.questions.audio")), 1),
              _createVNode(_unref(UiAudioUpload), {
                id: "audioUpload",
                disabled: (formState.textToAudio?.length ?? 0) > 0,
                class: "flex-grow",
                errors: _unref(v$).audio.$errors,
                "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules'),
                "onUpdate:file": handleFileUpdate
              }, null, 8, ["disabled", "errors", "text-rule"])
            ]),
            (audioData.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("audio", _hoisted_12, [
                    _createElementVNode("source", {
                      src: audioData.value,
                      type: _unref(getAudioFormat)(formState.audioFilePath || '')
                    }, null, 8, _hoisted_13)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("survey.create.section.questions.title")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formState.questions, (item, index) => {
            return (_openBlock(), _createBlock(_unref(SurveyFormQuestion), {
              key: item.uuid,
              index: index,
              "show-remove": formState.questions.length > 1,
              modelValue: formState.questions[index],
              "onUpdate:modelValue": ($event: any) => ((formState.questions[index]) = $event),
              onRemove: () => removeQuestion(index)
            }, null, 8, ["index", "show-remove", "modelValue", "onUpdate:modelValue", "onRemove"]))
          }), 128)),
          _createElementVNode("label", _hoisted_15, _toDisplayString(_unref(v$).questions?.$errors[0]?.$message), 1),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_unref(UiButton), {
              name: "addQuestion",
              variant: "white",
              "text-variant": "gray-500",
              class: "absolute right-0",
              onClick: addQuestion,
              disabled: !formState.levelId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("survey.create.section.questions.addQuestion")), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListSurvey' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_SURVEY)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_SURVEY))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})