import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" }

import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { IntegrationService } from "@/services";
import { UiPageHeader } from "@/components";
import { IntegrationWidget } from "./components";
import {
  UiActions,
  ILevel,
  FilterGetters,
  IIntegration,
  IntegrationType,
} from "@/definitions";
import { toastServiceError } from "@/utils/notification";
import { LinkIcon } from "@heroicons/vue/solid";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationPage',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();

const integrationInfo = {
  [IntegrationType.Pipedrive]: {
    logoPath: "/img/integration/pipedrive.png",
    description: t("integration.integrations.pipedrive.description"),
  },
  [IntegrationType.OpenAi]: {
    logoPath: "/img/integration/openai.png",
    description: t("integration.integrations.openai.description"),
  },
};

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const integrations: Ref<IIntegration[]> = ref([]);
const configuredIntegrations = computed<IIntegration[]>(() =>
  integrations.value.filter((integration) => integrationInfo[integration.type]),
);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const getIntegrations = () => {
  setLoading(true);
  IntegrationService.getAll<Array<IIntegration>>()
    .then((response: AxiosResponse<IIntegration[]>) => {
      integrations.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level], getIntegrations, { deep: true });

getIntegrations();

const handleConfigClick = (integration: IIntegration) => {
  router.push({
    name: "EditIntegrationById",
    params: {
      type: integration.type,
      id: integration.id,
    },
  });
};

const handleViewLogsClick = (integration: IIntegration) => {
  router.push({
    name: "ViewIntegrationLogs",
    params: {
      type: integration.type,
      id: integration.id,
    },
  });
};

const handleActivateClick = (integration: IIntegration) => {
  router.push({
    name: "CreateIntegration",
    params: {
      type: integration.type,
    },
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_unref(LinkIcon), {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Integrations")), 1)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(configuredIntegrations.value, (integration, index) => {
        return (_openBlock(), _createBlock(_unref(IntegrationWidget), {
          key: index,
          isActive: integration.isActive,
          logoPath: integrationInfo[integration.type].logoPath,
          contentText: integrationInfo[integration.type].description,
          onConfigClick: ($event: any) => (handleConfigClick(integration)),
          onViewLogsClick: ($event: any) => (handleViewLogsClick(integration)),
          onActivateClick: ($event: any) => (handleActivateClick(integration))
        }, null, 8, ["isActive", "logoPath", "contentText", "onConfigClick", "onViewLogsClick", "onActivateClick"]))
      }), 128))
    ])
  ]))
}
}

})