import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden sm:block" }
const _hoisted_2 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_3 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_4 = { class: "sm:col-span-6" }
const _hoisted_5 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_6 = { class: "sm:col-span-4" }
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

import { ref, Ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiMultiselect, UiRightPanel } from "@/components";
import {
  IMultiSelectOption,
  UserGetters,
  UserActions,
  IUserFilter,
  IRole,
} from "@/definitions";
import { FilterIcon } from "@heroicons/vue/solid";
import { RoleService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListFilters',
  setup(__props) {

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[UserGetters.DEFAULT_FILTER]);

const filter: Ref<IUserFilter> = ref({
  roles: defaultFilter.value.roles,
  isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<IUserFilter>(
  () => store.getters[UserGetters.FILTER],
);

const isActiveFilterOptions = [
  {
    label: t("user.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("user.filters.isActive.options.inactives"),
    value: false,
  },
] as IMultiSelectOption[];

const rolesOptions: Ref<IRole[]> = ref([]);
const getRoles = () => {
  RoleService.getAll<IRole[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  }).then(
    (response) =>
      (rolesOptions.value = rolesOptions.value.concat(response.data)),
  );
};

onMounted(() => {
  getRoles();
});

function applyFilters() {
  store.dispatch(UserActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(UserActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.roles = storeFilters.value.roles;
  filter.value.isActive = storeFilters.value.isActive;
}

watch(filtersOpened, () => {
  refreshLocalFilter();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiRightPanel), {
    modelValue: filtersOpened.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filtersOpened).value = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_unref(UiButton), {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filtersOpened.value = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(FilterIcon), {
            class: "mr-0 sm:mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Filter")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("user.filters.roles")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filter_role",
              label: "name",
              "value-prop": "id",
              multiple: "",
              modelValue: filter.value.roles,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.roles) = $event)),
              options: rolesOptions.value,
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("user.filters.isActive.label")), 1),
            _createVNode(_unref(UiMultiselect), {
              name: "filters_isActive",
              modelValue: filter.value.isActive,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.isActive) = $event)),
              options: isActiveFilterOptions,
              "can-clear": "",
              placeholder: _unref(t)('core.options.All')
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(UiButton), {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UiButton), {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})