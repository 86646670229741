import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_3 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_4 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_5 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_6 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 text-center whitespace-nowrap"
}
const _hoisted_7 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_8 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_9 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_10 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_11 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_12 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_13 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_14 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_15 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_16 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_17 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }

import { formatSecToTime } from "@/utils/datetimeCommon";
import { IUserDetails } from "@/definitions/chart-typings/Realtime";
import { computed, onUnmounted, ref, Ref, watch } from "vue";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { UiSpinner } from "@/components";
import { FilterGetters, ILevel, UserDeviceStatus } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersExtensionsDetailsTable',
  setup(__props) {

const loading = ref(true);

const items: Ref<IUserDetails[]> = ref([]);

const getData = () => {
  loading.value = true;
  RealtimeReportService.getAgentExtensions<IUserDetails[]>()
    .then((response) => {
      items.value = response.data;
    }, toastServiceError)
    .finally(() => (loading.value = false));
};

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  items.value = [];
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.extension")), 1),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.user")), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.device")), 1),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.status")), 1),
          _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.numberClient")), 1),
          _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.quality")), 1),
          _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.type")), 1),
          _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.duration")), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, idx) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `queueDetailItem${idx}`
          }, [
            _createElementVNode("td", _hoisted_11, _toDisplayString(item.extension), 1),
            _createElementVNode("td", _hoisted_12, _toDisplayString(item.user), 1),
            _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.$t(`core.device.${item.userDevice}`)), 1),
            _createElementVNode("td", {
              class: _normalizeClass([[
              item.status == _unref(UserDeviceStatus).OFFLINE
                ? 'bg-red-50 text-red-700'
                : 'bg-green-50 text-green-700',
            ], "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium text-center"])
            }, _toDisplayString(_ctx.$t(`report.realtime.device.status.${item.status}`)), 3),
            _createElementVNode("td", _hoisted_14, _toDisplayString(item.numberClient ? _unref(applyPhoneMask)(item.numberClient) : ""), 1),
            _createElementVNode("td", _hoisted_15, _toDisplayString(item.quality), 1),
            _createElementVNode("td", _hoisted_16, _toDisplayString(item.type ? _ctx.$t(`call.type.${item.type}`) : ""), 1),
            _createElementVNode("td", _hoisted_17, _toDisplayString(item.duration ? _unref(formatSecToTime)(item.duration) : ""), 1)
          ]))
        }), 128))
      ])
    ]),
    (loading.value)
      ? (_openBlock(), _createBlock(_unref(UiSpinner), {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})