import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex w-full gap-2 justify-end relative" }
const _hoisted_3 = {
  key: 0,
  class: "p-1 absolute -top-[45px] -left-[45px] bg-emerald-50 border border-gray-300 rounded-full shadow z-50"
}
const _hoisted_4 = {
  key: 1,
  class: "flex-1 text-gray-900 text-md font-medium"
}
const _hoisted_5 = { class: "text-sm leading-8" }
const _hoisted_6 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_7 = { class: "bg-gray-50 px-1.5 py-0.5 text-xs rounded-md ring-1 ring-black ring-opacity-10" }

import {
  computed,
  type HTMLAttributes,
  type PropType,
  type VNodeProps,
} from "vue";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";
import UiButton from "@/components/UiButton.vue";
import { Handle, Position } from "@vue-flow/core";
import { NodeToolbar } from "@vue-flow/node-toolbar";
import { PlusIcon } from "@heroicons/vue/solid";
import store from "@/store";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
} from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiNode',
  props: {
  title: {
    type: String,
  },
  icon: {
    type: Function as PropType<HTMLAttributes & VNodeProps>,
  },
  text: {
    type: String,
    default: "",
  },
  nodeId: {
    type: String,
    required: true,
  },
  showActions: {
    type: Boolean,
    default: false,
  },
  noEdition: {
    type: Boolean,
    default: false,
  },
  showHandleTarget: {
    type: Boolean,
    default: true,
  },
  showHandleSource: {
    type: Boolean,
    default: true,
  },
  bgColor: {
    type: String,
  },
  borderColor: {
    type: String,
  },
  roundedStyle: {
    type: String,
  },
  padding: {
    type: String,
  },
  nodeWidth: {
    type: String,
  },
  nodeHeight: {
    type: String,
  },
  hideNodeId: {
    type: Boolean,
  },
},
  emits: ["editNode", "removeNode"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleEditClick = () => {
  emit("editNode");
};

const handleRemoveClick = () => {
  emit("removeNode");
};

function openComponentSelector() {
  store.dispatch("flow/" + ConnectFlowModelingActions.SET_MENU, {
    show: true,
    idTriggerBy: props.nodeId,
  });
}

const node = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
    props.nodeId,
  ),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      class: [
        __props.bgColor ? __props.bgColor : 'bg-white',
        __props.borderColor ? __props.borderColor : 'border-gray-300',
        __props.roundedStyle ? __props.roundedStyle : 'rounded-xl',
        __props.padding ? __props.padding : 'px-5 py-5',
        __props.nodeWidth ? __props.nodeWidth : 'w-[350px]',
        __props.nodeHeight ? __props.nodeHeight : 'h-auto',
        'border-2 shadow relative',
      ]
    }), [
      _createElementVNode("div", _hoisted_2, [
        (__props.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(__props.icon), {
                class: "w-9 h-9 text-secondary",
                "aria-hidden": "true"
              }))
            ]))
          : _createCommentVNode("", true),
        (__props.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.title), 1))
          : _createCommentVNode("", true),
        (__props.showActions)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (!__props.noEdition)
                ? (_openBlock(), _createBlock(UiButton, {
                    key: 0,
                    variant: "primary",
                    "text-variant": "gray",
                    size: "xs",
                    class: "h-6",
                    onClick: handleEditClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(PencilAltIcon), { class: "h-4 w-4" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(UiButton, {
                variant: "primary",
                "text-variant": "gray",
                size: "xs",
                class: "h-6",
                onClick: handleRemoveClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TrashIcon), { class: "h-4 w-4" })
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      (__props.showHandleTarget)
        ? (_openBlock(), _createBlock(_unref(Handle), {
            key: 0,
            type: "target",
            position: _unref(Position).Top
          }, null, 8, ["position"]))
        : _createCommentVNode("", true),
      (__props.showHandleSource)
        ? (_openBlock(), _createBlock(_unref(Handle), {
            key: 1,
            type: "source",
            position: _unref(Position).Bottom
          }, null, 8, ["position"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default"),
        (!__props.hideNodeId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, " #" + _toDisplayString(node.value.id), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 16),
    _createVNode(_unref(NodeToolbar), {
      class: "flex gap-0.5 items-center",
      "is-visible": node.value?.connectable as boolean,
      position: _unref(Position).Bottom
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "rounded-full p-1 border-1 shadow bg-white hover:shadow-blueGray-600",
          onClick: openComponentSelector
        }, [
          _createVNode(_unref(PlusIcon), { class: "h-5 w-5" })
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "position"])
  ]))
}
}

})