import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["name", "id"]
const _hoisted_3 = ["for"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCheckboxInput',
  props: {
  modelValue: {
    type: Boolean,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      name: __props.name,
      id: __props.name,
      type: "checkbox",
      class: "h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event))
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, model.value]
    ]),
    _createElementVNode("label", {
      for: __props.name,
      class: "ml-3 text-sm font-medium leading-6 text-gray-900"
    }, _toDisplayString(__props.label), 9, _hoisted_3)
  ]))
}
}

})