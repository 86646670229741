import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }

import { computed } from "vue";
import { EChartsOption } from "echarts";
import { useI18n } from "vue-i18n";
import { UiChart, UiSpinner } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallQueueGaugeChart',
  props: {
  data: {
    type: Number,
    required: true,
  },
  loading: {
    type: Boolean,
    require: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const good = t("report.realtime.callsInQueue.gauge.good");
const ok = t("report.realtime.callsInQueue.gauge.ok");
const attention = t("report.realtime.callsInQueue.gauge.attention");
const bad = t("report.realtime.callsInQueue.gauge.bad");

const callStatusOptions = computed<EChartsOption>(() => {
  const data = [
    {
      value: props.data,
      name: t("report.realtime.callsInQueue.title"),
    },
  ];

  const eChartsOption: EChartsOption = {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        center: ["50%", "85%"],
        radius: "130%",
        min: props.min,
        max: props.max,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, "#7CFFB2"],
              [0.5, "#FDDD60"],
              [0.75, "#f48d3e"],
              [1, "#FF6E76"],
            ],
          },
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-60%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          length: 5,
          lineStyle: {
            color: "auto",
            width: 2,
          },
        },
        splitLine: {
          length: 10,
          lineStyle: {
            color: "auto",
            width: 5,
          },
        },
        axisLabel: {
          color: "#464646",
          fontSize: 12,
          distance: -30,
          rotate: "tangential",
          formatter: function (value) {
            if (value === 12.5) {
              return good;
            } else if (value === 37.5) {
              return ok;
            } else if (value === 62.5) {
              return attention;
            } else if (value === 87.5) {
              return bad;
            }
            return "";
          },
        },
        title: {
          offsetCenter: ["0px", "-10%"],
          fontSize: 15,
        },
        detail: {
          fontSize: 40,
          offsetCenter: [0, "-35%"],
          valueAnimation: true,
        },
        data,
      },
    ],
  };
  return eChartsOption;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiChart), { option: callStatusOptions.value }, null, 8, ["option"]),
    (props.loading)
      ? (_openBlock(), _createBlock(_unref(UiSpinner), {
          key: 0,
          class: "h-5 w-5 ml-3 absolute top-3 right-4 theme-color"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})