import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_4 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_6 = {
  key: 2,
  class: "mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2"
}
const _hoisted_7 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_8 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_9 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_10 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_11 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_12 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }

import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { toastServiceError } from "@/utils/notification";
import {
  ContactCenterPerDayReportActions,
  ContactCenterPerDayReportGetters,
  FilterActions,
  FilterGetters,
  IQueueTimeStats,
  IContactCenterPerDayReportFilter,
  IQueueTimeReportServiceGetInfoParams,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
  IQueueTimeReportServiceGetInfoResult,
} from "@/definitions";
import { ContactCenterPerDayReportFilters } from "@/views/pages/reports/contactCenterPerDay/components/";
import { UiPageHeader, UiDatePicker, UiBadge, BarChart } from "@/components";
import moment from "moment";
import { QueueTimeReportService } from "@/services";
import { formatSecToTime } from "@/utils/datetimeCommon";

interface ChartData {
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTimeReportPage',
  setup(__props) {

const { t } = useI18n();

const reportInfo: Ref<IQueueTimeReportServiceGetInfoResult | null> = ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const stats: IQueueTimeStats | undefined = reportInfo.value?.stats;

  if (stats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: stats.totalCalls.toString(),
    },
    {
      name: "inboundCalls",
      value: stats.inboundCalls.toString(),
    },
    {
      name: "costumersContacted",
      value: stats.costumersContacted.toString(),
    },
    {
      name: "nonTalkPercentage",
      value: stats.nonTalkPercentage.toString() + "%",
    },
    {
      name: "tma",
      value: stats.tma,
    },
    {
      name: "averageTimeQueue",
      value: stats.averageTimeQueue,
    },
  ];

  return result;
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IContactCenterPerDayReportFilter>(
  () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IQueueTimeReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: filter.value.type?.value || null,
    status: filter.value.status?.value || null,
    user: filter.value.user?.id || null,
    number: filter.value.number?.number || null,
    serviceTimeRange: filter.value.serviceTimeRange?.value || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  QueueTimeReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const chartDataAverageTimePerDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimePerDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerDay.length; i++) {
    const day = reportInfo.value?.queuePerDay[i];

    category.push(day.date);
    series[0].value.push(day.averageDuration);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangeByDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }
  for (let i = 0; i < reportInfo.value?.queuePerDay.length; i++) {
    const day = reportInfo.value?.queuePerDay[i];

    category.push(day.date);
    for (let y = 0; y < day.ranges.length; y++) {
      if (series[y] === undefined) {
        series[y] = {
          value: [],
          name: "",
          color: "",
        };
      }

      series[y].value.push(day.timeRange[y]);
      series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
      series[y].color = day.rangeColor[y];
    }
  }

  return {
    category,
    series,
  };
});

const chartDataAverageTimePerWeekDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimePerWeekDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerWeekDay.length; i++) {
    const day = reportInfo.value?.queuePerWeekDay[i];

    category.push(t(`core.days.${day?.day}`));
    series[0].value.push(day?.averageDuration);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangeByWeekDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerWeekDay.length; i++) {
    const day = reportInfo.value?.queuePerWeekDay[i];
    category.push(t(`core.days.${day?.day}`));
    for (let y = 0; y < day.ranges.length; y++) {
      if (series[y] === undefined) {
        series[y] = {
          value: [],
          name: "",
          color: "",
        };
      }

      series[y].value.push(day.timeRange[y]);
      series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
      series[y].color = day.rangeColor[y];
    }
  }

  return {
    category,
    series,
  };
});

const chartDataAverageTimePerHourOfDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimeByHourOfDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerHour === undefined) {
    return {
      category,
      series,
    };
  }

  for (let h = 0; h < 24; h++) {
    category.push(h.toString().padStart(2, "0"));
    series[0].value.push(reportInfo.value?.queuePerHour[h]);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangePerHourDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let h = 0; h < 24; h++) {
    category.push(h.toString().padStart(2, "0"));
  }
  for (
    let y = 0;
    y < reportInfo.value?.queueRangePorHourOfDay.ranges.length;
    y++
  ) {
    if (series[y] === undefined) {
      series[y] = {
        value: [],
        name: t(
          `report.queueTime.range.${reportInfo.value?.queueRangePorHourOfDay.ranges[y]}`,
        ),
        color: reportInfo.value?.queueRangePorHourOfDay.rangeColor[y],
      };
    }
    series[y].value = reportInfo.value?.queueRangePorHourOfDay.data[y];
  }

  return {
    category,
    series,
  };
});
const axisLabelDateFormatter = (value: number | string) => {
  return moment.utc(value).format("DD/MM");
};
const axisLabelSecToTimeFormatter = (value: number) => {
  return formatSecToTime(value);
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.queueTime.title")), 1)
      ]),
      actions: _withCtx(() => [
        _createVNode(_unref(ContactCenterPerDayReportFilters)),
        _createVNode(_unref(UiDatePicker), {
          modelValue: dateRange.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (activeFilters.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeFilters.value, (filter) => {
            return (_openBlock(), _createBlock(_unref(UiBadge), {
              key: filter,
              removable: "",
              onRemove: ($event: any) => (handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.contactCenterPerDay.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1)
              ]),
              _: 2
            }, 1032, ["onRemove"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (reportInfo.value?.stats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-5 grid grid-cols-1 gap-5", ['sm:grid-cols-' + headerStats.value.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerStats.value, (stat) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stat.name,
              class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            }, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t("report.queueTime.stats." + stat.name)), 1),
              _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1)
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (reportInfo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.averageTimePerDay.title'),
              categoryData: chartDataAverageTimePerDay.value.category,
              seriesData: chartDataAverageTimePerDay.value.series,
              xAxisLabelFormatter: axisLabelDateFormatter,
              yAxisLabelFormatter: axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.timeRangeByDay.title'),
              categoryData: chartDataTimeRangeByDay.value.category,
              seriesData: chartDataTimeRangeByDay.value.series,
              xAxisLabelFormatter: axisLabelDateFormatter,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.averageTimePerWeekDay.title'),
              categoryData: chartDataAverageTimePerWeekDay.value.category,
              seriesData: chartDataAverageTimePerWeekDay.value.series,
              yAxisLabelFormatter: axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.timeRangeByWeekDay.title'),
              categoryData: chartDataTimeRangeByWeekDay.value.category,
              seriesData: chartDataTimeRangeByWeekDay.value.series,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.averageTimeByHourOfDay.title'),
              categoryData: chartDataAverageTimePerHourOfDay.value.category,
              seriesData: chartDataAverageTimePerHourOfDay.value.series,
              yAxisLabelFormatter: axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_unref(BarChart), {
              title: _unref(t)('report.queueTime.averageTimeByHourOfDay.title'),
              categoryData: chartDataTimeRangePerHourDay.value.category,
              seriesData: chartDataTimeRangePerHourDay.value.series,
              column: ""
            }, null, 8, ["title", "categoryData", "seriesData"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})